import React, { useState, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BreadCrumb from '../../../../components/BreadCrumb';

import ChatRoom from './components/chatroom/Index';
import Conversations from './components/conversations/Index';
import ActivityIndicator from '../../../../utils/indicator/ActivityIndicator';


function Index(props) {
    const {
        auth
    } = props

    const match = useRouteMatch()

    const [ChatRooms, setChatRooms] = useState(null)
    const [MessengerSocket, setMessengerSocket] = useState(null)

    const [ActiveChat, setActiveChat] = useState(null)

    const InitializeMessenger = (data) => {
        console.log("Messenger data: ", data)
        setChatRooms(data)
    }

    const COMMAND = {
        init_messenger: InitializeMessenger
    }

    const _startMessenger = () => {
        const chatUser = auth.user
        if (chatUser) {
            console.log("Initializing messengerSocket for userId: ", chatUser.id)
            const URL = `wss://api.234parts.com/ws/messenger/${chatUser.id}/`
            const ws = new WebSocket(URL);
            setMessengerSocket(ws)
        }
    }

    const _sendSocketCommand = (ws, data) => {
        if (ws.readyState !== WebSocket.OPEN) {
            _startMessenger()
        }

        if (ws.readyState === WebSocket.OPEN) {
            console.log("\nSending Socket Command\n")

            try {
                console.log({ ...data })
                ws.send(JSON.stringify({ ...data }))
            }
            catch (err) {
                console.log(err.message);
            }
            console.log("Socket Command Sent\n")
        }
    }

    const _receiveSocketCommand = async (event) => {
        console.log("MESSENGER received new command!")
        const parsedData = JSON.parse(event.data);
        const command = COMMAND[parsedData.command];
        const data = parsedData.data;
        await command(data)
    }

    
    useEffect(() => {
        // Initialize Messenger if Active chat changes
        if (MessengerSocket && MessengerSocket.readyState === WebSocket.OPEN) {
            _sendSocketCommand(MessengerSocket,
                { command: 'init_messenger' }
            )
        }

    }, [ActiveChat])

    useEffect(() => {
        if (!MessengerSocket) {
            _startMessenger()
        } else {
            MessengerSocket.onopen = function (event) {
                // Initialize Messenger
                _sendSocketCommand(MessengerSocket,
                    { command: 'init_messenger' }
                )
            };
            //
            MessengerSocket.onmessage = (event) => _receiveSocketCommand(event);
        }
    }, [MessengerSocket])



    return (
        <div class="container-fluid">
            <Switch>
                <Route path={`${match.path}/:chatId`}>
                    <BreadCrumb
                        title="Support Chat"
                        crumbs={[
                            {
                                title: "Support Chat",
                                path: "/messenger/"
                            },
                            {
                                title: ActiveChat
                            }
                        ]}
                    />                    
                </Route>

                <Route exact path={`${match.path}`}>
                    <BreadCrumb
                        title="Support Chat"
                        crumbs={[
                            {
                                title: "Support Chat"
                            }
                        ]}
                    />
                </Route>

            </Switch>
            
            <div class="row">

                <div class="col-xl-3 col-lg-4">
                    <div class="card">
                        <div class="card-body" style={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>

                            <div class="d-flex align-items-start mb-3">
                                {/* <img src="../assets/images/users/user-1.jpg" class="me-2 rounded-circle" height="42" alt="Brandon Smith" /> */}
                                <div class="w-100">
                                    <h5 class="mt-0 mb-0 font-15">
                                        <a href="contacts-profile.html" class="text-reset">
                                            {auth.user && `${auth.user.first_name} ${auth.user.last_name}`}
                                        </a>
                                    </h5>
                                    <p class="mt-1 mb-0 text-muted font-14">
                                        <small class="mdi mdi-circle text-success"></small> Online
                                    </p>
                                </div>
                                <a href="javascript: void(0);" class="text-reset font-20">
                                    <i class="mdi mdi-cog-outline"></i>
                                </a>
                            </div>

                            <form class="search-bar mb-3">
                                <div class="position-relative">
                                    <input type="text" class="form-control form-control-light" placeholder="People, groups &amp; messages..." />
                                </div>
                            </form>

                            <Conversations 
                                chatRoomList={ChatRooms}
                            />
                    
                        </div>

                    </div>
                </div>


                <div class="col-xl-9 col-lg-8">
                    <Switch>
                        <Route path={`${match.path}/:chatId`}>
                            <ChatRoom
                                activeChat={ActiveChat}
                                setActiveChat={(activeChat) => setActiveChat(activeChat)}
                            />
                        </Route>

                        <Route exact path={`${match.path}`}>
                            <div className='card' style={{
                                minHeight: '600px',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {
                                    MessengerSocket && MessengerSocket.readyState !== WebSocket.OPEN ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <p class="no-messages text-center">
                                            There are no open conversations!
                                        </p>
                                    )
                                }
                            </div>
                        </Route>

                    </Switch>

                </div>

            </div>

        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)