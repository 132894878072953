import React, { Component } from 'react'
import {
    colors
} from '../App.json'
import {
    Link
} from 'react-router-dom'

import BounceLoader from "react-spinners/BounceLoader"


export default class Button extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const buttonState = this.props

        const RenderButton = () => {
            if (buttonState.linkTo) {
                return (
                    <Link
                        {...buttonState.props}
                        to={buttonState.linkTo}
                    >
                        {
                            buttonState.text.style ? (
                                <span style={{ ...buttonState.text.style }}>
                                    {buttonState.text.value}
                                </span>
                            ) : buttonState.text.value
                        }
                    </Link>
                )
            }
            else if (buttonState.href) {
                return (
                    <a
                        {...buttonState.props}
                        href={buttonState.href}
                    >
                        {
                            buttonState.text.style ? (
                                <span style={{ ...buttonState.text.style }}>
                                    {buttonState.text.value} ref
                                </span>
                            ) : buttonState.text.value
                        }
                    </a>
                )
            } else if (buttonState.onClick) {
                return (
                    <button
                        {...buttonState.props}
                        onClick={buttonState.onClick}
                    >
                        {
                            buttonState.text.style ? (
                                <span style={{ ...buttonState.text.style }}>
                                    {buttonState.text.value}
                                </span>
                            ) : buttonState.text.value
                        }

                        {
                            buttonState.loader ? (
                                <span className="ml-2 mr-3">
                                    <BounceLoader
                                        // css={override}
                                        // color={buttonState.loader.color}
                                        {...buttonState.loader.style}
                                        loading={buttonState.loader.isLoading}
                                        size={buttonState.loader.size}
                                    />
                                </span>
                            ) : null
                        }
                    </button>
                )
            } else {
                return (
                    <a
                        {...buttonState.props}
                        href={buttonState.href}
                    >
                        {
                            buttonState.text.style ? (
                                <span style={{ ...buttonState.text.style }}>
                                    {buttonState.text.value}
                                </span>
                            ) : buttonState.text.value
                        }
                    </a>
                )
            }
        }

        return (
            <>
                {
                    RenderButton()
                }
            </>
        )
    }
}
