import React, {
	useMemo,
	useState,
	useEffect, 
	useCallback 
} from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';

import {
	PostMan,
	SetDateTime
} from '../../../../../../Helpers';

import CreateStoreModal from './CreateStore';

import BreadCrumb from '../../../../../../components/BreadCrumb';
import PageActivityIndicator from '../../../../../../utils/indicator/PageActivityIndicator';


export default function StoreList() {
	const [StoreList, setStoreList] = useState([])
	const [Pagination, setPagination] = useState({
		count: 0,
		nextPage: "",
		prevPage: "",
	})
	const [IsLoading, setIsLoading] = useState(false)
	const [ShowModal, setShowModal] = useState(false)

	const FetchStores = useCallback(
		async () => {
			setIsLoading(true)
			//
			const responseObject = await PostMan(
				`auto-shop/all/?page_size=100`,
				'GET'
			)
			//
			setIsLoading(false)
			//
			if (responseObject.status === 'success') {
				const responseData = responseObject.data
				const storeList = responseData.results
				console.log('Stores List: ', storeList)
				// Update StoreList in state.
				await setStoreList(storeList)
				await setPagination({
					count: responseData.count,
					nextPage: responseData.next,
					prevPage: responseData.previous,
				})
			}
			else { }
		}, []
	)

	const storeTableCheckbox = (id = 0) => {
		return (
			<div class='custom-control custom-checkbox'>
				<input
					type='checkbox'
					class='custom-control-input'
					id={`customCheck${id}`}
				/>
				<label
					class='custom-control-label'
					for={`customCheck${id}`}
				>
					&nbsp;
				</label>
			</div>
		)
	}

	const storeTableAction = (store) => {
		return (
			<Link to={`/autoshop/stores/${store.id}`} class="action-icon" target="_blank">
				<i class='mdi mdi-square-edit-outline'></i>
			</Link>
		)
	}

	const getStoreType = (store) => {
		if (store.ecommerce) {
			return (
				<span class='badge bg-success text-white shadow-none'>
					Ecommerce
				</span>
			)
		} else {
			return (
				<span class='badge bg-info text-white shadow-none'>
					Classified
				</span>
			)
		}
	}

	const getStoreStatus = (store) => {
		if (store.is_active) {
			return (
				<span class='badge bg-soft-success text-success shadow-none'>
					Active
				</span>
			)
		} else {
			return (
				<span class='badge bg-soft-danger text-danger shadow-none'>
					Inactive
				</span>
			)
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: storeTableCheckbox(),
				accessor: 'col0',
			},
			{
				Header: 'Name',
				accessor: 'col1',
			},
			{
				Header: 'Type',
				accessor: 'col2',
			},
			{
				Header: 'Manager',
				accessor: 'col3',
			},
			{
				Header: 'Agent',
				accessor: 'col4',
			},
			{
				Header: 'Listings',
				accessor: 'col5',
			},
			{
				Header: 'Location',
				accessor: 'col6',
			},
			{
				Header: 'Create Date',
				accessor: 'col7',
			},
			{
				Header: 'Status',
				accessor: 'col8',
			},
			{
				Header: 'Action',
				accessor: 'col9',
			},
		], []
	)

	const data = useMemo(
		() => {
			return StoreList.map((store, _index) => {
				const index = _index + 1
				return ({
					col0: storeTableCheckbox(index),
					col1: store.name,
					// col1: <Link to={`/autoshop/stores/${store.id}`}>{store.name}</Link>,
					col2: getStoreType(store),
					col3: store.vendor.full_name,
					col4: store.agent.full_name,
					col5: store.listings.count,
					col6: store.city.short_name,
					col7: SetDateTime(store.created_at),
					col8: getStoreStatus(store),
					col9: storeTableAction(store)
				})
			})
		}, [StoreList]
	)

	const tableInstance = useTable(
		{
			columns, 
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 50
			},
		}, usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		pageOptions,
	    page,
		state: { pageIndex, pageSize },
	    gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,

	} = tableInstance


	const toggleModal = () => {
		setShowModal(!ShowModal)
	}

	useEffect(() => {
		// Fetch Store List
		FetchStores()
	}, [])

	return (
		<>
			<BreadCrumb
				title={"All Stores"}
				crumbs={[
					{
						title: 'Autoshop',
						path: null,
					},
					{
						title: 'All Stores',
						path: null,
					}
				]}
			/>

			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<div class='row'>
				<div class='col-12'>
					<div class='card'>
						<div class='card-body'>
							<div class='row mb-2'>
								<div class='col-sm-4'>
									<a
										href='#'
										class='btn btn-md btn-danger'
										onClick={toggleModal}
									>
										<i class='mdi mdi-plus-circle-outline'></i> Add Store
									</a>
								</div>
								<div class='col-sm-8'></div>
							</div>

							<div class='table-responsive'>
								<table {...getTableProps()} class='table table-centered table-striped'>
									<thead>
										{// Loop over the header rows
											headerGroups.map(headerGroup => (
												// Apply the header row props
												<tr {...headerGroup.getHeaderGroupProps()}>
													{// Loop over the headers in each row
														headerGroup.headers.map(column => (
															// Apply the header cell props
															<th {...column.getHeaderProps()}>
																{// Render the header
																	column.render('Header')
																}
															</th>
														))
													}
												</tr>
											))
										}

									</thead>
									<tbody {...getTableBodyProps()}>
										{// Loop over the table rows
											page.map(row => {
												// Prepare the row for display
												prepareRow(row)
												return (
													// Apply the row props
													<tr {...row.getRowProps()}>
														{// Loop over the rows cells
															row.cells.map(cell => {
																// Apply the cell props
																return (
																	<td {...cell.getCellProps()}>
																		{// Render the cell contents
																			cell.render('Cell')}
																	</td>
																)
															})}
													</tr>
												)
											})
										}
									</tbody>
								</table>

								<div className="pagination-wrapper">
									<nav aria-label="Page navigation example">
										<ul class="pagination justify-content-center">
											<li class="page-item disabled">
												<a onClick={() => previousPage()} disabled={!canPreviousPage}
													class="page-link"
													href="javascript: void(0);"
													tabindex="-1"
												>Previous</a>
											</li>

											{
												[...Array(pageOptions.length)].map((p, index) => {
													const activePageIndex = pageIndex + 1
													const pageCount = index + 1
													return (
														<li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
															<a class="page-link"
																href="javascript: void(0);"
															>{pageCount}</a>
														</li>
													)
												})
											}

											<li class="page-item">
												<a onClick={() => nextPage()} disabled={!canNextPage}
													class="page-link"
													href="javascript: void(0);"
												>Next</a>
											</li>
										</ul>
									</nav>

									<div style={{ textAlign: 'center' }}>
										<span>
											Page{' '}
											<strong>
												{pageIndex + 1} of {pageOptions.length}
											</strong>{' '}
										</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			
			
			<CreateStoreModal
				userType={"agent"}
				config={{
					title: 'Add a Store',
					isOpen: ShowModal,
					toggle: toggleModal,
				}}
			/>
		</>
	)
}
