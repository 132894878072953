import React, { useState } from 'react';

import Drawer from './Drawer';
import Header from './Header';

import ProtectedRoute from "../../utils/ProtectedRoute";

export default function BaseScreen(props) {

    const [ShowMenu, setShowMenu] = useState(false)

    return (
        <ProtectedRoute>
            <div id="wrapper">
                
                <Header setShowMenu={() => setShowMenu(!ShowMenu)} />

                <Drawer showMenu={ShowMenu} children={props.children} />
            </div>
        </ProtectedRoute>
    )
}


