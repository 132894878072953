import React, { useState, useEffect } from 'react'
import {
    PostMan,
    NumberWithCommas,
    SetDateTime
} from '../../Helpers';
import {
    Link,
    useRouteMatch
} from 'react-router-dom'


export default function ReportList() {
    const match = useRouteMatch()
    const [ReportList, setReportList] = useState([])

    const FetchReportList = async () => {
        const responseObject = await PostMan(`diagnostics/reports/`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("Client List: ", responseData)
            // Update ReportList in state.
            await setReportList(responseData)
        }
        else { }
    }

    useEffect(() => {
        // Fetch Client List
        FetchReportList()
    }, [])

    return (
        <>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-4">
                                    {/* <Link to={"/clients/add"} class="btn btn-md btn-danger">
                                        <i class="mdi mdi-plus-circle-outline"></i> Add New Client
                                    </Link> */}
                                    <h5>ALL DIAGNOSTICS REPORTS</h5>
                                </div>
                                <div class="col-sm-8">

                                </div>
                            </div>


                            <div class="table-responsive">
                                <table class="table table-centered table-striped" id="agent-datatable">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20px' }}>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                    <label class="custom-control-label" for="customCheck1">&nbsp;</label>
                                                </div>
                                            </th>
                                            <th>Full Name</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Vehicle</th>
                                            <th>Status</th>
                                            <th>Create Date</th>
                                            <th style={{ width: '85px' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ReportList && ReportList.map(report => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                                <label class="custom-control-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td class="table-user">
                                                            <Link to={`reports/${report.id}`} class="text-body font-weight-semibold">
                                                                {report.diagnostics_request.full_name || "Michael Smith"}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            +{report.diagnostics_request.mobile || "---"}
                                                        </td>
                                                        <td>
                                                            {report.diagnostics_request.email ? report.diagnostics_request.email : "---"}
                                                        </td>
                                                        <td>
                                                            {report.diagnostics_request.vehicle && report.diagnostics_request.vehicle.long_name}
                                                        </td>
                                                        <td>
                                                            {report.status && report.status.display}
                                                        </td>
                                                        <td>
                                                            {report.diagnostics_request.created_at && SetDateTime(report.diagnostics_request.created_at)}
                                                        </td>

                                                        <td>
                                                            <Link to={`reports/${report.id}`} class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
