import React, { useState, useEffect } from 'react'
import {
    Redirect,
    useHistory,
    useLocation,
    useRouteMatch
} from 'react-router-dom'
import {
    PostMan,
} from '../../Helpers';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../utils/Button';
import FormField from '../../utils/FormField';

import { AiOutlineCamera } from 'react-icons/ai'


function VehicleFaultCreate(props) {
    const {
        auth,
    } = props

    const history = useHistory()
    const match = useRouteMatch()
    const location = useLocation()
    
    const ReportId = match.params.reportId
    const { state: locationState } = location

    const [Report, setReport] = useState(null)
    const [redirect, setRedirect] = useState(null)    
    const [FaultImage, setFaultImage] = useState(null)
    const [AssemblyGroups, setAssemblyGroups] = useState(null)

    const [formData, setFormData] = useState({
        assembly_group: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                }
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Assembly Group',
                props: null
            },
            props: {
                name: 'assembly_group_input',
                // type: 'select',
                className: 'form-control',
                placeholder: 'Select Assembly Group',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        auto_part: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                }
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Auto part',
                props: null
            },
            props: {
                name: 'auto_part_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },

        severity_scale: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                },
                {
                    value: "low",
                    display: "Low"
                },
                {
                    value: "medium",
                    display: "Medium"
                },
                {
                    value: "major",
                    display: "Major"
                },
                {
                    value: "critical",
                    display: "Critical"
                },
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Severity Scale',
                props: null
            },
            props: {
                name: 'severity_scale_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        error_code: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Error Code (Optional)',
                props: null
            },
            props: {
                name: 'error_code_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Machine error code',
                required: false,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        description: {
            element: 'textarea',
            value: '',
            showLabel: true,
            label: {
                text: 'Description',
                props: null
            },
            props: {
                name: 'description_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Describe part fault(s)',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        remarks: {
            element: 'textarea',
            value: '',
            showLabel: true,
            label: {
                text: 'Remarks',
                props: null
            },
            props: {
                name: 'remarks_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter remarks',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
    })

    const [PageButtons, setPageButtons] = useState({
        saveAndContinue: {
            text: {
                style: null,
                value: "Save and Continue",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        cancel: {
            text: {
                style: null,
                value: "Cancel",
            },
            props: {
                type: "button",
                style: {
                    margin: '0 10px 0'
                },
                className: 'btn btn-danger',
            },
            onClick: () => history.goBack(),
            loader: null,
        },
    })

    const FetchAssemblyGroups = async () => {
        const responseObject = await PostMan("auto-parts/assembly-group-detail/", 'GET')
        if (responseObject.status === 'success') {
            let assemblyGroups = responseObject.data
            // Save Assembly Group to state
            setAssemblyGroups(assemblyGroups)
            let newFormData = formData
            assemblyGroups.map(assemblyGroup => {
                newFormData.assembly_group.data.push({
                    value: assemblyGroup.id,
                    display: assemblyGroup.name,
                })
            })
            // Update FormData in state.
            await setFormData({ ...newFormData })
        }
        else { }
    }

    const _getAssemblyPartsList = (assemblyGroupId) => {
        let assemblyGroupData = AssemblyGroups.find(assembly_group => {
            return parseInt(assemblyGroupId) === assembly_group.id
        })
        return assemblyGroupData.sub_category
    }

    const AttemptSaveFault = async () => {
        console.log("Saving Fault")

        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.saveAndContinue.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })

        const form_data = new FormData();
        if (FaultImage && FaultImage.raw) {
            const imageName = () => {
                let ext = FaultImage.raw.name.split('.')[1]
                return `${(new Date()).getTime()}.${ext}`
            }
            //
            form_data.append('image', FaultImage.raw, imageName());
        }
        //
        form_data.append('technician', auth.user && auth.user.id)
        form_data.append('diagnostics_report', Report.id)
        form_data.append('user_vehicle', Report.diagnostics_request.user_vehicle)
        
        //
        for (let formField in formData) {
            let fieldName = formField
            let fieldData = formData[formField]
            if (fieldData.props.required) {
                if (!fieldData.value || fieldData.value == ' ' || fieldData.value == 0) {
                    // Toast Error Message
                    toast.error(`${fieldData.label.text} is required!`)
                    // Stop Loader
                    newPageButtons.saveAndContinue.loader.isLoading = false
                    return setPageButtons({ ...newPageButtons })
                }
            }
            // payload[fieldName] = fieldData.value
            form_data.append(fieldName, fieldData.value)
        }

        //
        const responseObject = await PostMan('diagnostics/faults/', 'POST', form_data, false)

        // Stop Loader
        newPageButtons.saveAndContinue.loader.isLoading = false
        await setPageButtons({ ...newPageButtons })

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            // console.log("responseData: ", responseData)
            return window.location = `/reports/${Report.id}/`
        }
        else if (responseObject.status === 'bad_request') {
            let responseData = responseObject.data
            for (let key in responseData) {
                if (key === "message") {
                    toast.error(responseData[key])
                } else {
                    let fieldErrors = responseData[key]
                    fieldErrors.map(errorMessage => {
                        // Toast Error Message
                        toast.error(errorMessage)
                    })
                }
            }
        }
        else if (responseObject.status === 'error') {
            // Toast Error Message
            toast.error(responseObject.data.message)
        }
    }

    useEffect(() => {
        // Fetch vehicle
        FetchAssemblyGroups()

        // Redirect if missing state
        if (!locationState || !locationState.report) {
            // return GoBackToScratch()
            setRedirect("/reports")
        } else {
            setReport(locationState.report)
        }

    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="header-title">Vehicle Fault Report</h4>
                    <p class="sub-header">Provide valuable, actionable feedback to your users with HTML5 form validation–available in all our supported browsers.</p>

                    <form class="#">
                        <FormField
                            formData={formData}
                            change={(newFormData) => {
                                let assemblyGroup = newFormData.assembly_group.value
                                // Set Assembly Group auto-parts list
                                let assemblyPartsList = _getAssemblyPartsList(assemblyGroup)
                                // Update Auto-Parts field data
                                assemblyPartsList.map(autoPart => {
                                    newFormData.auto_part.data.push({
                                        value: autoPart.id,
                                        display: autoPart.name
                                    })
                                })
                                // Update Form
                                setFormData({ ...newFormData })
                            }}
                            field={{
                                id: 'assembly_group',
                                config: formData.assembly_group
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'auto_part',
                                config: formData.auto_part
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'severity_scale',
                                config: formData.severity_scale
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'description',
                                config: formData.description
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'remarks',
                                config: formData.remarks
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'error_code',
                                config: formData.error_code
                            }}
                        />

                        {/* <div class="form-group mb-3">
                            <div class="custom-control custom-checkbox form-check">
                                <input type="checkbox" class="custom-control-input" id="invalidCheck" required="" />
                                <label class="custom-control-label" for="invalidCheck">Agree to terms and conditions</label>
                                <div class="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                            </div>
                        </div> */}

                        <div className="my-3">
                            <div className="d-flex flex-column flex-sm-row">

                                <label className={"d-flex flex-column justify-content-center align-items-center hover-primary"} htmlFor="upload-button" style={{
                                    cursor: 'pointer',
                                    border: `5px solid grey`,
                                    padding: '10px 5px',
                                    borderRadius: '5px',
                                    maxHeight: '200px',
                                }}>
                                    <span style={{ borderRadius: '50%', textAlign: 'center' }}>
                                        <AiOutlineCamera size={100} />
                                    </span>

                                    <p style={{ margin: '0 0 0 5px', fontSize: '15px' }}>
                                        Add Image
                                    </p>
                                </label>
                                <input type="file" id="upload-button" style={{ display: 'none' }} onChange={(e) => setFaultImage({
                                    preview: URL.createObjectURL(e.target.files[0]),
                                    raw: e.target.files[0]
                                })} />


                                {
                                    FaultImage ? (
                                        <div className={"d-flex flex-column justify-content-start align-items-center"} style={{ height: '200px', }}>
                                            <img style={{ height: '160px', margin: '0 10px', borderRadius: '3px' }}
                                                src={FaultImage.preview}
                                            />

                                            <div onClick={() => setFaultImage(null)}
                                                style={{ 
                                                    backgroundColor: 'grey', 
                                                    borderRadius: "10px", 
                                                    cursor: 'pointer',
                                                    color: 'white', 
                                                    margin: '5px 0 0',
                                                    padding: '3px 15px',
                                                }}
                                            >
                                                Remove
                                            </div>
                                        </div>
                                    ) : null
                                }
                                
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Button {...PageButtons.saveAndContinue} {...{ onClick: () => AttemptSaveFault() }} />
                            <Button {...PageButtons.cancel} />
                        </div>

                    </form>

                </div>
            </div>


            <ToastContainer />
        </>
    )
}



const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFaultCreate)