import React, { useState, useEffect } from 'react';
import {
    PostMan
} from '../../../../../Helpers';
import {
    adServerUrl
} from '../../../../../App.json';


export default function ReferralDashboard() {
    const [Dashboard, setDashboard] = useState(null)

    const FetchAdsDashboard = async () => {
        const responseObject = await PostMan("dashboard/", 'GET', undefined, true, adServerUrl)
        if (responseObject.status === 'success') {
            let adsData = responseObject.data
            // Update ReferralList in state.
            await setDashboard(adsData)
        } else {
        }
    }

    useEffect(()=> {
        //
        FetchAdsDashboard()
    }, [])

    return (
        <div class="row">
            <div class="col-md-6">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="fe-users font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.campaign_count || "N/A"}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Campaigns</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="dripicons-store font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.ad_count || "N/A"}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Ads</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
