import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
// import ClientVehicleList from '../../components/diagnostics/ClientVehicleList'
import {
    PostMan,
} from '../../Helpers';

import Button from '../../utils/Button';

import VehiclePlaceholder from '../../assets/images/vehicle.png'
import VehicleFaultList from './VehicleFaultList';
import VehicleFaultCreate from './VehicleFaultCreate';


export default function VehicleReportDetail(props) {
    const match = useRouteMatch()
    const ReportId = match.params.reportId

    const [Report, setReport] = useState(null)

    const [PageButtons, setPageButtons] = useState({
        attemptGenerateReport: {
            text: {
                style: null,
                value: (
                    <><i class="mdi mdi-atom mr-1"></i>Generate Report</>
                ),
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary waves-effect waves-light mt-4',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        downloadReport: {
            text: {
                style: null,
                value: (
                    <>
                        <i class="mdi mdi-cloud-download-outline mr-1"></i>
                        Download Report
                    </>
                ),
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-danger waves-effect waves-light mt-3',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
    })

    const FetchDiagnosticsReport = async () => {
        const responseObject = await PostMan(`diagnostics/reports/${ReportId}/`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)
            // Update User vehicle in state.
            await setReport(responseData)
        }
        else { }
    }

    const AttemptGenerateReport = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.attemptGenerateReport.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        //
        const responseObject = await PostMan(`diagnostics/reports/${ReportId}/generate/`, 'GET')
        // Stop Loader
        newPageButtons.attemptGenerateReport.loader.isLoading = false
        await setPageButtons({ ...newPageButtons })
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)
            // Update User vehicle in state.
            // window.location.reload()
        }
        else { }
    }

    const AttemptDownloadReport = async () => {
        // // Start Loader
        // let newPageButtons = PageButtons
        // newPageButtons.attemptGenerateReport.loader.isLoading = true
        // await setPageButtons({ ...newPageButtons })
        // //
        // const responseObject = await PostMan(`diagnostics/reports/${ReportId}/download/`, 'POST')
        // // Stop Loader
        // newPageButtons.attemptGenerateReport.loader.isLoading = false
        // await setPageButtons({ ...newPageButtons })
        // //
        // if (responseObject.status === 'success') {
        //     let responseData = responseObject.data
        //     console.log("responseData: ", responseData)
        //     // Update User vehicle in state.
        //     window.location.reload()
        // }
        // else { }
        
    }


    useEffect(() => {
        // Fetch Report
        FetchDiagnosticsReport()

    }, [])


    return (
        <>

            <BreadCrumb title={Report && `${Report.diagnostics_request.vehicle.long_name}` || "Vehicle Report"} crumbs={[
                {
                    title: "Reports",
                    path: "/reports"
                }
            ]} />

            <div class="row">
                <div class="col-xl-8 order-xl-1 order-2">

                    <Switch>

                        <Route exact path={`${match.path}/add-fault`}>
                            <VehicleFaultCreate />
                        </Route>


                        <Route exact path={`${match.path}`}>
                            {
                                Report && <VehicleFaultList report={Report} />
                            }
                        </Route>

                    </Switch>

                </div>


                <div class="col-xl-4 order-xl-2 order-1">
                    <div class="card-box" dir="ltr">

                        <div class="text-center">
                            <h5>{Report && Report.diagnostics_request.vehicle.long_name}</h5>
                            <div class="row my-2">
                                <div class="col-6">
                                    <h3 data-plugin="counterup">{Report && Report.faults.length || 0}</h3>
                                    <p class="text-muted font-13 mb-0 text-truncate">Known Faults</p>
                                </div>
                                <div class="col-6">
                                    <h3 data-plugin="counterup">{Report && Report.faults.length || 0}</h3>
                                    <p class="text-muted font-13 mb-0 text-truncate">Faulty Assembly</p>
                                </div>
                            </div>

                            {/* <div id="leads-statics" style={{ height: "280px" }} class="morris-chart"></div> */}
                            <img src={VehiclePlaceholder} style={{ height: "100px" }} />

                            {/* <p class="text-muted font-15 font-family-secondary mb-0 mt-3">
                                <span class="mx-2"><i class="mdi mdi-checkbox-blank-circle text-blue"></i> Leads Won</span>
                                <span class="mx-2"><i class="mdi mdi-checkbox-blank-circle text-muted"></i> Leads Lost</span>
                            </p> */}


                            {/* <div>
                                {
                                    Report && Report.faults.length > 0 ? (
                                        <Button
                                            {...PageButtons.attemptGenerateReport}
                                            {...{ onClick: () => AttemptGenerateReport() }}
                                        />
                                    ) : null
                                }
                            </div>

                            <div>
                                {
                                    Report && Report.file ? (
                                        <Button
                                            {...PageButtons.downloadReport}
                                            {...{ onClick: () => AttemptDownloadReport() }}
                                        />
                                    ) : null
                                }
                            </div> */}
                        </div>

                        

                    </div>


                    



                </div>

            </div>

        </>
    )
}
