import React, { useState, useEffect } from 'react';
import {
    PostMan
} from '../../../../../../Helpers'; 
import {
    useRouteMatch
} from 'react-router-dom';

import DiagnosticsTable from '../DiagnosticsTable';
import DiagnosticsDashboard from '../DiagnosticsDashboard';

import PageActivityIndicator from '../../../../../../utils/indicator/PageActivityIndicator';
import BreadCrumb from '../../../../../../components/BreadCrumb';


export default function DiagnosticsRequests() {
    const match = useRouteMatch()
    const [DiagnosticsRequests, setDiagnosticsRequests] = useState([])
    const [IsLoading, setIsLoading] = useState(false)

    const FetchDiagnosticsRequests = async () => {
        //
        setIsLoading(true)
        //
        const responseObject = await PostMan(`diagnostics/`, 'GET')
        //
        setIsLoading(false)
        //
        if (responseObject.status === 'success') {
            let responseData = responseObject.data  
            // Update DiagnosticsRequests in state.
            await setDiagnosticsRequests(responseData)
        }
        else { }
    }

    useEffect(() => {
        // Fetch Diagnostics List
        FetchDiagnosticsRequests()
    }, [])

    return (

        <div class="container-fluid">

            <PageActivityIndicator open={IsLoading} toggle={() => { }} />

            <BreadCrumb 
                title={"Diagnostics"}
                crumbs={[
                    {
                        title: "All Diagnostics Requests",
                        path: null
                    }
                ]} 
            />

            <DiagnosticsDashboard />

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body mt-2">

                            <DiagnosticsTable diagnosticsRequests={DiagnosticsRequests} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
