import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    PostMan,
    SetDateTime
} from '../../../../../../Helpers';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../../../../utils/Button';
import FormField from '../../../../../../utils/FormField';
import ModalComponent from '../../../../../../utils/modal/Modal';


export default function CreateStoreModal(props) {
    const {
        userType,
        config
    } = props

    const {
        title,
        isOpen,
        toggle
    } = config

    const [formData, setFormData] = useState({
        first_name: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'First name',
                props: null,
            },
            props: {
                name: 'first_name_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter first name',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        last_name: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Last name',
                props: null,
            },
            props: {
                name: 'last_name_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter last name',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        email: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Email',
                props: null,
            },
            props: {
                name: 'email_input',
                type: 'email',
                className: 'form-control',
                placeholder: 'Enter email address',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        mobile: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Phone number',
                props: null,
            },
            props: {
                name: 'mobile_input',
                type: 'tel',
                className: 'form-control',
                placeholder: 'Enter phone number',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        password: {
            element: 'input',
            value: '234Agent2021',
            showLabel: true,
            label: {
                text: 'Password',
                props: null,
            },
            props: {
                name: 'password_input',
                type: 'password',
                className: 'form-control',
                placeholder: 'Enter password',
                required: true,
            },
            wrapperProps: {
                className: 'form-group',
            },
        },
    })

    const [PageButtons, setPageButtons] = useState({
        attemptCreateUser: {
            text: {
                style: null,
                value: 'Create',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-success btn-block',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
        cancelCreateUser: {
            text: {
                style: null,
                value: 'Cancel',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-danger btn-block',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
    })


    const AttemptCreateUser = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.attemptCreateUser.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })

        let payload = {
            account_type: userType,
        }
        for (let formField in formData) {
            let fieldName = formField
            let fieldData = formData[formField]
            if (fieldData.props.required) {
                if (
                    !fieldData.value ||
                    fieldData.value == ' ' ||
                    fieldData.value == 0
                ) {
                    // Toast Error Message
                    toast.error(`${fieldData.label.text} field is required!`)
                    // Stop Loader
                    newPageButtons.attemptCreateUser.loader.isLoading = false
                    return setPageButtons({ ...newPageButtons })
                }
            }
            if (fieldName == "password") {
                payload["password1"] = fieldData.value
                payload["password2"] = fieldData.value
            } 
            else payload[fieldName] = fieldData.value
        }

        const responseObject = await PostMan(`account/user/`, 'POST', payload)

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            window.location.reload()
        } else {
        }
    }

    const toggleShowPassword = () => {
        let newFormData = formData
        if (newFormData.password.props.type == "password") {
            newFormData.password.props.type = "text"
        } else {
            newFormData.password.props.type = "password"
        }
        setFormData({...newFormData})
        return
    }


    return (
        <>
            <ModalComponent open={isOpen} toggle={toggle} title={title}>

                <div style={{ padding: '20px' }}>
                    <form action="" method='POST'>
                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'first_name',
                                config: formData.first_name,
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'last_name',
                                config: formData.last_name,
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'email',
                                config: formData.email,
                            }}
                        />

                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'mobile',
                                config: formData.mobile,
                            }}
                        />

                        <div className="form-group mb-3">
                            <FormField
                                formData={formData}
                                change={(newFormData) => setFormData({ ...newFormData })}
                                field={{
                                    id: 'password',
                                    config: formData.password,
                                }}
                            />

                            {
                                formData.password.value.length > 0 ? (
                                    <div style={{ textAlign: 'right', cursor: 'pointer' }}
                                        onClick={() => toggleShowPassword()}
                                    >
                                        {
                                            formData.password.props.type == "password" ?
                                                "Show Password"
                                                :
                                                "Hide Password"
                                        }
                                    </div>
                                ) : null
                            }

                            
                        </div>


                        <div className='form-group mb-0 text-center'>
                            <Button
                                {...PageButtons.attemptCreateUser}
                                {...{
                                    onClick: () => AttemptCreateUser()
                                }}
                            />
                            <Button
                                {...PageButtons.cancelCreateUser}
                                {...{
                                    onClick: () => toggle()
                                }}
                            />
                        </div>
                    </form>
                </div>

            </ModalComponent>

            <ToastContainer />
        </>
    )
}
