import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import BreadCrumb from '../../../../../components/BreadCrumb';

import MailPlaceholder from '../../../../../assets/images/mail-placeholder.png'
import { PostMan } from '../../../../../Helpers';
import PageActivityIndicator from '../../../../../utils/indicator/PageActivityIndicator';
import ActivityIndicator from '../../../../../utils/indicator/ActivityIndicator';


export default function Index(props) {
    const [IsLoading, setIsLoading] = useState([])
    const [CommunicationList, setCommunicationList] = useState([])

    const FetchEmailCommunications = useCallback(
        async () => {
            setIsLoading(true)
            const responseObject = await PostMan(
                `notifications/email/communications`,
                'GET'
            )
            setIsLoading(false)
            //
            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                console.log('Email Communications: ', responseData)
                // Update UserList in state.
                await setCommunicationList(responseData)
            }
            else { }
        }, []
    )

    
    useEffect(() => {
        // Fetch Email Communications
        FetchEmailCommunications()

    }, [])


    return (
        <div class="container-fluid">
            
            <PageActivityIndicator open={IsLoading} toggle={() => { }} />

            <BreadCrumb
                title="Email Marketing"
                crumbs={[
                    {
                        title: "Email Marketing"
                    }
                ]}
            />

            <div class="row">
                <div class="col-md-12">
                    <div class="card-box">
                        <div class="row mb-2">
                            <div className='col-12'>
                                <Link to={"/email-marketing/create/"} class="btn btn-danger waves-effect waves-light mb-2" data-animation="fadein" data-plugin="custommodal" data-overlaycolor="#38414a">
                                    <i class="mdi mdi-plus-circle mr-1"></i> 
                                    Create Communication
                                </Link>
                            </div>                            
                        </div>

                        <div class="row">
                            {
                                CommunicationList.map(communication => {
                                    return (
                                        <div class="col-12 col-md-4 col-lg-3 mb-4">
                                            <Link to={`/email-marketing/${communication.name}`}> 
                                                <img src={MailPlaceholder} class="img-fluid" alt="" />
                                            </Link>
                                            <Link to={`/email-marketing/${communication.name}`}>
                                                <h4 class="header-title mt-3">
                                                    {communication.subject}
                                                </h4>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
