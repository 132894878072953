import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import { SetDateTime } from '../../../../../Helpers'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function ReferralTable(props) {
    const {
        sponsoredListings
    } = props

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());

    const referreeTableCheckbox = (id = 0) => {
        return (
            <div class='custom-control custom-checkbox'>
                <input
                    type='checkbox'
                    class='custom-control-input'
                    id={`customCheck${id}`}
                />
                <label
                    class='custom-control-label'
                    for={`customCheck${id}`}
                >
                    &nbsp;
                </label>
            </div>
        )
    }

    const referreeTableAction = (referree) => {
        return (
            <Link to={`/`} class="action-icon" target="_blank">
                <i class='mdi mdi-square-edit-outline'></i>
            </Link>
        )
    }
    
    const columns = useMemo(
        () => [
            {
                Header: referreeTableCheckbox(),
                accessor: 'col0',
            },
            {
                Header: 'Name',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Store',
                accessor: 'col2',
            },
            {
                Header: 'Duration',
                accessor: 'col3', // accessor is the "key" in the data
            },
            {
                Header: 'Status',
                accessor: 'col4',
            },
            {
                Header: 'Expires At',
                accessor: 'col5', // accessor is the "key" in the data
            },
            {
                Header: 'Action',
                accessor: 'col6', // accessor is the "key" in the data
            },
        ], []
    )

    const data = useMemo(
        () => {
            const referralData = []
            sponsoredListings.map((ad, _index) => {
                const index = _index + 1
                referralData.push({
                    col0: referreeTableCheckbox(index),
                    col1: ad.listing.name,
                    col2: ad.listing.auto_shop.name,
                    col3: ad.campaign.duration,
                    col4: ad.campaign.status,
                    col5: SetDateTime(ad.campaign.expires_at),
                    col6: referreeTableAction(ad)
                })
            })
            return referralData
        }, [sponsoredListings]
    )

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 50
            },
        },
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },


    } = tableInstance

    return (
        <div class='table-responsive'>

            <div class="page-title-box">
                <div class="page-title-right">
                    
                </div>

                <h4 class="page-title text-uppercase">
                    <i class="mdi mdi-cards-variant"></i>
                    Running Ads
                </h4>
            </div>


            {
                sponsoredListings ? (
                    <>
                        <table {...getTableProps()} class='table table-centered table-striped'>
                            <thead>
                                {// Loop over the header rows
                                    headerGroups.map(headerGroup => (
                                        // Apply the header row props
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {// Loop over the headers in each row
                                                headerGroup.headers.map(column => (
                                                    // Apply the header cell props
                                                    <th {...column.getHeaderProps()}>
                                                        {// Render the header
                                                            column.render('Header')
                                                        }
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }

                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                        <div className="pagination-wrapper">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item disabled">
                                        <a onClick={() => previousPage()} disabled={!canPreviousPage}
                                            class="page-link"
                                            href="javascript: void(0);"
                                            tabindex="-1"
                                        >Previous</a>
                                    </li>

                                    {
                                        [...Array(pageOptions.length)].map((p, index) => {
                                            const activePageIndex = pageIndex + 1
                                            const pageCount = index + 1
                                            return (
                                                <li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
                                                    <a class="page-link"
                                                        href="javascript: void(0);"
                                                    >{pageCount}</a>
                                                </li>
                                            )
                                        })
                                    }

                                    <li class="page-item">
                                        <a onClick={() => nextPage()} disabled={!canNextPage}
                                            class="page-link"
                                            href="javascript: void(0);"
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav>

                            <div style={{ textAlign: 'center' }}>
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                            </div>
                        </div>

                    </>
                ) : null
            }
        </div>
    )
}
