import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';

import {
	PostMan,
} from '../../../../../../Helpers';

import {
	useRouteMatch,
	useHistory,
	Redirect
} from 'react-router-dom';

import { UserTypes } from '../../Helpers';

import BreadCrumb from '../../../../../../components/BreadCrumb';

import UserProfileCard from '../UserProfileCard';

import UserSettings from './components/UserSettings';
import UserMessages from './components/UserMessages';
import UserSummary from './components/UserSummary';


export default function User() {
	const match = useRouteMatch()
	const history = useHistory()
	
	const userId = match.params.userId
	const userType = match.params.userType

	const [redirect, seRedirect] = useState(null)
	const [User, setUser] = useState(null)

	const [ActivePill, setActivePill] = useState("userSummary")


	const FetchUser = async () => {
		const responseObject = await PostMan(
			`account/user/${userId}/`,
			'GET'
		)
		if (responseObject.status === 'success') {
			let responseData = responseObject.data
			let userData = responseData.user
			console.log('User Detail: ', responseData)
			await setUser(userData)
		} 
		else {}
	}

	useEffect(() => {
		//
		if (!UserTypes[userType]) {
			seRedirect("/users/agents")
		}

		// Fetch User
		FetchUser()

	}, [])

	if (redirect) {
		return <Redirect to={redirect} />
	}

	function getPillStatus(pill) {
		if (ActivePill === pill) { return "active" }
		return ""
	}

	function getAriaStatus(pill) {
		if (ActivePill === pill) { return "true" }
		return "false"
	}

	return (
		<>
			<BreadCrumb
				title={
					User 
					?
					(
						<div style={{ textTransform: 'capitalize' }}>
							{userType} Detail: 
							<span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
								{User && User.full_name}
							</span>
						</div>
					)
					:
					"User Detail"
				}
				crumbs={[
					{
						title: 'Users',
						path: '/users',
					},
					{
						title: `${UserTypes[userType]}`,
						path: `/users/${userType}`,
					},
					{
						title: User && User.full_name,
						path: null,
					},
				]}
			/>

			

			<div className="row">
				<div className="col-lg-4 col-xl-4">
					<UserProfileCard user={User} />
				</div>

				<div className="col-lg-8 col-xl-8">
					<div className='card-box'>

						
						<ul class="nav nav-pills navtab-bg nav-justified">
							<li class="nav-item">
								<a onClick={() => setActivePill("userSummary")} 
									style={{ cursor: 'pointer' }}
									data-toggle="tab"
									aria-expanded={getAriaStatus("userSummary")}
									className={`nav-link ${getPillStatus("userSummary")}`}
								>
									Summary
								</a>
							</li>
							<li class="nav-item">
								<a onClick={() => setActivePill("userMessages")} 
									style={{ cursor: 'pointer' }}
									data-toggle="tab"
									aria-expanded={getAriaStatus("userMessages")} 
									className={`nav-link ${getPillStatus("userMessages")}`}
								>
									Messages
								</a>
							</li>
							<li class="nav-item">
								<a onClick={() => setActivePill("userSettings")} 
									style={{ cursor: 'pointer' }}
									data-toggle="tab"
									aria-expanded={getAriaStatus("userSettings")} 
									className={`nav-link ${getPillStatus("userSettings")}`}
								>
									Settings
								</a>
							</li>
						</ul>

						<div className='tab-content'>
							<UserSummary getStatus={() => getPillStatus("userSummary")} />
							<UserMessages getStatus={() => getPillStatus("userMessages")} />
							<UserSettings getStatus={() => getPillStatus("userSettings")} />
						</div>


					</div>

				</div>
			</div>
			


			
		</>
	)
}
