import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import BreadCrumb from '../../../../../components/BreadCrumb';

import CloudMessagePlaceholder from '../../../../../assets/images/cloud-message-placeholder.png';

import { PostMan } from '../../../../../Helpers';
import PageActivityIndicator from '../../../../../utils/indicator/PageActivityIndicator';
import ActivityIndicator from '../../../../../utils/indicator/ActivityIndicator';


export default function Index(props) {
    const [IsLoading, setIsLoading] = useState([])
    const [CommunicationList, setCommunicationList] = useState([])

    const FetchPushCommunications = useCallback(
        async () => {
            setIsLoading(true)
            const responseObject = await PostMan(
                `notifications/push/communications`,
                'GET'
            )
            setIsLoading(false)
            //
            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                console.log('Push Communications: ', responseData)
                // Update UserList in state.
                await setCommunicationList(responseData)
            }
            else { }
        }, []
    )

    
    useEffect(() => {
        // Fetch Push Communications
        FetchPushCommunications()

    }, [])


    return (
        <div class="container-fluid">
            
            <PageActivityIndicator open={IsLoading} toggle={() => { }} />

            <BreadCrumb
                title="Push Notifications"
                crumbs={[
                    {
                        title: "Push Notifications"
                    }
                ]}
            />

            <div class="row">
                <div class="col-md-12">
                    <div class="card-box">
                        <div class="row mb-2">
                            <div className='col-12'>
                                <Link to={"/push-notification/create/"} class="btn btn-danger waves-effect waves-light mb-2" data-animation="fadein" data-plugin="custommodal" data-overlaycolor="#38414a">
                                    <i class="mdi mdi-plus-circle mr-1"></i> 
                                    Create Communication
                                </Link>
                            </div>                            
                        </div>

                        <div class="row">
                            {
                                CommunicationList.map(communication => {
                                    return (
                                        <div class="col-12 col-md-4 col-lg-3 mb-4">
                                            <Link to={`/push-notification/${communication.name}`}> 
                                                <img style={{ background: 'lightgrey' }} src={CloudMessagePlaceholder} class="img-fluid" alt="" />
                                            </Link>
                                            <Link to={`/push-notification/${communication.name}`}>
                                                <h4 class="header-title mt-3">
                                                    {communication.subject}
                                                </h4>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
