import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    PostMan,
    SetDateTime
} from '../../../../../Helpers';

import {
    adServerUrl
} from '../../../../../App.json';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../../../utils/Button';
import FormField from '../../../../../utils/FormField';
import ModalComponent from '../../../../../utils/modal/Modal';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


function CreateAd(props) {
    const {
        auth,
        listing,
        config
    } = props

    const {
        title,
        isOpen,
        toggle
    } = config

    const [Plans, setPlans] = useState(null)
    const [formData, setFormData] = useState({
        plan: {
            element: 'select',
            value: '',
            data: [
                {
                    display: "---",
                    value: 0
                },
            ],
            showLabel: true,
            label: {
                text: 'Plan',
                props: null,
            },
            props: {
                name: 'plan_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Select plan',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        duration: {
            element: 'select',
            value: '',
            data: [
                { display: "---", value: 0 },
                { display: "1 week", value: 1 },
                { display: "2 weeks", value: 2 },
                { display: "3 weeks", value: 3 },
                { display: "4 weeks", value: 4 },
                { display: "5 weeks", value: 5 },
                { display: "6 weeks", value: 6 },
                { display: "7 weeks", value: 7 },
                { display: "8 weeks", value: 8 },
                { display: "9 weeks", value: 9 },
                { display: "10 weeks", value: 10 },
                { display: "11 weeks", value: 11 },
                { display: "12 weeks", value: 12}

            ],
            showLabel: true,
            label: {
                text: 'Duration',
                props: null,
            },
            props: {
                name: 'duration_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter last name',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
    })

    const [PageButtons, setPageButtons] = useState({
        attemptSponsorListing: {
            text: {
                style: null,
                value: 'Publish',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-success btn-block',
            },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
        cancelCreateUser: {
            text: {
                style: null,
                value: 'Cancel',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-danger btn-block',
            },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
    })


    const FetchPlans = async () => {
        const responseObject = await PostMan(`plans/`, 'GET', undefined, true, adServerUrl)
        if (responseObject.status === 'success') {
            let plans = responseObject.data
            let newFormData = formData
            plans.map(plan => {
                newFormData.plan.data.push({
                    display: plan.name,
                    value: plan.id
                })
            })
            await setFormData({...newFormData})
            await setPlans(plans)
        } else {
        }
    }


    const AttemptSponsorListing = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.attemptSponsorListing.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        let payload = {
            listing_ids: [listing.id, ],
            reference: "234000000",
        }
        for (let formField in formData) {
            let fieldName = formField
            let fieldData = formData[formField]
            if (fieldData.props.required) {
                if (
                    !fieldData.value ||
                    fieldData.value == ' ' ||
                    fieldData.value == 0
                ) {
                    // Toast Error Message
                    toast.error(`${fieldData.label.text} field is required!`)
                    // Stop Loader
                    newPageButtons.attemptSponsorListing.loader.isLoading = false
                    return setPageButtons({ ...newPageButtons })
                }
            }
            //
            payload[fieldName] = fieldData.value
        }

        console.log("payload: ", payload)

        const responseObject = await PostMan(`${listing.ad_account_id}/campaigns/`, 'POST', payload, true, adServerUrl)

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            window.location.reload()
        } else {
        }
    }

    const toggleShowPassword = () => {
        let newFormData = formData
        if (newFormData.password.props.type == "password") {
            newFormData.password.props.type = "text"
        } else {
            newFormData.password.props.type = "password"
        }
        setFormData({ ...newFormData })
        return
    }

    useEffect(()=> {
        // Fetch Plans
        FetchPlans()

    }, [])


    return (
        <>
            <ModalComponent open={isOpen} toggle={toggle} title={title} fullscreen={"xl"}>
                <div style={{ padding: '20px' }}>
                    <p>
                        Select Ad campaign details for listing: <b>{listing.name}</b>
                    </p>
                    {
                        Plans ? (
                            <form action="" method='POST'>
                                <FormField
                                    formData={formData}
                                    change={(newFormData) => setFormData({ ...newFormData })}
                                    field={{
                                        id: 'plan',
                                        config: formData.plan,
                                    }}
                                />

                                <FormField
                                    formData={formData}
                                    change={(newFormData) => setFormData({ ...newFormData })}
                                    field={{
                                        id: 'duration',
                                        config: formData.duration,
                                    }}
                                />

                                <div className='form-group mb-0 text-center'>
                                    <Button
                                        {...PageButtons.attemptSponsorListing}
                                        {...{
                                            onClick: () => AttemptSponsorListing()
                                        }}
                                    />
                                    <Button
                                        {...PageButtons.cancelCreateUser}
                                        {...{
                                            onClick: () => toggle()
                                        }}
                                    />
                                </div>
                            </form>
                        ) : null
                    }
                </div>
            </ModalComponent>

            <ToastContainer />
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAd)