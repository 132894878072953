import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import EmailCommunicationCreate from './components/CommunicationCreate';
import EmailCommunicationList from './components/CommunicationList';
import EmailCommunicationDetail from './components/CommunicationDetail/Index';

export default function Index(props) {
    const {

    } = props
    
    const match = useRouteMatch()

    return (
        <Switch>
            
            <Route path={`${match.path}/create`}>
                <EmailCommunicationCreate />    
            </Route>

            <Route path={`${match.path}/:communicationSlug`}>
                <EmailCommunicationDetail />
            </Route>
            
            <Route exact path={`${match.path}`}>
                <EmailCommunicationList />
            </Route>
            
        </Switch>
    )
}
