import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function Message(props) {
    const {
        auth,
        message,
        chatUser,
        recipient,
        markMessageAsRead
    } = props

    // const Message = messageMeta.message
    // const MessageMeta = messageMeta.message

    const [Meta, setMeta] = useState(false)
    const [ShowMeta, setShowMeta] = useState(false)

    const Alignment = () => {
        return chatUser.id == message.author_id ? "right" : "left"
    }

    useEffect(() => {
        if (!Meta) {
            if (chatUser.id == message.author_id) {
                message.meta_list.map(meta => {
                    if (chatUser.id != meta.chat_user_id) {
                        setMeta(meta)
                        return
                    }
                })
            } else {
                message.meta_list.map(meta => {
                    // if (meta.chat_user_id !== message.author_id) {
                    if (chatUser.id == meta.chat_user_id) {
                        setMeta(meta)
                        if (meta.status != "seen") {
                            // Mark message as read.
                            markMessageAsRead(message.id)
                        }
                        return
                    }
                })
            }
        }

    }, [])

    return (
        <>
            <li className={`clearfix ${Alignment() == "left" ? '' : 'odd'}`}
                onMouseEnter={() => setShowMeta(true)}
                onMouseLeave={() => setShowMeta(false)}
            >

                {/* <div class="chat-avatar">
                    <img src="../assets/images/users/user-5.jpg" class="rounded" alt="James Z" />
                    <i>{Meta.delivered_at}</i>
                </div> */}

                <div class="conversation-text">
                    <div class="ctext-wrap">
                        {
                            Alignment() == "left" ? (
                                <i>{recipient.full_name}</i>
                            ) : null
                        }

                        <p>
                            {message.content}
                        </p>
                    </div>
                </div>

                {
                    ShowMeta ? (
                        <div class="conversation-actions">
                            <div style={{
                                fontSize: '10px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {
                                    Meta.seen_at && (
                                        moment(Meta.seen_at).fromNow()
                                    ) || Meta.delivered_at && (
                                        moment(Meta.delivered_at).fromNow()
                                    ) || (
                                        moment(message.created_at).fromNow()
                                    )
                                }                                
                                                                
                                <a href={() => void (0)}>
                                    <i class="mdi mdi-dots-vertical font-16"></i>

                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a class="dropdown-item" href="#">Copy Message</a>
                                        <a class="dropdown-item" href="#">Edit</a>
                                        <a class="dropdown-item" href="#">Delete</a>
                                    </div>
                                </a>
                            </div>
                            <p style={{
                                fontSize: '12px',
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                            }}>
                                {Meta.status}
                            </p>
                        </div>
                    ) : null
                }
                
            </li>
        </>
    )
}
