import React from 'react'

export default function UserSettings(props) {
    const {
        getStatus
    } = props

    return (
        <div className={`tab-pane ${getStatus()}`} id="userSettings">
            <form>
                <h5 class="mb-4 text-uppercase"><i class="mdi mdi-account-circle mr-1"></i> Personal Info</h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="firstname">First Name</label>
                            <input type="text" class="form-control" id="firstname" placeholder="Enter first name" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="lastname">Last Name</label>
                            <input type="text" class="form-control" id="lastname" placeholder="Enter last name" />
                        </div>
                    </div> 
                </div> 

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="userbio">Bio</label>
                            <textarea class="form-control" id="userbio" rows="4" placeholder="Write something..."></textarea>
                        </div>
                    </div> 
                </div> 

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="useremail">Email Address</label>
                            <input type="email" class="form-control" id="useremail" placeholder="Enter email" />
                            <span class ="form-text text-muted"><small>If you want to change email please <a href="javascript: void(0);">click</a> here.</small></span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="userpassword">Password</label>
                            <input type="password" class="form-control" id="userpassword" placeholder="Enter password" />
                            <span class ="form-text text-muted"><small>If you want to change password please <a href="javascript: void(0);">click</a> here.</small></span>
                        </div>
                    </div> 
                </div> 

                <h5 class="mb-3 text-uppercase bg-light p-2"><i class="mdi mdi-office-building mr-1"></i> Company Info</h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="companyname">Company Name</label>
                            <input type="text" class="form-control" id="companyname" placeholder="Enter company name" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="cwebsite">Website</label>
                            <input type="text" class="form-control" id="cwebsite" placeholder="Enter website url" />
                        </div>
                    </div> 
                </div> 

                <h5 class="mb-3 text-uppercase bg-light p-2"><i class="mdi mdi-earth mr-1"></i> Social</h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-fb">Facebook</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-facebook-square"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-fb" placeholder="Url" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-tw">Twitter</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-twitter"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-tw" placeholder="Username" />
                            </div>
                        </div>
                    </div> 
                </div> 

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-insta">Instagram</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-instagram"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-insta" placeholder="Url" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-lin">Linkedin</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-linkedin"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-lin" placeholder="Url" />
                            </div>
                        </div>
                    </div> 
                </div> 

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-sky">Skype</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-skype"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-sky" placeholder="@username" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="social-gh">Github</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fab fa-github"></i></span>
                                </div>
                                <input type="text" class="form-control" id="social-gh" placeholder="Username" />
                            </div>
                        </div>
                    </div> 
                </div> 

                <div class="text-right">
                    <button type="submit" class="btn btn-success waves-effect waves-light mt-2"><i class="mdi mdi-content-save"></i> Save</button>
                </div>
            </form>
        </div>
    )
}
