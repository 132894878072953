import { store, persistor } from './redux/Store';
import {
    apiUrl
} from './App.json';

import {
    logout
} from './redux/actions/AuthActions'


export function NumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(".00", "");
}

export function FormatAMPM(DateTime) {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // const d = new Date();
    // let day = weekday[d.getDay()];


    let date = new Date(DateTime);
    console.log("date: ", date)
    let day = weekday[date.getDay()];
    console.log("day: ", day)
    var hours = date.getHours();
    console.log("hours: ", hours)
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function SetDateTime(DateTime) {
    let dateTime = new Date(DateTime);
    return dateTime.toUTCString().replace(" GMT", "")
}

export function BuildImageUri(imageURL) {
    if (imageURL.startsWith("http")) { return imageURL }
    return apiUrl + String(imageURL).substring(1)
}

// export const slugify = str =>
//     str
//         .toLowerCase()
//         .trim()
//         .replace(/[^\w\s-]/g, '')
//         .replace(/[\s_-]+/g, '-')
//         .replace(/^-+|-+$/g, '');

export function Slugify(str) {
    return str
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .trim();
}

export function NormalizePhoneNumber(value, previousValue, toDisplay) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (toDisplay) {
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            let normalizedOutput = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
            return [currentValue, normalizedOutput]
        }
    }
    return [currentValue, null]
};


export const PostMan = async(uriPath, method, payload, stringified=true, xHost=null) => {
    let responseObject
    const {
        auth
    } = store.getState()

    // console.log("Postman store", store.getState())
    console.log("Postman auth", auth)

    const setHeaders = () => {
        let headers = {}
        if (auth.user) { headers['Authorization'] = `jwt ${auth.accessToken}` }
        if (stringified) { headers['Content-Type'] = 'application/json' }
        if (stringified) { headers['Accept'] = 'application/json' }
        return headers
    }

    console.log("payload: ", payload)

    console.log("stringified: ", stringified)
    
    const getUrlPath = () => {
        if (xHost) { return xHost + uriPath }
        else if (uriPath.startsWith('http')) {
            return uriPath
        }
        return apiUrl + uriPath
    }

    await fetch(getUrlPath(), {
        method: method,
        headers: new Headers(setHeaders()),
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        body: stringified ? JSON.stringify(payload) : payload
    })
    .then(async response => {
        console.log("response: ", response)
        let errorData

        const contentType = response.headers.get("content-type");

        if (response.ok) {
            if (response.status === 200 || response.status === 201) {

                if (contentType.indexOf("application/json") !== -1) {
                    return {
                        statusCode: response.status,
                        data: await response.json(),
                    }
                } else if (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("image/") !== -1) {
                    return {
                        statusCode: response.status,
                        data: await response.blob(),
                    }
                } else {
                    return {
                        statusCode: response.status,
                        data: await response.text(),
                    }
                }
            }
        } else {
            if (response.status === 400) {
                return {
                    statusCode: response.status,
                    data: await response.json(),
                }
            }

            if (response.status === 401) {
                return {
                    statusCode: response.status,
                    data: await response.json(),
                }
            }

            if (response.status === 404) {
                return {
                    statusCode: response.status,
                    data: await response.text(),
                }
            }

            if (response.status === 500) {
                return {
                    statusCode: response.status,
                    data: await response.text(),
                }
            }

            // console.log("contentType: ", contentType)

            errorData = await response.json()
            throw new Error(errorData.message)
        }
    })
    .then(responseData => {
        console.log('POSTMAN responseData: ', responseData)
        if (responseData.statusCode == 400) {
            responseObject = {
                data: responseData.data,
                status: 'bad_request',
            }
        } else if (responseData.statusCode == 401) {
            responseObject = {
                data: responseData.data,
                status: 'unauthorized',
            }
            // Force logout
            store.dispatch(logout())
        } else if (responseData.statusCode == 404) {
            responseObject = {
                data: {
                    message: "not_found",
                    content: responseData.data
                },
                status: 'error',
            }
        } else if (responseData.statusCode == 500) {
            responseObject = {
                data: {
                    message: "Something went wrong",
                    content: responseData.data
                },
                status: 'error',
            }
        } else {
            responseObject = {
                data: responseData.data,
                status: 'success',
            }
        }
    })
    .catch(errorData => {
        // console.log("errorData: ", errorData.message)
        responseObject = {
            data: {
                message: errorData.message,
            },
            status: 'error',
        }
    })
    return responseObject
}