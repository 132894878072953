import React, { useState } from 'react';

import VehicleFaultItem from './VehicleFaultItem';
import ReportPlaceholder from '../../../../../../../assets/images/report.png'

import GenerateFinalReportModal from './VehicleFaultDetail/components/GenerateFinalReportModal';


export default function VehicleReportList(props) {
    const {
        diagnosticsReport,
        vehicleFaults
    } = props

    const [ShowModal, setShowModal] = useState(false)

    const toggleModal = () => {
        setShowModal(!ShowModal)
    }

    return (
        <>
            <GenerateFinalReportModal
                config={{
                    title: 'Upload Final Report',
                    isOpen: ShowModal,
                    toggle: toggleModal,
                }}
                diagnosticsReport={diagnosticsReport}
            />

            <div class="card mb-2">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <form class="form-inline">
                                <div class="form-group">
                                    <label for="inputPassword2" class="sr-only">Search</label>
                                    <input type="search" class="form-control" id="inputPassword2" placeholder="Search..." />
                                </div>
                            </form>
                        </div>
                        
                        {
                            diagnosticsReport && diagnosticsReport.version.value == "final" && diagnosticsReport.status.value == "open" ? (
                                <div class="col-sm-3 ml-auto">
                                    <a class='btn btn-md btn-danger text-white' href={void (0)} onClick={toggleModal} >
                                        <i class='mdi mdi-plus-circle-outline'></i> Upload Final Report
                                    </a>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>

            {
                vehicleFaults && vehicleFaults.length > 0 ? (
                    <>
                        {
                            vehicleFaults.map(fault => {
                                return (
                                    <VehicleFaultItem faultItem={fault} diagnosticsReport={diagnosticsReport} />
                                )
                            })
                        }
                    </>
                ) : (
                    <div class="card-box mb-2" style={{ padding: "100px" }}>
                        <div class="row align-items-center justify-content-center">
                            <div>
                                <div class="media" style={{ alignItems: 'center' }}>
                                    <img class="d-flex align-self-center mr-3 rounded-circle" src={ReportPlaceholder} alt="Generic placeholder image" height="64" />
                                    <div>
                                        <h4 class="mt-0 mb-2 font-16">There are no faults on this report</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
