import React, { useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FormField from '../../../../../../utils/FormField';
import Message from './components/Message';
import ActivityIndicator from '../../../../../../utils/indicator/ActivityIndicator';


function ChatRoom(props) {
    const {
        auth,
        activeChat,
        setActiveChat
    } = props

    const messageArrayRef = useRef();
    const messageEndDiv = useRef();

    const match = useRouteMatch()
    const ChatId = match.params.chatId

    const [Chat, setChat] = useState(null)
    const [ChatUser, setChatUser] = useState(null)
    const [MessageArray, setMessageArray] = useState([])
    const [ChatSocket, setChatSocket] = useState(null)
    const [formData, setFormData] = useState({
        newMessage: {
            element: 'input',
            value: '',
            showLabel: false,
            label: {
                text: 'New Message',
                props: null,
            },
            props: {
                name: 'new_message_input',
                type: 'text',
                className: 'form-control border-0',
                placeholder: 'Start typing...',
                required: true,
            },
            wrapperProps: {
                className: 'col mb-2 mb-sm-0',
            },
        }
    })

    //
    messageArrayRef.current = MessageArray

    const Recipients = () => {
        if (Chat && Chat.members && auth.user) {
            return Chat.members.filter(member => member.user_id != auth.user.id)
        }
        return []
    }

    const Recipient = () => {
        let _recipients = Recipients()
        if (_recipients && _recipients.length > 0) {
            return _recipients[0]
        } else return null
    }

    //

    const InitializeChat = (data) => {
        console.log("InitializeChat: ", data)
        const chat = data['chat']
        setChat(chat)
        const chat_user = data['chat_user']
        setChatUser(chat_user)
        const messages = data['messages']
        // setMessageArray(messages)
        UpdateMessages(messages)
    }

    const UpdateMessages = async (messages) => {
        const newMessageArray = [
            ...messageArrayRef.current,
            ...messages
        ]
        await setMessageArray(newMessageArray)
    }

    const UpdateMessage = async (newMessage) => {
        const newMessageArray = []
        const messageArray = messageArrayRef.current
        messageArray.map(message => {
            if (message.id == newMessage.id) {
                newMessageArray.push(newMessage)
            } else {
                newMessageArray.push(message)
            }
        })
        await setMessageArray(newMessageArray)
    }

    const IncomingMessage = async (data) => {
        const incoming_message = data['message']
        //
        await UpdateMessages([incoming_message, ])        
    }

    const MessageRead = async (data) => {
        const message = data['message']
        // Update Message
        await UpdateMessage(message)
    }

    const COMMAND = {
        init_chat: InitializeChat,
        incoming_message: IncomingMessage,
        message_read: MessageRead
    }

    const _startChat = async () => {
        await setMessageArray([])
        const URL = `wss://api.234parts.com/ws/messenger/${auth.user.id}/chat/${ChatId}/`
        const ws = new WebSocket(URL);
        setChatSocket(ws)
        setActiveChat(ChatId)
    }

    const _stopChat = async () => {
        await setChatSocket(null)
        await setActiveChat(null)
        await setChat(null)
        await setMessageArray([])
    }

    const _attemptSendNewMessage = async (e) => {
        //
        e.preventDefault()
        //
        const messageContent = formData.newMessage.value
        if (messageContent) {
            await _sendSocketCommand(ChatSocket,
                {
                    command: 'new_message',
                    message: messageContent
                }
            )
            //
            formData.newMessage.value = ""
            await setFormData({...formData})
        }
    }

    const _markMessageAsRead = async (messageId) => {
        await _sendSocketCommand(ChatSocket,
            {
                command: 'message_read',
                message_id: messageId
            }
        )
    }

    const _sendSocketCommand = (ws, data) => {
        if (ws.readyState !== WebSocket.OPEN) {
            _startChat()
        }

        if (ws.readyState === WebSocket.OPEN) {
            // console.log("\nSending Socket Command\n")
            try {
                console.log({ ...data })
                ws.send(JSON.stringify({ ...data }))
            }
            catch (err) {
                console.log(err.message);
            }
        }
    }

    const _receiveSocketCommand = async (event) => {
        // console.log("Chat Room received new command!")
        const parsedData = JSON.parse(event.data);
        const command = COMMAND[parsedData.command];
        const data = parsedData.data;
        await command(data)
    }

    useEffect(() => {
        if (!ChatSocket) {
            _startChat()
        } else {
            ChatSocket.onopen = function (event) {
                // Initialize Chat
                _sendSocketCommand(ChatSocket,
                    { command: 'init_chat' }
                )
            };
            ChatSocket.onclose = function (event) {
                console.log("Messenger WebSocket is now close.");
                // Clear Chat
                _stopChat()
            };
            ChatSocket.onmessage = (event) => _receiveSocketCommand(event);
        }
    }, [ChatSocket])

    useEffect(() => {
        if (ChatSocket && activeChat) {
            if (activeChat != ChatId) {
                // Close current websocket
                ChatSocket.close()
            }
        }
    }, [activeChat, ChatId])

    useEffect(() => {
        if(messageEndDiv.current) {
            messageEndDiv.current.scrollIntoView({ behavior: "smooth" })
        }
    })

    return (
        <div className='card' style={{
            height: '80vh',
        }}>
            {
                ChatSocket && ChatUser && Recipient() && (
                    <>
                        <div class="card-body py-2 px-3 border-bottom border-light">
                            <div class="row justify-content-between py-1">
                                <div class="col-sm-7">
                                    <div class="d-flex align-items-start">

                                        {/* <img src="../assets/images/users/user-5.jpg" class="me-2 rounded-circle" height="36" alt="Brandon Smith" /> */}

                                        <div>
                                            <h5 class="mt-0 mb-0 font-15">
                                                <a href="contacts-profile.html" class="text-reset">
                                                    {Recipient().user.full_name}
                                                </a>
                                            </h5>
                                            {
                                                Recipient().online ? (
                                                    <p class="mt-1 mb-0 text-muted font-12">
                                                        <small class={"mdi mdi-circle text-success mr-1"}></small>
                                                        Online
                                                    </p>
                                                ) : (
                                                    <p class="mt-1 mb-0 text-muted font-12">
                                                        <small class={"mdi mdi-circle text-secondary mr-1"}></small>
                                                        Offline
                                                    </p>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            height: '-webkit-fill-available',
                            overflowX: 'hidden'
                        }}>
                            {
                                MessageArray && MessageArray.length > 0 ? (
                                    <>


                                        <div class="card-body">
                                            <ul class="conversation-list" data-simplebar="init" style={{ maxHeight: "460px" }}>

                                                {
                                                    MessageArray.map(message => {
                                                        return (
                                                            <Message
                                                                {...props}
                                                                message={message}
                                                                chatUser={ChatUser}
                                                                recipient={Recipient().user}
                                                                markMessageAsRead={(messageId) => _markMessageAsRead(messageId)}
                                                            />
                                                        )
                                                    })
                                                }

                                                <div style={{ float: "left", clear: "both" }}
                                                    ref={(el) => { messageEndDiv.current = el; }}>
                                                </div>

                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p class="no-messages text-center">
                                            There are no messages yet. Say Hi?
                                        </p>
                                    </>
                                )
                            }
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="mt-2 bg-light p-3 rounded">
                                    <form class="needs-validation" novalidate="" name="chat-form" id="chat-form">
                                        <div class="row">
                                     
                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'newMessage',
                                                    config: formData.newMessage,
                                                }}
                                            />
                                            
                                            <div class="col-sm-auto">
                                                <div class="btn-group">
                                                    <a href="#" class="btn btn-light">
                                                        <i class="fe-paperclip"></i>
                                                    </a>
                                                    <button type="submit" onClick={(e) => _attemptSendNewMessage(e)} class="btn btn-success chat-send w-100">
                                                        <i class="fe-send"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </>
                )

                ||

                (
                    <div style={{ padding: '350px 0' }}>
                        <ActivityIndicator />
                    </div>
                )
            }
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom)