import React from 'react';
import {
    BuildImageUri,
} from '../../../../../../../Helpers';

import VehiclePlaceholder from '../../../../../../../assets/images/vehicle.png';

export default function UserProfileCard(props) {
    const {
        diagnosticsUser
    } = props

    return (
        <>
        {
            diagnosticsUser ? (
                <div class="card-box" dir="ltr">
                    {/* <h4 class="header-title mb-3">Vehicle Information</h4> */}

                    <div class="text-center">
                        <h5>{`${diagnosticsUser.vehicle.make.manufacturer.name} ${diagnosticsUser.vehicle.make.name}`}</h5>
                        <div class="row mt-2">
                            <div class="col-6">
                                <h3 data-plugin="counterup">{diagnosticsUser.vehicle.engine}</h3>
                                <p class="text-muted font-13 mb-0 text-truncate">Engine</p>
                            </div>
                            <div class="col-6">
                                <h3 data-plugin="counterup">{diagnosticsUser.vehicle.trim}</h3>
                                <p class="text-muted font-13 mb-0 text-truncate">Trim</p>
                            </div>
                        </div>

                        {
                            diagnosticsUser.vehicle.image_url ? (
                                <img style={{ height: "150px" }} src={BuildImageUri(diagnosticsUser.vehicle.image_url)} />
                            ) : (
                                <img src={VehiclePlaceholder} style={{ height: "100px" }} />
                            )
                        }
                    </div>
                </div>

            ) : null
        }
        </>
    )
}
