import React from 'react'
import { withRouter } from 'react-router-dom'

import VendorRouter from './vendor/Router'
import ManagementRouter from './management/Router'
import DiagnosticsRouter from './diagnostics/Router'

function RouterBase() {
	const subDomain = window.location.host.split('.')[1]
		? window.location.host.split('.')[0]
		: false
	console.log(window.location.host.split('.')[0])
	if (subDomain === 'newman') {
		return <ManagementRouter />
	} else if (subDomain === 'diagnostics') {
		return <DiagnosticsRouter />
	} else if (subDomain === 'vendor') {
		return <VendorRouter />
	} else {
		return (
			<div>
				<h1>234Parts</h1>
				<h1>Something Went Wrong</h1>
			</div>
		)
	}
}

export default withRouter(RouterBase)
