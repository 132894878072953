import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';

import {
    PostMan,
    SetDateTime
} from '../../../../../../Helpers';

import {
    useRouteMatch,
    Redirect
} from 'react-router-dom';

import BreadCrumb from '../../../../../../components/BreadCrumb';
import FormField from '../../../../../../utils/FormField';
import CreateAd from '../../../sponsored_listings/components/CreateAd';



export default function ListingDetail() {
    const match = useRouteMatch()

    const listingId = match.params.listingId

    const [redirect, seRedirect] = useState(null)
    const [Listing, setListing] = useState(null)

    const [ShowCreateAdModal, setShowCreateAdModal] = useState(false)

    const [formData, setFormData] = useState({
        // name: {
        //     element: 'input',
        //     value: '',
        //     showLabel: true,
        //     label: {
        //         text: 'Name [Not In Use]',
        //         props: null
        //     },
        //     props: {
        //         name: 'name_input',
        //         type: 'text',
        //         className: 'form-control',
        //         placeholder: 'Listing name',
        //         required: false,
        //     },
        //     wrapperProps: {
        //         className: 'form-group mb-3'
        //     }
        // },
        auto_shop: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                }
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Autoshop',
                props: null
            },
            props: {
                name: 'auto_shop_input',
                className: 'form-control',
                placeholder: 'Select Autoshop',
                required: true,
                disabled: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        price: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Price',
                props: null
            },
            props: {
                name: 'price_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter Amount',
                required: false,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        warranty: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                },
                {
                    value: "1 month",
                    display: "1 month"
                },
                {
                    value: "2 months",
                    display: "2 months"
                },
                {
                    value: "3 months",
                    display: "3 months"
                },
                {
                    value: "6 months",
                    display: "6 months"
                },
                {
                    value: "1 year",
                    display: "1 year"
                },
                {
                    value: "2 years",
                    display: "2 years"
                },
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Warranty',
                props: null
            },
            props: {
                name: 'warranty_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        }, 
        condition: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                },
                {
                    value: "new",
                    display: "New"
                },
                {
                    value: "used",
                    display: "Used"
                },
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Condition',
                props: null
            },
            props: {
                name: 'condition_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        discount: {
            element: 'select',
            data: [
                { value: 0, display: "---" },
                { value: 5, display: "5%" },
                { value: 10, display: "10%" },
                { value: 15, display: "15%" },
                { value: 20, display: "20%" },
                { value: 25, display: "25%" },
                { value: 30, display: "30%" },
                { value: 35, display: "35%" },
                { value: 40, display: "40%" },
                { value: 45, display: "45%" },
                { value: 50, display: "50%" },
                { value: 55, display: "55%" },
                { value: 60, display: "60%" },
                { value: 65, display: "65%" },
                { value: 70, display: "70%" },
                { value: 75, display: "75%" },
                { value: 80, display: "80%" },
                { value: 85, display: "85%" },
                { value: 90, display: "90%" },
                { value: 95, display: "95%" },
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Discount',
                props: null
            },
            props: {
                name: 'discount_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        is_sponsored: {
            element: 'checkbox',
            checked: false,
            showLabel: true,
            label: {
                text: 'Is Sponsored',
                props: {
                    className: "custom-control-label",
                    for: 'is_sponsored',
                }
            },
            props: {
                name: 'is_sponsored_input',
                type: 'checkbox',
                className: 'custom-control-input',
                id: 'is_sponsored',
                required: true,
            },
            wrapperProps: {
                className: 'custom-control custom-switch mb-3'
            }
        },
        is_negotiable: {
            element: 'checkbox',
            checked: false,
            showLabel: true,
            label: {
                text: 'Is Negotiable',
                props: {
                    className: "custom-control-label",
                    for: 'is_negotiable',
                }
            },
            props: {
                name: 'is_negotiable_input',
                type: 'checkbox',
                className: 'custom-control-input',
                id: 'is_negotiable',
                required: true,
            },
            wrapperProps: {
                className: 'custom-control custom-switch mb-3'
            }
        },
        is_shipped_from_overseas: {
            element: 'checkbox',
            checked: false,
            showLabel: true,
            label: {
                text: 'Is Shipped From Overseas',
                props: {
                    className: "custom-control-label",
                    for: 'is_shipped_from_overseas',
                }
            },
            props: {
                name: 'is_shipped_from_overseas_input',
                type: 'checkbox',
                className: 'custom-control-input',
                id: 'is_shipped_from_overseas',
                required: true,
            },
            wrapperProps: {
                className: 'custom-control custom-switch mb-3'
            }
        },
    })


    const FetchListing = async () => {
        const responseObject = await PostMan(
            `auto-shop/listing/${listingId}/`,
            'GET'
        )
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log('Listing Detail: ', responseData)
            await setListing(responseData)

            let newFormData = formData
            for (let fieldName in responseData) {
                if (newFormData[fieldName]) {
                    if (fieldName == "auto_shop") {
                        let autoShop = responseData[fieldName]
                        newFormData[fieldName].value = {
                            display: autoShop.name,
                            value: autoShop.id
                        }
                        newFormData[fieldName].data = [
                            newFormData[fieldName].value
                        ]
                    } else if (newFormData[fieldName].element == "checkbox") {
                        newFormData[fieldName].checked = responseData[fieldName]
                    } else {
                        newFormData[fieldName].value = responseData[fieldName]
                    }
                    
                }

            }
            setFormData({ ...newFormData })
        }
        else { }
    }

    const toggleModal = () => {
        setShowCreateAdModal(!ShowCreateAdModal)
    }


    useEffect(() => {

        // Fetch Listing
        FetchListing()

    }, [])

    return (
        <div class="container-fluid">
            {
                Listing ? (
                    <>
                        <CreateAd
                            listing={Listing}
                            config={{
                                title: 'Create Listing Ad',
                                isOpen: ShowCreateAdModal,
                                toggle: toggleModal,
                            }}
                        />

                        <BreadCrumb
                            title={
                                Listing
                                    ?
                                    (
                                        <div style={{ textTransform: 'capitalize' }}>
                                            Listing Detail:
                                            <span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
                                                {Listing && Listing.name}
                                            </span>
                                        </div>
                                    )
                                    :
                                    "Listing Detail"
                            }
                            crumbs={[
                                {
                                    title: 'Autoshop',
                                    path: '/autoshop',
                                },
                                {
                                    title: 'All Listings',
                                    path: `/autoshop/stores/${Listing && Listing.auto_shop.id}/`,
                                },
                                {
                                    title: Listing && Listing.name,
                                    path: null,
                                },
                            ]}
                        />

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="card-box">
                                    <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>

                                    {/* <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'name',
                                config: formData.name
                            }}
                        /> */}

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'auto_shop',
                                            config: formData.auto_shop
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'price',
                                            config: formData.price
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'warranty',
                                            config: formData.warranty
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'condition',
                                            config: formData.condition
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'discount',
                                            config: formData.discount
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'is_sponsored',
                                            config: formData.is_sponsored
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'is_negotiable',
                                            config: formData.is_negotiable
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'is_shipped_from_overseas',
                                            config: formData.is_shipped_from_overseas
                                        }}
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6">

                                <div class="card-box">
                                    <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">Meta Data</h5>


                                    <div className='row'>
                                        <div class="col-12 col-md-6">
                                            <h5>Auto Part</h5>
                                            <p>
                                                {Listing.product.auto_part}
                                            </p>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <h5>Brand</h5>
                                            <p>
                                                {Listing.product.brand.name}
                                            </p>
                                        </div>

                                        <div class="col-12">
                                            <h5>Part Number</h5>
                                            <p>
                                                {Listing.product.part_numbers}
                                            </p>
                                        </div>

                                        <div class="col-12">
                                            <h5>Description [from OEM]</h5>
                                            <p>
                                                {Listing.description || "---"}
                                            </p>

                                            <h5>Linked vehicles</h5>
                                            <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                                                {
                                                    Listing.linked_vehicles.length > 0 ? (
                                                        Listing.linked_vehicles.map(linked_vehicle => {
                                                            console.log("linked_vehicle: ", linked_vehicle)

                                                            return (
                                                                linked_vehicle.vehicles.map((vehicle, index) => {
                                                                    return (
                                                                        <p key={index}>
                                                                            {vehicle.long_name_display}
                                                                        </p>
                                                                    )
                                                                })
                                                            )

                                                        })
                                                    ) : (
                                                        <p>null</p>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <h5>Date Created</h5>
                                            <p>
                                                {SetDateTime(Listing.created_at)}
                                            </p>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <h5>Last Updated</h5>
                                            <p>
                                                {SetDateTime(Listing.updated_at)}
                                            </p>
                                        </div>

                                        <div class="col-12">
                                            <h5>Is Sponsored</h5>
                                            <p>
                                                {
                                                    Listing.is_sponsored ? (
                                                        <>
                                                            True [Expires {SetDateTime(Listing.campaign.expires_at)}]
                                                        </>
                                                    ) : (
                                                        <>
                                                            False
                                                            <a onClick={toggleModal} href="javascript: void(0);" class="btn- btn-xs btn-info ml-1">Sponsor Listing</a>
                                                        </>
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                {/* {
                                    Listing && (
                                        <div class="card-box">
                                            <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">Product Images</h5>

                                            <div class="d-flex flex-column align-items-center" data-dz-message="">
                                                <i class="h1 text-muted dripicons-cloud-upload"></i>
                                                <h5>Drop files here or click to upload.</h5>
                                                <span class="text-muted font-13">(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)</span>
                                            </div>
                                        </div>
                                    )
                                } */}

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                {
                                    Listing && (
                                        <div class="card-box">
                                            <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">Product Images</h5>

                                            <div class="d-flex flex-column align-items-center" data-dz-message="">
                                                <i class="h1 text-muted dripicons-cloud-upload"></i>
                                                <h5>Drop files here or click to upload.</h5>
                                                <span class="text-muted font-13">(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12">
                                <div class="text-center mb-3">
                                    <button type="button" class="btn w-sm btn-light waves-effect">Cancel</button>
                                    <button type="button" class="btn w-sm btn-success waves-effect waves-light">Save</button>
                                    <button type="button" class="btn w-sm btn-danger waves-effect waves-light">Delete</button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    )
}
