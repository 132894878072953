import React, { useState } from 'react';
import {
    PostMan,
    BuildImageUri,
    NumberWithCommas
} from '../../../../../../../Helpers';
import {
    apiUrl
} from '../../../../../../../App.json';

import Button from '../../../../../../../utils/Button';
import VehiclePlaceholder from '../../../../../../../assets/images/vehicle.png';


export default function UserProfileCard(props) {
    const {
        reportId,
        diagnosticsReport
    } = props

    const [PageButtons, setPageButtons] = useState({
        attemptGenerateReport: {
            text: {
                style: null,
                value: (
                    <><i class="mdi mdi-atom mr-1"></i>Generate Report</>
                ),
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary waves-effect waves-light mt-4',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        downloadReport: {
            text: {
                style: null,
                value: (
                    <>
                        <i class="mdi mdi-cloud-download-outline mr-1"></i>
                        Download Report
                    </>
                ),
            },
            props: {
                // type: "button",
                style: { color: 'white', },
                className: 'btn btn-danger waves-effect waves-light mt-3',
                download: true,
                target: "_blank"
            },
            href: diagnosticsReport && diagnosticsReport.file,
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
    })

    const AttemptGenerateReport = async () => {
        // Generated Report HTML
        const generatedReportLink = apiUrl + `diagnostics/reports/${reportId}/generate/`
        // Open in new tab
        window.open(`https://pdf.234parts.com/screenshot?url=${generatedReportLink}`, '_blank');
    }

    const AttemptDownloadReport = async () => {
        
    }

    return (
        <>
        {
            diagnosticsReport ? (

                <div class="card-box" dir="ltr">
                    <div class="text-center">
                        <h5>{`${diagnosticsReport.diagnostics_request.vehicle.make.manufacturer.name} ${diagnosticsReport.diagnostics_request.vehicle.make.name}`}</h5>
                        <div class="row mt-2">
                            <div class="col-6">
                                <h3 data-plugin="counterup">{diagnosticsReport.diagnostics_request.vehicle.engine}</h3>
                                <p class="text-muted font-13 mb-0 text-truncate">Engine</p>
                            </div>
                            <div class="col-6">
                                <h3 data-plugin="counterup">{diagnosticsReport.diagnostics_request.vehicle.trim}</h3>
                                <p class="text-muted font-13 mb-0 text-truncate">Trim</p>
                            </div>
                        </div>

                        {
                            diagnosticsReport.diagnostics_request.vehicle.image_url ? (
                                <img style={{ height: "150px" }} src={BuildImageUri(diagnosticsReport.diagnostics_request.vehicle.image_url)} />
                            ) : (
                                <img src={VehiclePlaceholder} style={{ height: "100px" }} />
                            )
                        }

                        {
                            diagnosticsReport.status.value != "open" ? (
                                <>
                                    <div>
                                        {
                                            diagnosticsReport.faults.length > 0 ? (
                                                <Button
                                                    {...PageButtons.attemptGenerateReport}
                                                    {...{ onClick: () => AttemptGenerateReport() }}
                                                />
                                            ) : null
                                        }
                                    </div>

                                    <div>
                                        {
                                            diagnosticsReport.file ? (
                                                <Button
                                                    {...PageButtons.downloadReport}
                                                    {...{
                                                        href: diagnosticsReport.file
                                                    }}
                                                />
                                            ) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }

                    </div>

                </div>

            ) : null
        }
        </>
    )
}
