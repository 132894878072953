import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
	PostMan, 
	SetDateTime
} from '../../../../../../../Helpers';

import PageActivityIndicator from '../../../../../../../utils/indicator/PageActivityIndicator';


export default function VendorList() {
	const [VendorList, setVendorList] = useState([])
	const [IsLoading, setIsLoading] = useState(false)

	const FetchVendors = async () => {
		//
		setIsLoading(true)
		//
		const responseObject = await PostMan(
			`account/user/all/?account_type=vendor`,
			'GET'
		)
		//
		setIsLoading(false)
		//
		if (responseObject.status === 'success') {
			let responseData = responseObject.data
			// Update VendorList in state.
			await setVendorList(responseData)
		} else {
		}
	}

	useEffect(() => {
		// Fetch Diagnostics List
		FetchVendors()
		
	}, [])

	return (
		<>
			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<div class='row'>
				<div class='col-12'>
					<div class='card'>
						<div class='card-body'>
							<div class='row mb-2'>
								<div class='col-sm-4'>
									<a
										href='#'
										data-toggle='modal'
										data-target='#add-vendor'
										class='btn btn-md btn-danger'
									>
										<i class='mdi mdi-plus-circle-outline'></i> Add Vendor
									</a>
								</div>
								<div class='col-sm-8'></div>
							</div>

							<div class='table-responsive'>
								<table
									class='table table-centered table-striped'
									id='agent-datatable'
								>
									<thead>
										<tr>
											<th style={{ width: '20px' }}>
												<div class='custom-control custom-checkbox'>
													<input
														type='checkbox'
														class='custom-control-input'
														id='customCheck1'
													/>
													<label class='custom-control-label'>&nbsp;</label>
												</div>
											</th>
											<th>Names</th>
											<th>Phone</th>
											<th>Email</th>
											<th>Stores</th>
											<th>Create Date</th>
											<th>Status</th>
											<th style={{ width: '85px' }}>Action</th>
										</tr>
									</thead>
									<tbody>
										{VendorList.map((user) => {
											return (
												<tr key={user.id}>
													<td>
														<div class='custom-control custom-checkbox'>
															<input
																type='checkbox'
																class='custom-control-input'
																id='customCheck2'
															/>
															<label class='custom-control-label'>&nbsp;</label>
														</div>
													</td>
													<td class='table-user'>
														<Link
															to={`vendors/${user.id}`}
															class='text-body font-weight-semibold'
														>
															{user.full_name}
														</Link>
													</td>
													<td>{user.mobile}</td>
													<td>{user.email ? user.email : '---'}</td>
													<td>
														{user.manage ? user.managed_stores.count : '---'}
													</td>
													<td>{SetDateTime(user.created_at)}</td>
													<td>
														{user.is_active ? (
															<span class='badge bg-soft-success text-success shadow-none'>
																Active
															</span>
														) : (
															<span class='badge bg-soft-danger text-danger shadow-none'>
																Inactive
															</span>
														)}
													</td>

													<td>
														<a href={`vendors/${user.id}`} class='action-icon'>
															{' '}
															<i class='mdi mdi-square-edit-outline'></i>
														</a>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
