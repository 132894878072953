import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";


import HomeScreen from './screens/HomeScreen';

import BaseDashboard from '../../components/diagnostics/BaseDashboard';

import LoginScreen from '../auth/LoginScreen';

import ReportsScreen from "./screens/ReportsScreen";


function RouterBase() {

    return (
        <>
            <Router>

            
                {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}

                <Switch>
                    <Route path="/reports">
                        <BaseDashboard>
                            <ReportsScreen />
                        </BaseDashboard>
                    </Route>

                    <Route exact path="/login">
                        <LoginScreen />
                    </Route>

                    <Route path="/">
                        <BaseDashboard>
                            <HomeScreen />
                        </BaseDashboard>
                    </Route>                    

                </Switch>

            </Router>
        </>
    );
}

export default withRouter(RouterBase)