import React from 'react'

import {
    Switch,
    Route,
    withRouter,
    useRouteMatch
} from "react-router-dom";
import BreadCrumb from '../../../components/BreadCrumb';

import VehicleReportList from '../../../components/diagnostics/VehicleReportList';
import VehicleReportDetail from '../../../components/diagnostics/VehicleReportDetail';

// import ClientList from '../../../components/diagnostics/ClientList';
// import ClientAddNew from '../../../components/diagnostics/ClientAddNew';
// import ClientDetailScreen from './ClientDetailScreen';
// import ClientVehicleDetailScreen from './ClientVehicleDetailScreen';


export default function CLientsScreen() {
    const match = useRouteMatch()

    return (
        <>
            <Switch>
                <Route path={`${match.path}/:reportId`}>
                    <VehicleReportDetail />
                </Route>

                <Route exact path={`${match.path}`}>

                    <BreadCrumb title={"Reports"} />

                    <VehicleReportList />
                </Route>

            </Switch>
            
        </>
    )
}
