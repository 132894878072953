import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';

import {
    PostMan,
    SetDateTime
} from '../../../../../../../../Helpers';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CSVLink } from "react-csv";


export default function ListingsTable(props) {
    const {
        store
    } = props

    const [StoreListings, setStoreListings] = useState(null) 

    const referrerTableAction = (listing) => {
        return (
            <Link to={`/autoshop/listings/${listing.id}`} class="action-icon" target="_blank">
                <i class='mdi mdi-square-edit-outline'></i>
            </Link>
        )
    }

    const referrerTableCheckbox = (id = 0) => {
        return (
            <div class='custom-control custom-checkbox'>
                <input
                    type='checkbox'
                    class='custom-control-input'
                    id={`customCheck${id}`}
                />
                <label
                    class='custom-control-label'
                    for={`customCheck${id}`}
                >
                    &nbsp;
                </label>
            </div>
        )
    }

    // const fetchStoreListings = useCallback(
    //     async () => {
    //         const responseObject = await PostMan(
    //             `auto-shop/${store.id}/listing/all/`,
    //             'GET'
    //         )
    //         if (responseObject.status === 'success') {
    //             let responseData = responseObject.data
    //             console.log('responseData: ', responseData)
                
    //             // console.log('Store Listings: ', responseData)
    //             // await setStoreListings(responseData)
    //         }
    //         else { }
    //     }
    // )

    const columns = useMemo(
        () => [
            {
                Header: referrerTableCheckbox(),
                accessor: 'col0',
            },
            {
                Header: 'Name',
                accessor: 'col1',
            },
            {
                Header: 'Product',
                accessor: 'col2',
            },
            {
                Header: 'Price',
                accessor: 'col3',
            },
            {
                Header: 'Condition',
                accessor: 'col4',
            },
            {
                Header: 'Created At',
                accessor: 'col5',
                // defaultCanSort: true
            },
            {
                Header: 'Action',
                accessor: 'col6',
            },
        ], []
    )

    const data = useMemo(
        () => {
            const listings = []
            store.listings.map((listing, _index) => {
                const index = _index + 1
                listings.push({
                    col0: referrerTableCheckbox(index),
                    col1: listing.name,
                    col2: listing.product.name,
                    col3: listing.price,
                    col4: listing.condition,
                    col5: listing.created_at,
                    col6: referrerTableAction(listing)
                })
            })
            return listings
        }, [StoreListings]
    )

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                Search: {' '}
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                    }}
                />
            </span>
        )
    }

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 20
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const {
        headerGroups,
        rows,
        page,
        getTableProps,
        getTableBodyProps,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            globalFilter
        },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = tableInstance


    const currentTableData = useMemo(
        () => {
            const tableData = []
            const headerArray = []
            columns.slice(1, 5).map(column => {
                headerArray.push(column.Header)
            })
            tableData.push(headerArray)
            //
            rows.map(row => {
                const rowArray = []
                prepareRow(row)
                row.cells.slice(1, 5).map(cell => rowArray.push(cell.value))
                tableData.push(rowArray)
            })
            return tableData
        }
    )


    return (
        <>
            <div class="table-responsive">
                <table {...getTableProps()} class='table table-centered table-striped'>
                    <thead>
                        {// Loop over the header rows
                            headerGroups.map((headerGroup, i) => (
                                // Apply the header row props
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {// Loop over the headers in each row
                                        headerGroup.headers.map((column, i) => (
                                            // Apply the header cell props
                                            <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {// Render the header
                                                    column.render('Header')
                                                }
                                                {/* Add a sort direction indicator */}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }

                    </thead>
                    <tbody {...getTableBodyProps()}>
                        <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </th>
                        </tr>

                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr key={i} {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => {
                                        return (
                                            <td key={i} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>


                <div className="pagination-wrapper">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled">
                                <a onClick={() => previousPage()} disabled={!canPreviousPage}
                                    class="page-link"
                                    href="javascript: void(0);"
                                    tabindex="-1"
                                >Previous</a>
                            </li>

                            {
                                [...Array(pageOptions.length)].map((p, index) => {
                                    const activePageIndex = pageIndex + 1
                                    const pageCount = index + 1
                                    return (
                                        <li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
                                            <a class="page-link"
                                                href="javascript: void(0);"
                                            >{pageCount}</a>
                                        </li>
                                    )
                                })
                            }

                            <li class="page-item">
                                <a onClick={() => nextPage()} disabled={!canNextPage}
                                    class="page-link"
                                    href="javascript: void(0);"
                                >Next</a>
                            </li>
                        </ul>
                    </nav>

                    <div style={{ textAlign: 'center' }}>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                    </div>
                </div>
            </div>

            <ul class="pagination pagination-rounded justify-content-end mb-0 mt-2">
                <li class="page-item">
                    <a class="page-link" href="javascript: void(0);" aria-label="Previous">
                        <span aria-hidden="true">«</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                <li class="page-item active"><a class="page-link" href="javascript: void(0);">1</a></li>
                <li class="page-item"><a class="page-link" href="javascript: void(0);">2</a></li>
                <li class="page-item"><a class="page-link" href="javascript: void(0);">3</a></li>
                <li class="page-item"><a class="page-link" href="javascript: void(0);">4</a></li>
                <li class="page-item"><a class="page-link" href="javascript: void(0);">5</a></li>
                <li class="page-item">
                    <a class="page-link" href="javascript: void(0);" aria-label="Next">
                        <span aria-hidden="true">»</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </>
    )
}
