import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CSVLink } from "react-csv";


export default function ReferralTable(props) {
    const {
        listType,
        referrerList,
        attemptFetchReferrerList,
        startDate,
        endDate,
        setStartDate,
        setEndDate
    } = props

    const referrerTableAction = (referree) => {
        return (
            <Link to={`/referrals/${referree.id}/${listType}`} class="action-icon" target="_blank">
                <i class='mdi mdi-square-edit-outline'></i>
            </Link>
        )
    }

    const referrerTableCheckbox = (id = 0) => {
        return (
            <div class='custom-control custom-checkbox'>
                <input
                    type='checkbox'
                    class='custom-control-input'
                    id={`customCheck${id}`}
                />
                <label
                    class='custom-control-label'
                    for={`customCheck${id}`}
                >
                    &nbsp;
                </label>
            </div>
        )
    }
    
    const columns = useMemo(
        () => [
            {
                Header: referrerTableCheckbox(),
                accessor: 'col0',
            },
            {
                Header: 'Full Name',
                accessor: 'col1',
            },
            {
                Header: 'Phone',
                accessor: 'col2',
            },
            {
                Header: 'Email',
                accessor: 'col3',
            },
            {
                Header: 'Code',
                accessor: 'col4',
            },
            {
                Header: 'Referrals',
                accessor: 'col5',
                defaultCanSort: true
            },
            {
                Header: 'Action',
                accessor: 'col6',
            },
        ], []
    )

    const data = useMemo(
        () => {
            const referralData = []
            referrerList.map((referree, _index) => {
                const index = _index + 1
                referralData.push({
                    col0: referrerTableCheckbox(index),
                    col1: referree.full_name,
                    col2: referree.mobile,
                    col3: referree.email ? referree.email : '---',
                    col4: referree.referral_code,
                    col5: referree.referral_count,
                    col6: referrerTableAction(referree)
                })
            })
            return referralData
        }, [referrerList]
    )

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                Search:{' '}
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                    }}
                />
            </span>
        )
    }    

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 20
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const {
        headerGroups,
        rows,
        page,
        getTableProps,
        getTableBodyProps,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {
            pageIndex, 
            pageSize, 
            globalFilter 
        },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = tableInstance


    const currentTableData = useMemo(
        () => {
            const tableData = []
            const headerArray = []
            columns.slice(1, 5).map(column => {
                headerArray.push(column.Header)
            })
            tableData.push(headerArray)
            //
            rows.map(row => {
                const rowArray = []
                prepareRow(row)
                row.cells.slice(1, 5).map(cell => rowArray.push(cell.value))
                tableData.push(rowArray)
            })
            return tableData
        }
    )


    return (
        <>
            <div class='table-responsive'>
                <div class="page-title-box">
                    <div class="page-title-right">
                        <div class="form-group">
                            <div class="input-group input-group-sm">
                                <div style={{ margin: "0 10px" }}>
                                    From
                                </div>

                                <div class="input-group-append">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                    <span class="input-group-text bg-blue border-blue text-white">
                                        <i class="mdi mdi-calendar-range font-13"></i>
                                    </span>
                                </div>

                                <div style={{ margin: "0 10px" }}>
                                    To
                                </div>

                                <div class="input-group-append">
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                    <span class="input-group-text bg-blue border-blue text-white">
                                        <i class="mdi mdi-calendar-range font-13"></i>
                                    </span>
                                </div>

                                <a onClick={async () => await attemptFetchReferrerList()} href="javascript: void(0);" class="btn btn-blue btn-sm ml-2 mr-2">
                                    <i class="mdi mdi-autorenew"></i>
                                </a>


                                <CSVLink class="btn btn-blue btn-sm mr-2" data={currentTableData}>
                                    <i class="mdi mdi-download"></i>
                                </CSVLink>

                            </div>
                        </div>
                    </div>

                    <h4 class="page-title text-uppercase">
                        <i class="mdi mdi-cards-variant"></i>
                        Referrers
                    </h4>
                </div>


                {
                    referrerList ? (
                        <>
                            <table {...getTableProps()} class='table table-centered table-striped'>
                                <thead>
                                    {// Loop over the header rows
                                        headerGroups.map((headerGroup, i) => (
                                            // Apply the header row props
                                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                                {// Loop over the headers in each row
                                                    headerGroup.headers.map((column, i) => (
                                                        // Apply the header cell props
                                                        <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {// Render the header
                                                                column.render('Header')
                                                            }
                                                            {/* Add a sort direction indicator */}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? ' 🔽'
                                                                        : ' 🔼'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }

                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    <tr>
                                        <th
                                            colSpan={visibleColumns.length}
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            <GlobalFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        </th>
                                    </tr>

                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr key={i} {...row.getRowProps()}>
                                                {row.cells.map((cell, i) => {
                                                    return (
                                                        <td key={i} {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                            <div className="pagination-wrapper">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item disabled">
                                            <a onClick={() => previousPage()} disabled={!canPreviousPage}
                                                class="page-link"
                                                href="javascript: void(0);"
                                                tabindex="-1"
                                            >Previous</a>
                                        </li>

                                        {
                                            [...Array(pageOptions.length)].map((p, index) => {
                                                const activePageIndex = pageIndex + 1
                                                const pageCount = index + 1
                                                return (
                                                    <li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
                                                        <a class="page-link"
                                                            href="javascript: void(0);"
                                                        >{pageCount}</a>
                                                    </li>
                                                )
                                            })
                                        }

                                        <li class="page-item">
                                            <a onClick={() => nextPage()} disabled={!canNextPage}
                                                class="page-link"
                                                href="javascript: void(0);"
                                            >Next</a>
                                        </li>
                                    </ul>
                                </nav>

                                <div style={{ textAlign: 'center' }}>
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{' '}
                                    </span>
                                </div>
                            </div>

                        </>
                    ) : null
                }
            </div>
        </>
    )
}
