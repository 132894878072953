import React, { useState, useEffect } from 'react';
import {
    PostMan
} from '../../../../../Helpers';


export default function ReferralDashboard() {
    const [Dashboard, setDashboard] = useState(null)

    const FetchReferralDashboard = async () => {
        const responseObject = await PostMan(`referral/dashboard/`, 'GET')
        if (responseObject.status === 'success') {
            let referralData = responseObject.data
            console.log('Referral: ', referralData)
            // Update ReferralList in state.
            await setDashboard(referralData)
        } else {
        }
    }

    useEffect(()=> {
        //
        FetchReferralDashboard()
    }, [])

    return (
        <div class="row">
            <div class="col-md-6">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="fe-users font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.referrees_count || "N/A"}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Referees</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="dripicons-store font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.referral_count || "N/A"}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Referrals</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
