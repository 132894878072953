import React from 'react';
import { Link } from 'react-router-dom';

export default function VehicleFaultItem(props) {
    const {
        faultItem,
        diagnosticsReport
    } = props

    function getFaultStatusColor(status) {
        if (status === "low") { return "#85d735" }
        else if (status === "medium") { return "#e2d415" }
        else if (status === "major") { return "#eeb74a" }
        else if (status === "critical") { return "#f93208" }
    }

    return (
        <div class="card-box mb-2">
            <div class="row align-items-center">
                <div class="col-sm-10">
                    <div class="media">
                        <div class="media-body">
                            <h4 class="mt-0 mb-2 font-16">{faultItem.assembly_group.name}</h4>
                            
                            <p class="mb-1">
                                <b>Severity Scale:</b>
                                <span className={`badge font-14 p-1 ml-3 shadow-none`} style={{ backgroundColor: getFaultStatusColor(faultItem.severity_scale.value), color: "white" }}>
                                    {faultItem.severity_scale.display}
                                </span>
                            </p>

                            <p class="mb-1">
                                <b style={{ marginRight: '20px' }}>Suspected Parts:</b>
                                {
                                    faultItem.suspected_parts.length > 0 && faultItem.suspected_parts.map(suspected_part => {
                                        return (
                                            <span class="badge badge-primary badge-pill mx-1">{suspected_part.name}</span>
                                        )
                                    }) || "N/A"
                                }
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-2">
                    <div class="text-sm-right text-center mt-2 mt-sm-0">
                        {
                            diagnosticsReport.version.value == "final" ? (
                                <Link to={`/diagnostics/reports/${diagnosticsReport.id}/fault/${faultItem.id}`}>
                                    <i class="mdi mdi-square-edit-outline"></i>
                                </Link>
                            ) : null
                        }
                        {/* <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a> */}
                        {/* <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
