import React from 'react'
import { Link } from 'react-router-dom'

import ReportPlaceholder from '../../assets/images/report.png'


export default function VehicleFaultList(props) {
    const {
        report
    } = props

    function getFaultStatusColor(status) {
        if (status === "low") { return "primary" }
        else if (status === "medium") { return "info" }
        else if (status === "major") { return "warning" }
        else if (status === "critical") { return "danger" }
    }

    return (
        <>
            <div class="card mb-2">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4">
                            {/* <form class="form-inline">
                                <div class="form-group">
                                    <label for="inputPassword2" class="sr-only">Search</label>
                                    <input type="search" class="form-control" id="inputPassword2" placeholder="Search..." />
                                </div>
                            </form> */}
                        </div>
                        <div class="col-sm-8">
                            <div class="text-sm-right">
                                <Link to={{
                                    pathname: `/reports/${report.id}/add-fault`,
                                    state: { report: report }
                                }} class="btn btn-danger waves-effect waves-light">
                                    <i class="mdi mdi-plus-circle mr-1"></i>
                                    Add Fault
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div style={{ maxHeight: '500px', overflowY: 'auto' }}> */}

            <div>
                
                {
                    report && report.faults.length > 0 ? (
                        <>
                            
                            {
                                report.faults.map(fault => {
                                    return (
                                        <div class="card-box mb-2">
                                            <div class="row align-items-center">
                                                <div class="col-sm-10">
                                                    <div class="media">
                                                        <div class="media-body">
                                                            <h4 class="mt-0 mb-2 font-16">{fault.auto_part.name}</h4>
                                                            <p class="mb-0"><b>Assembly Group:</b> {fault.auto_part.automotive_category.name}</p>
                                                            <p class="mb-1"><b>Severity Scale:</b>
                                                                <span className={`badge font-14 bg-soft-${getFaultStatusColor(fault.severity_scale.value)} text-${getFaultStatusColor(fault.severity_scale.value)} p-1 ml-3 shadow-none`}>
                                                                    {fault.severity_scale.display}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2">
                                                    <div class="text-sm-right text-center mt-2 mt-sm-0">
                                                        <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                                        <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </>
                    ) : (
                        <div class="card-box mb-2" style={{ padding: "50px" }}>
                            <div class="row align-items-center justify-content-center">
                                <div class="d-flex flex-column flex-md-row">
                                    <img class="d-flex align-self-center mr-3 my-3 rounded-circle" src={ReportPlaceholder} alt="Generic placeholder image" height="64" />

                                    <div className="my-3">
                                        <h4 class="mt-0 mb-2 font-16">No faults have been added to this report.</h4>
                                        <Link to={{
                                            pathname: `/reports/${report.id}/add-fault`,
                                            state: { report: report }
                                        }} class="btn btn-danger waves-effect waves-light">
                                            <i class="mdi mdi-plus-circle mr-1"></i>
                                            Add Fault
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }               
                
            </div>
        </>
    )
}
