import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";

import HomeScreen from './screens/HomeScreen';


import ProtectedRoute from "../../utils/ProtectedRoute";


function RouterBase() {

    return (
        <Router>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

            <Switch>
                {/* <ProtectedRoute>
                    <Route path="/account" component={ProfileScreen} />
                </ProtectedRoute> */}

                {/* <Route path="/search">
                    <ProtectedRoute>
                        <SearchScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/order">
                    <ProtectedRoute>
                        <OrderScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/offers">
                    <ProtectedRoute>
                        <OffersScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/account">
                    <ProtectedRoute>
                        <ProfileScreen />
                    </ProtectedRoute>
                </Route>

                <Route path="/wallet">
                    <ProtectedRoute>
                        <WalletScreen />
                    </ProtectedRoute>
                </Route>

                <Route exact path="/login">
                    <LoginScreen />
                </Route>
                <Route exact path="/logout">
                    <LogoutScreen />
                </Route>
                <Route exact path="/register">
                    <RegisterScreenOne />
                </Route>
                <Route path="/register/extra-information">
                    <RegisterScreenTwo />
                </Route>
                <Route path="/phone-verification">
                    <PhoneVerification />
                </Route>


                <Route exact path="/password-reset/request">
                    <PasswordResetRequest />
                </Route>
                <Route exact path="/password-reset/sent">
                    <PasswordResetSent />
                </Route>
                <Route exact path="/password-reset/">
                    <PasswordReset />
                </Route>


                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/terms-and-conditions" component={TermsCondition} /> */}

                <Route path="/" component={HomeScreen} />

            </Switch>

        </Router>
    );
}

export default withRouter(RouterBase)