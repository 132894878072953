import React, { useEffect, useState, useCallback } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { PostMan } from '../../../../../../Helpers';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from '../../../../../../utils/Button';
import FormField from '../../../../../../utils/FormField';
import BreadCrumb from '../../../../../../components/BreadCrumb';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommunicationHistory from './components/CommunicationHistory';


function Index(props) {
    const {
        auth
    } = props

    const match = useRouteMatch()

    const communicationSlug = match.params.communicationSlug

    console.log("communicationSlug: ", communicationSlug)

    const [redirect, setRedirect] = useState(null)

    const [Communication, setCommunication] = useState(false)

    const [formData, setFormData] = useState({
        name: {
            element: 'input',
            value: Communication && Communication.name || '',
            showLabel: true,
            label: {
                text: 'Name',
                props: null,
            },
            props: {
                name: 'name_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication name',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        recipients: {
            element: 'select',
            data: [],
            value: '',
            showLabel: true,
            label: {
                text: 'Recipients',
                props: null,
            },
            props: {
                name: 'subject_input',
                type: 'text',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        subject: {
            element: 'input',
            value: Communication && Communication.subject || '',
            showLabel: true,
            label: {
                text: 'Subject',
                props: null,
            },
            props: {
                name: 'subject_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication subject',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        content: {
            element: 'textarea',
            value: Communication && Communication.content || '',
            showLabel: true,
            label: {
                text: 'Content',
                props: null,
            },
            props: {
                name: 'content_input',
                type: 'text',
                className: 'form-control',
                required: true,
                style: {
                    height: "550px"
                }
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        action: {
            element: 'input',
            value: Communication && Communication.action || '',
            showLabel: true,
            label: {
                text: 'Action [Optional]',
                props: null,
            },
            props: {
                name: 'action_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication deep link',
                required: false,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
    })

    const [PageButtons, setPageButtons] = useState({
        sendMessage: {
            text: {
                style: null,
                value: "Send Message",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        saveToDraft: {
            text: {
                style: null,
                value: "Save To Draft",
            },
            props: {
                type: "button",
                style: {
                    margin: '0 10px 0'
                },
                className: 'btn btn-danger',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
    })

    

    const AttemptSendMessage = useCallback(
        async () => {
            // Start Loader
            let newPageButtons = PageButtons
            newPageButtons.sendMessage.loader.isLoading = true
            await setPageButtons({ ...newPageButtons })
            //
            let payload = {
                subject: formData.subject.value,
                content: formData.content.value,
                action: formData.action.value,
                recipients: Communication.recipients.name,
                communication: Communication.id,
                sent_by: auth.user.id
            }
            const responseObject = await PostMan(`notifications/push/communications/history/`, 'POST', payload)
            // Start Loader
            newPageButtons.sendMessage.loader.isLoading = false
            await setPageButtons({ ...newPageButtons })

            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                console.log("responseData: ", responseData)
                //
                window.location.reload()
            } else {
            }
        }
    )

    const AttemptSaveToDraft = useCallback(
        async () => {
            // Start Loader
            let newPageButtons = PageButtons
            newPageButtons.saveToDraft.loader.isLoading = true
            await setPageButtons({ ...newPageButtons })
            //
            let payload = {}
            for (let formField in formData) {
                let fieldName = formField
                let fieldData = formData[formField]
                if (fieldData.props.required) {
                    if (
                        !fieldData.value ||
                        fieldData.value == ' ' ||
                        fieldData.value == 0
                    ) {
                        // Toast Error Message
                        toast.error(`${fieldData.label.text} field is required!`)
                        // Stop Loader
                        newPageButtons.saveToDraft.loader.isLoading = false
                        return setPageButtons({ ...newPageButtons })
                    }
                }
                //
                payload[fieldName] = fieldData.value
            }

            const responseObject = await PostMan(`notifications/push/communication/${communicationSlug}/`, 'PATCH', payload)

            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                console.log("responseData: ", responseData)
                //
                window.location.reload()
            } else {
            }
        }
    )

    const FetchCommunication = useCallback(
        async () => {
            const responseObject = await PostMan(
                `notifications/push/communication/${communicationSlug}/`,
                'GET'
            )
            if (responseObject.status === 'success') {
                let communicationData = responseObject.data
                // Save Communication in state.
                await setCommunication(communicationData)
                //
                let newFormData = formData
                for (let field in communicationData) {
                    let fieldValue = communicationData[field]
                    if (newFormData[field]) {
                        if (field == "recipients") {
                            newFormData[field].value = fieldValue.id
                        } else {
                            newFormData[field].value = fieldValue
                        }
                    }
                }
                await setFormData({ ...newFormData })
            }
            else { }
        }, []
    )

    const FetchCommunicationList = useCallback(
        async () => {
            const responseObject = await PostMan(
                `notifications/communication-list`,
                'GET'
            )
            if (responseObject.status === 'success') {
                let communicationLists = responseObject.data
                let newFormData = formData
                communicationLists.map(commList => {
                    newFormData.recipients.data.push({
                        value: commList.id,
                        display: commList.name,
                    })
                })
                // Update FormData in state.
                await setFormData({ ...newFormData })
            }
            else { }
        }, []
    )

    useEffect(() => {
        // Fetch Comm List
        FetchCommunicationList()

        // Fetch Communication Detail
        FetchCommunication()

    }, [])

    return (
        <div class="container-fluid" style={{ paddingBottom: "200px" }}>

            <ToastContainer />

            <BreadCrumb
                title="Push Communication"
                crumbs={[
                    {
                        title: "Push Notifications",
                        path: "/push-notification/"
                    },
                    {
                        title: "Push Communication"
                    }
                ]}
            />

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                 
                            {
                                Communication && (
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'name',
                                                    config: formData.name,
                                                }}
                                            />

                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'recipients',
                                                    config: formData.recipients,
                                                }}
                                            />

                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'subject',
                                                    config: formData.subject,
                                                }}
                                            />

                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'action',
                                                    config: formData.action,
                                                }}
                                            />

                                            <FormField
                                                formData={formData}
                                                change={(newFormData) => setFormData({ ...newFormData })}
                                                field={{
                                                    id: 'content',
                                                    config: formData.content,
                                                }}
                                            />
                                        </div>

                                        <div className='col-12'>
                                            <Button {...PageButtons.saveToDraft} {...{ onClick: () => AttemptSaveToDraft() }} />

                                            <Button {...PageButtons.sendMessage}
                                            {...{ onClick: () => AttemptSendMessage() }} 
                                            />
                                        </div>

                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>

            {
                Communication && (
                    <CommunicationHistory
                        communication={Communication}
                    />
                )
            }
            
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)