import React, { useState, useEffect, useCallback } from 'react';
import { PostMan, NumberWithCommas } from '../../../../../../Helpers';
import { useLocation } from 'react-router-dom';

import BreadCrumb from '../../../../../../components/BreadCrumb';

import ReferralDashboard from '../ReferralDashboard';
import ReferrerTable from '../ReferrerTable';

import PageActivityIndicator from '../../../../../../utils/indicator/PageActivityIndicator';


export default function ReferrerList(props) {
	const {
		listType="customer"
	} = props

	const location = useLocation();

	const [ReferrerList, setReferrerList] = useState([])

	const [IsLoading, setIsLoading] = useState(false)

	const [StartDate, setStartDate] = useState("");
	const [EndDate, setEndDate] = useState("");
	
	const AttemptFetchReferrerList = useCallback(
		async () => {
			//
			setIsLoading(true)
			let urlPath = "referral/customer-referrers/"
			if (listType === "vendor") {
				urlPath = `referral/vendor-referrers/`
			}
			if (StartDate && EndDate) {
				urlPath += `?start_date=${StartDate.toISOString()
					}&end_date=${EndDate.toISOString()}`
			}
			const responseObject = await PostMan(urlPath, 'GET')
			//
			setIsLoading(false)
			if (responseObject.status === 'success') {
				let responseData = responseObject.data
				let referrerList = responseData.data
				let date_range = responseData.date_range
				if (!StartDate && date_range) {
					let startDate = responseData.date_range.start_date
					await setStartDate(new Date(startDate))
				}
				if (!EndDate && date_range) {
					let endDate = responseData.date_range.end_date
					await setEndDate(new Date(endDate))
				}
				// Update ReferreeList in state.
				await setReferrerList(referrerList)
			} else {
			}
		},
		[location, StartDate, EndDate],
	)

	useEffect(() => {
		// Fetch Referral List
		AttemptFetchReferrerList()

	}, [location])

	return (
		<>
			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<BreadCrumb title={"Referral Program"} 
				crumbs={[
					{
						title: "Referees",
						path: null,
					},
				]}
			/>

			<div class="container-fluid">

				<ReferralDashboard />

				<div class='row'>
					<div class='col-12'>
						<div class='card'>
							<div class='card-body mt-3'>
								{
									ReferrerList
									&&
									<ReferrerTable 
										listType={listType}
										referrerList={ReferrerList}
										attemptFetchReferrerList={() => AttemptFetchReferrerList()}
										startDate={StartDate}
										endDate={EndDate}
										setStartDate={(newData) => setStartDate(newData)}
										setEndDate={(newData) => setEndDate(newData)}
									/>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
