import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { clientUrl } from '../../App.json';
import { PostMan } from '../../Helpers';
import { login } from '../../redux/actions/AuthActions';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../utils/Button';
import FormField from '../../utils/FormField';

import Logo from '../../assets/images/logo.png';

function LoginScreen(props) {
	const { auth, login: reduxLogin } = props

	const [redirect, setRedirect] = useState(null)

	const [FormData, setFormData] = useState({
		username: {
			element: 'input',
			value: '',
			showLabel: true,
			label: {
				text: 'Email or Phone number',
				props: null,
			},
			props: {
				name: 'username_input',
				type: 'text',
				className: 'form-control',
				placeholder: 'Enter email or phone number',
				required: true,
			},
			wrapperProps: {
				className: 'form-group mb-3',
			},
		},
		password: {
			element: 'input',
			value: '',
			showLabel: true,
			label: {
				text: 'Password',
				props: null,
			},
			props: {
				name: 'password_input',
				type: 'password',
				className: 'form-control',
				placeholder: 'Enter password',
				required: true,
			},
			wrapperProps: {
				className: 'form-group mb-3',
			},
		},
		rememberMe: {
			element: 'checkbox',
			checked: false,
			data: [],
			showLabel: true,
			label: {
				text: 'Remmber me',
				props: {
					className: 'form-check-label',
					for: 'id_rememberMe',
				},
			},
			props: {
				id: 'id_rememberMe',
				name: 'remember_me_input',
				type: 'checkbox',
				className: 'form-check-input',
				// required: true,
			},
			wrapperProps: {
				className: 'form-group form-check',
			},
		},
	})

	const [PageButtons, setPageButtons] = useState({
		signIn: {
			text: {
				style: null,
				value: 'Sign in',
			},
			props: {
				type: 'button',
				style: null,
				className: 'btn btn-primary btn-block',
			},
			onClick: () => AttemptSignIn(),
			loader: {
				isLoading: false,
				size: 20,
				style: {
					color: 'black',
				},
			},
		},
		forgotPassword: {
			text: {
				style: null,
				value: 'Forgot Your Password?',
			},
			props: {
				style: null,
				className: null,
			},
			linkTo: '/password-reset/request',
			loader: {
				isLoading: false,
				size: 15,
				styles: null,
			},
		},
	})

	const AttemptSignIn = async () => {
		console.log('submitting')
		let payload = {}

		// Start Loader
		let newPageButtons = PageButtons
		newPageButtons.signIn.loader.isLoading = true
		await setPageButtons({ ...newPageButtons })

		for (let formField in FormData) {
			let fieldName = formField
			let fieldData = FormData[formField]
			if (fieldData.props.required) {
				if (
					!fieldData.value ||
					fieldData.value == ' ' ||
					fieldData.value == 0
				) {
					// Toast Error Message
					toast.error(`${fieldData.label.text} field is required!`)
					// Stop Loader
					newPageButtons.signIn.loader.isLoading = false
					return setPageButtons({ ...newPageButtons })
				}
			}
			payload[fieldName] = fieldData.value
		}

		const responseObject = await PostMan('login/', 'post', payload)

		// Stop Loader
		newPageButtons.signIn.loader.isLoading = false
		await setPageButtons({ ...newPageButtons })

		if (responseObject.status === 'success') {
			let authResponseData = responseObject.data
			console.log('authResponseData: ', authResponseData)
			await reduxLogin(authResponseData)
			return (window.location = '/')
		} else if (responseObject.status === 'bad_request') {
			let responseData = responseObject.data
			for (let key in responseData) {
				if (key === 'message') {
					toast.error(responseData[key])
				} else {
					let fieldErrors = responseData[key]
					fieldErrors.map((errorMessage) => {
						// Toast Error Message
						toast.error(errorMessage)
					})
				}
			}
		} else if (responseObject.status === 'error') {
			// Toast Error Message
			toast.error(responseObject.data.message)
		}
	}

	useEffect(() => {
		if (auth.loggedIn) {
			setRedirect('/')
		}
	}, [])

	if (redirect) {
		return <Redirect to={redirect} />
	}

	return (
		<div
			className='authentication-bg'
			style={{
				height: '100vh',
				backgroundImage: `url(${require('../../assets/images/bg.png')})`,
			}}
		>
			<div className='account-pages'>
				<div className='container' style={{ padding: '200px 0 0' }}>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-5'>
							<div className='card bg-pattern'>
								<div className='card-body p-4'>
									<div className='text-center w-75 m-auto'>
										<a href='https://234parts.com'>
											<span>
												<img src={Logo} alt='' height='70' />
											</span>
										</a>
										<p className='text-muted mb-4 mt-3'>
											Enter your email address or phone number and password to
											access admin panel.
										</p>
									</div>

									<form method='POST'>
										<FormField
											formData={FormData}
											change={(newFormData) => setFormData({ ...newFormData })}
											field={{
												id: 'username',
												config: FormData.username,
											}}
										/>

										<FormField
											formData={FormData}
											change={(newFormData) => setFormData({ ...newFormData })}
											field={{
												id: 'password',
												config: FormData.password,
											}}
										/>

										<div className='form-group mb-0 text-center'>
											<Button {...PageButtons.signIn} />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer />
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			login,
		},
		dispatch
	)
}

const mapStateToProps = (state) => {
	const { auth } = state
	return {
		auth,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
