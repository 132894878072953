import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { PostMan, SetDateTime } from '../../../../../../../Helpers';
import { Link } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';

import PageActivityIndicator from '../../../../../../../utils/indicator/PageActivityIndicator';


export default function CommunicationHistory(props) {
    const {
        communication
    } = props

    // const [Communication, setCommunication] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    // const [ShowModal, setShowModal] = Communication History

    // const FetchCommunicationHistory = useCallback(
    //     async () => {
    //         setIsLoading(true)
    //         //
    //         const responseObject = await PostMan(
    //             `/notifications/email/communications/${communication.name}/history/`,
    //             // `account/user/all/?account_type=agent`,
    //             'GET'
    //         )
    //         //
    //         setIsLoading(false)
    //         //
    //         if (responseObject.status === 'success') {
    //             let responseData = responseObject.data
    //             console.log('Agents List: ', responseData)
    //             // Update UserList in state.
    //             await setUserList(responseData)
    //         }
    //         else { }
    //     }, []
    // )

    const userTableCheckbox = (id = 0) => {
        return (
            <div class='custom-control custom-checkbox'>
                <input
                    type='checkbox'
                    class='custom-control-input'
                    id={`customCheck${id}`}
                />
                <label
                    class='custom-control-label'
                    for={`customCheck${id}`}
                >
                    &nbsp;
                </label>
            </div>
        )
    }

    const userTableAction = (user) => {
        return (
            <Link to={`/users/agents/${user.id}`} class="action-icon" target="_blank">
                <i class='mdi mdi-square-edit-outline'></i>
            </Link>
        )
    }

    const userTableStatus = (user) => {
        if (user.is_active) {
            return (
                <span class='badge bg-soft-success text-success shadow-none'>
                    Active
                </span>
            )
        } else {
            return (
                <span class='badge bg-soft-danger text-danger shadow-none'>
                    Inactive
                </span>
            )
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: userTableCheckbox(),
                accessor: 'col0',
            },
            {
                Header: 'Gateway',
                accessor: 'col1',
            },
            {
                Header: 'Subject',
                accessor: 'col2',
            },
            {
                Header: 'Recipients',
                accessor: 'col3',
            },
            {
                Header: 'Sent By',
                accessor: 'col4',
            },
            {
                Header: 'Sent At',
                accessor: 'col5',
            },
        ], []
    )

    console.log("communication.history: ", communication.history)

    const data = useMemo(
        () => {
            return communication.history.map((history, _index) => {
                const index = _index + 1
                return ({
                    col0: userTableCheckbox(index),
                    col1: history.gateway && history.gateway.send_as,
                    col2: history.subject,
                    col3: communication.recipients.name,
                    col4: history.sent_by.full_name,
                    col5: history.sent_at,
                })
            })
        }, [communication]
    )

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 50
            },
        }, usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,

    } = tableInstance


    // useEffect(() => {
    //     // Fetch Communication History
    //     FetchCommunicationHistory()
    // }, [])


    return (
        <>
            <PageActivityIndicator open={IsLoading} toggle={() => { }} />

            <div class='row'>
                <div class='col-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row mb-2'>
                                <div className='col-12'>
                                    <h3 className='text-center'>
                                        Communication History
                                    </h3>
                                </div>
                                {/* <div class='col-sm-4'>
                                    <a
                                        href='#'
                                        class='btn btn-md btn-danger'
                                        // onClick={toggleModal}
                                    >
                                        <i class='mdi mdi-plus-circle-outline'></i> Add Agent
                                    </a>
                                </div>
                                <div class='col-sm-8'></div> */}
                            </div>

                            <div class='table-responsive'>
                                <table {...getTableProps()} class='table table-centered table-striped'>
                                    <thead>
                                        {// Loop over the header rows
                                            headerGroups.map(headerGroup => (
                                                // Apply the header row props
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {// Loop over the headers in each row
                                                        headerGroup.headers.map(column => (
                                                            // Apply the header cell props
                                                            <th {...column.getHeaderProps()}>
                                                                {// Render the header
                                                                    column.render('Header')
                                                                }
                                                            </th>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }

                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {// Loop over the table rows
                                            page.map(row => {
                                                // Prepare the row for display
                                                prepareRow(row)
                                                return (
                                                    // Apply the row props
                                                    <tr {...row.getRowProps()}>
                                                        {// Loop over the rows cells
                                                            row.cells.map(cell => {
                                                                // Apply the cell props
                                                                return (
                                                                    <td {...cell.getCellProps()}>
                                                                        {// Render the cell contents
                                                                            cell.render('Cell')}
                                                                    </td>
                                                                )
                                                            })}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className="pagination-wrapper">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item disabled">
                                                <a onClick={() => previousPage()} disabled={!canPreviousPage}
                                                    class="page-link"
                                                    href="javascript: void(0);"
                                                    tabindex="-1"
                                                >Previous</a>
                                            </li>

                                            {
                                                [...Array(pageOptions.length)].map((p, index) => {
                                                    const activePageIndex = pageIndex + 1
                                                    const pageCount = index + 1
                                                    return (
                                                        <li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
                                                            <a class="page-link"
                                                                href="javascript: void(0);"
                                                            >{pageCount}</a>
                                                        </li>
                                                    )
                                                })
                                            }

                                            <li class="page-item">
                                                <a onClick={() => nextPage()} disabled={!canNextPage}
                                                    class="page-link"
                                                    href="javascript: void(0);"
                                                >Next</a>
                                            </li>
                                        </ul>
                                    </nav>

                                    <div style={{ textAlign: 'center' }}>
                                        <span>
                                            Page{' '}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>{' '}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
