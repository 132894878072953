import React, { useState } from 'react'
import {
    Switch,
    Route,
    useRouteMatch 
} from "react-router-dom";

import DiagnosticsRequestList from './components/DiagnosticsRequestList/Index';
import DiagnosticsRequestDetail from './components/DiagnosticsRequestDetail/Index';
import DiagnosticsReportDetail from './components/DiagnosticsReportDetail/Index';


export default function DiagnosticsScreen(props) {
    const {
        dashboard
    } = props

    const match = useRouteMatch()

    const [Dashboard, setDashboard] = useState(null)

    return (
        <Switch>

            {/* <Route exact path={`${match.path}/requests/:requestId`}>
                <DiagnosticsRequestDetail />
            </Route> */}

            <Route path={`${match.path}/reports/:reportId`}>
                <DiagnosticsReportDetail />
            </Route>

            <Route exact path={`${match.path}/:requestId`}>
                <DiagnosticsRequestDetail />
            </Route>

            <Route exact path={`${match.path}`}>
                <DiagnosticsRequestList />
            </Route>

        </Switch>
    )
}
