import React, { useState, useEffect, useCallback } from 'react';
import { PostMan, NumberWithCommas } from '../../../../Helpers';
import { adServerUrl } from '../../../../App.json';
import { useLocation } from 'react-router-dom';

import BreadCrumb from '../../../../components/BreadCrumb';

import AdsDashboard from './components/AdsDashboard';
import ListingsTable from './components/ListingsTable';

import PageActivityIndicator from '../../../../utils/indicator/PageActivityIndicator';


export default function Index(props) {
	const [SponsoredListings, setSponsoredListings] = useState(null)

	const [IsLoading, setIsLoading] = useState(false)

	const AttemptFetchSponsoredListings = useCallback(
		async () => {
			//
			setIsLoading(true)
			let urlPath = "all/"
			const responseObject = await PostMan(urlPath, 'GET', undefined, true, adServerUrl)
			//
			setIsLoading(false)
			if (responseObject.status === 'success') {
				let sponsoredListings = responseObject.data
				// Update ReferreeList in state.
				await setSponsoredListings(sponsoredListings)
			} else {
			}
		},
		[],
	)

	useEffect(() => {
		// Fetch Referral List
		AttemptFetchSponsoredListings()

	}, [])

	console.log("SponsoredListings: ", SponsoredListings)

	return (
		<>
			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<BreadCrumb title={"Sponsored Listings"} 
				crumbs={[
					{
						title: "Sponsored Listings",
						path: null,
					},
				]}
			/>

			<div class="container-fluid">

				<AdsDashboard />

				<div class='row'>
					<div class='col-12'>
						<div class='card'>
							<div class='card-body mt-3'>
								{
									SponsoredListings
									&&
									<ListingsTable 
										sponsoredListings={SponsoredListings}
										attemptFetchSponsoredListings={() => AttemptFetchSponsoredListings()}
									/>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
