import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConversationListItem from './components/ConversationListItem';
import ActivityIndicator from '../../../../../../utils/indicator/ActivityIndicator';


function ChatUsers(props) {
    const {
        chatRoomList
    } = props

    return (
        <>
            <h6 class="font-13 text-muted text-uppercase mb-2">Conversations</h6>

            <div class="row" style={{ minHeight: "70%", overflowyY: "scroll", overflowX: "hidden", }}>
                <div class="col">

                    {
                        chatRoomList && chatRoomList.length > 0 ? (
                            chatRoomList.sort((a, b) => b.preview.timestamp.localeCompare(a.preview.timestamp)).map(chatRoom => {
                                console.log("chatRoom: ", chatRoom)
                                console.log("chatRoom: ", new Date(chatRoom.preview.timestamp))
                                return <ConversationListItem conversation={chatRoom} />
                            })
                        ) : (
                            <div style={{ padding: "50px 0"}}>
                                <ActivityIndicator />
                            </div>
                        )
                    }

                </div>
            </div>
        </>
    )
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

    export default connect(mapStateToProps, mapDispatchToProps)(ChatUsers)