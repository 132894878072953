import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import UserList from './components/UserList/Index';
import UserDetail from './components/UserDetail/Index';

export default function UsersScreen() {
	const match = useRouteMatch()

	return (
		<div class='container-fluid'>
			<Switch>
				<Route exact path={`${match.path}/:userType/:userId`}>
					<UserDetail />
				</Route>

				<Route exact path={`${match.path}/:userType`}>
					<UserList />
				</Route>

				<Route exact path={`${match.path}`}>
					<h1>Users Screen</h1>
				</Route>
			</Switch>
		</div>
	)
}
