import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    apiUrl
} from '../../../../../../../../../App.json';
import {
    PostMan,
    SetDateTime
} from '../../../../../../../../../Helpers';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../../../../../../../utils/Button';
import FormField from '../../../../../../../../../utils/FormField';
import ModalComponent from '../../../../../../../../../utils/modal/Modal';


export default function AgentList(props) {
    const {
        diagnosticsReport,
        config
    } = props

    const {
        title,
        isOpen,
        toggle
    } = config

    const [formData, setFormData] = useState({
        recommendation: {
            element: 'sun-editor',
            value: '',
            showLabel: true,
            label: {
                text: 'Recommendations',
                props: null,
            },
            props: {
                name: 'recommendation_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter your recommendations',
                required: true,
                style: {
                    height: "400px"
                }
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        
    })

    const [PageButtons, setPageButtons] = useState({
        attemptSubmitReport: {
            text: {
                style: null,
                value: 'Upload',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-success btn-block',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
        cancelCreateUser: {
            text: {
                style: null,
                value: 'Cancel',
            },
            props: {
                type: 'button',
                style: null,
                className: 'btn btn-danger btn-block',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'black',
                },
            },
        },
    })


    const AttemptSubmitReport = async () => {
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.attemptSubmitReport.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })

        let payload = {
            status: "closed",
        }
        for (let formField in formData) {
            let fieldName = formField
            let fieldData = formData[formField]
            if (fieldData.props.required) {
                if (
                    !fieldData.value ||
                    fieldData.value == ' ' ||
                    fieldData.value == 0
                ) {
                    // Toast Error Message
                    toast.error(`${fieldData.label.text} field is required!`)
                    // Stop Loader
                    newPageButtons.attemptSubmitReport.loader.isLoading = false
                    return setPageButtons({ ...newPageButtons })
                }
            }
            payload[fieldName] = fieldData.value
        }

        // console.log("payload: ", payload)

        const responseObject = await PostMan(`diagnostics/reports/${diagnosticsReport.id}/`, 'PATCH', payload)

        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("responseData: ", responseData)

            // // Generate Report
            // await AttemptGenerateReport()
            
            //
            window.location.reload()
        } else {
        }
    }

    const AttemptGenerateReport = async () => {
        // Generated Report HTML
        const generatedReportLink = apiUrl + `diagnostics/reports/${diagnosticsReport.id}/generate/`
        // Open in new tab
        window.open(`https://pdf.234parts.com/screenshot?url=${generatedReportLink}`, '_blank');
    }

    return (
        <>
            <ModalComponent open={isOpen} toggle={toggle} title={title}>

                <div style={{ padding: '20px' }}>
                    <form action="" method='POST'>
                        <FormField
                            formData={formData}
                            change={(newFormData) => setFormData({ ...newFormData })}
                            field={{
                                id: 'recommendation',
                                config: formData.recommendation,
                            }}
                        />

                        <div className='form-group mb-0 text-center'>
                            <Button
                                {...PageButtons.attemptSubmitReport}
                                {...{
                                    onClick: async() => {
                                        // Submit Final Report
                                        await AttemptSubmitReport()
                                        
                                    }
                                }}
                            />
                            <Button
                                {...PageButtons.cancelCreateUser}
                                {...{
                                    onClick: () => toggle()
                                }}
                            />
                        </div>
                    </form>
                </div>

            </ModalComponent>

            <ToastContainer />
        </>
    )
}
