import React, {
	useMemo,
	useState,
	useEffect, 
	useCallback 
} from 'react';
import { Link } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';

import {
	PostMan,
	SetDateTime
} from '../../../../../../../Helpers';

import CreateUserModal from './CreateUser';

import PageActivityIndicator from '../../../../../../../utils/indicator/PageActivityIndicator';


export default function AgentList() {
	const [UserList, setUserList] = useState([])
	const [IsLoading, setIsLoading] = useState(false)
	const [ShowModal, setShowModal] = useState(false)

	const FetchAgents = useCallback(
		async () => {
			setIsLoading(true)
			//
			const responseObject = await PostMan(
				`account/user/all/?account_type=agent`,
				'GET'
			)
			//
			setIsLoading(false)
			//
			if (responseObject.status === 'success') {
				let responseData = responseObject.data
				console.log('Agents List: ', responseData)
				// Update UserList in state.
				await setUserList(responseData)
			}
			else { }
		}, []
	)

	const userTableCheckbox = (id = 0) => {
		return (
			<div class='custom-control custom-checkbox'>
				<input
					type='checkbox'
					class='custom-control-input'
					id={`customCheck${id}`}
				/>
				<label
					class='custom-control-label'
					for={`customCheck${id}`}
				>
					&nbsp;
				</label>
			</div>
		)
	}

	const userTableAction = (user) => {
		return (
			<Link to={`/users/agents/${user.id}`} class="action-icon" target="_blank">
				<i class='mdi mdi-square-edit-outline'></i>
			</Link>
		)
	}

	const userTableStatus = (user) => {
		if (user.is_active) {
			return (
				<span class='badge bg-soft-success text-success shadow-none'>
					Active
				</span>
			)
		} else {
			return (
				<span class='badge bg-soft-danger text-danger shadow-none'>
					Inactive
				</span>
			)
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: userTableCheckbox(),
				accessor: 'col0',
			},
			{
				Header: 'Full Name',
				accessor: 'col1',
			},
			{
				Header: 'Phone',
				accessor: 'col2',
			},
			{
				Header: 'Email',
				accessor: 'col3',
			},
			{
				Header: 'Stores',
				accessor: 'col4',
			},
			{
				Header: 'Create Date',
				accessor: 'col5',
			},
			{
				Header: 'Status',
				accessor: 'col6',
			},
			{
				Header: 'Action',
				accessor: 'col7',
			},
		], []
	)

	const data = useMemo(
		() => {
			return UserList.map((user, _index) => {
				const index = _index + 1
				return ({
					col0: userTableCheckbox(index),
					col1: user.full_name,
					col2: user.mobile,
					col3: user.email ? user.email : '---',
					col4: user.managed_stores ? user.managed_stores.count : '---',
					col5: SetDateTime(user.created_at),
					col6: userTableStatus(user),
					col7: userTableAction(user)
				})
			})
		}, [UserList]
	)

	const tableInstance = useTable(
		{
			columns, 
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 50
			},
		}, usePagination
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		pageOptions,
	    page,
		state: { pageIndex, pageSize },
	    gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,

	} = tableInstance


	const toggleModal = () => {
		setShowModal(!ShowModal)
	}

	useEffect(() => {
		// Fetch Agent List
		FetchAgents()
	}, [])

	return (
		<>
			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<div class='row'>
				<div class='col-12'>
					<div class='card'>
						<div class='card-body'>
							<div class='row mb-2'>
								<div class='col-sm-4'>
									<a
										href='#'
										class='btn btn-md btn-danger'
										onClick={toggleModal}
									>
										<i class='mdi mdi-plus-circle-outline'></i> Add Agent
									</a>
								</div>
								<div class='col-sm-8'></div>
							</div>

							<div class='table-responsive'>
								<table {...getTableProps()} class='table table-centered table-striped'>
									<thead>
										{// Loop over the header rows
											headerGroups.map(headerGroup => (
												// Apply the header row props
												<tr {...headerGroup.getHeaderGroupProps()}>
													{// Loop over the headers in each row
														headerGroup.headers.map(column => (
															// Apply the header cell props
															<th {...column.getHeaderProps()}>
																{// Render the header
																	column.render('Header')
																}
															</th>
														))
													}
												</tr>
											))
										}

									</thead>
									<tbody {...getTableBodyProps()}>
										{// Loop over the table rows
											page.map(row => {
												// Prepare the row for display
												prepareRow(row)
												return (
													// Apply the row props
													<tr {...row.getRowProps()}>
														{// Loop over the rows cells
															row.cells.map(cell => {
																// Apply the cell props
																return (
																	<td {...cell.getCellProps()}>
																		{// Render the cell contents
																			cell.render('Cell')}
																	</td>
																)
															})}
													</tr>
												)
											})
										}
									</tbody>
								</table>

								<div className="pagination-wrapper">
									<nav aria-label="Page navigation example">
										<ul class="pagination justify-content-center">
											<li class="page-item disabled">
												<a onClick={() => previousPage()} disabled={!canPreviousPage}
													class="page-link"
													href="javascript: void(0);"
													tabindex="-1"
												>Previous</a>
											</li>

											{
												[...Array(pageOptions.length)].map((p, index) => {
													const activePageIndex = pageIndex + 1
													const pageCount = index + 1
													return (
														<li onClick={() => gotoPage(index)} class={`page-item ${activePageIndex === pageCount && 'active'}`}>
															<a class="page-link"
																href="javascript: void(0);"
															>{pageCount}</a>
														</li>
													)
												})
											}

											<li class="page-item">
												<a onClick={() => nextPage()} disabled={!canNextPage}
													class="page-link"
													href="javascript: void(0);"
												>Next</a>
											</li>
										</ul>
									</nav>

									<div style={{ textAlign: 'center' }}>
										<span>
											Page{' '}
											<strong>
												{pageIndex + 1} of {pageOptions.length}
											</strong>{' '}
										</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			
			
			<CreateUserModal
				userType={"agent"}
				config={{
					title: 'Add an Agent',
					isOpen: ShowModal,
					toggle: toggleModal,
				}}
			/>
		</>
	)
}
