import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ReferrerList from './components/ReferrerList/Index';
import ReferreeList from './components/ReferreeList/Index';
import ReferralDetail from './components/ReferralDetail/Index';


export default function UsersScreen() {
    const match = useRouteMatch()

    return (
        <div class='container-fluid'>
            <Switch>
                <Route exact path={`${match.path}/referrees`}>
                    <ReferreeList />
                </Route>

                <Route exact path={`${match.path}/vendor-referrers`}>
                    <ReferrerList listType="vendor" />
                </Route>

                <Route exact path={`${match.path}/:userId/vendor`}>
                    <ReferralDetail listType="vendor" />
                </Route>
                
                <Route exact path={`${match.path}/customer-referrers`}>
                    <ReferrerList listType="customer" />
                </Route>

                <Route exact path={`${match.path}/:userId/customer`}>
                    <ReferralDetail listType="customer" />
                </Route>

                {/* <Route exact path={`${match.path}`}>
                    <ReferralList />
                </Route> */}
            </Switch>
        </div>
    )
}
