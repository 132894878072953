import React, { useEffect, useState } from 'react'

import {
    withRouter,
    useRouteMatch,
    useLocation,
    Link,
    Redirect
} from 'react-router-dom'
import {
    logout
} from '../redux/actions/AuthActions'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';



function Middleware(props) {
    const location = useLocation()
    const [redirect, setRedirect] = useState(null)


    const {
        auth
    } = props

    console.log("auth: ", auth)

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            {props.children}
        </>
    )
}


const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        logout
    }, dispatch)
}

const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((Middleware)))
