import React, { useState, useEffect } from 'react'
import {
    Link,
    useRouteMatch
} from 'react-router-dom'

import IsPhone from "../../utils/breakpoints/IsPhone"
import Breakpoint from "../../utils/breakpoints/Base"

export default function Drawer(props) {
    const {
        showMenu,
        children
    } = props
    const match = useRouteMatch()
    
    const [CurrentPath, setCurrentPath] = useState(null)
    // const [CurrentPath, setCurrentPath] = useState("/")
    const [OpenNav, setOpenNav] = useState(null)

    const [NavigationNav, setNavigationNav] = useState([
        {
            name: "Dashboard", 
            icon: "fe-menu",
            path: "/",
            // className: "active",
            className: "",
            children: null
        },
        {
            name: "Users",
            icon: "fe-users",
            path: "/users",
            className: "",
            // className: "collapse in",
            children: [
                {
                    name: "Agents",
                    path: "/users/agents",
                    className: ""
                },
                {
                    name: "Vendors",
                    path: "/users/vendors",
                    className: ""
                },
                {
                    name: "Customers",
                    path: "/users/customers",
                    className: ""
                },
                {
                    name: "Technicians",
                    path: "/users/technicians",
                    className: ""
                },
            ],
            expanded: false
        },
        {
            name: "Autoshop",
            icon: "fe-pocket",
            path: "/autoshop",
            className: "",
            children: [
                {
                    name: "Stores",
                    path: "/autoshop/stores/",
                    className: ""
                },
                {
                    name: "Listings",
                    path: "/autoshop/listings/",
                    className: ""
                },
            ],
            expanded: false
        },
        {
            name: "Products",
            icon: "fe-pocket",
            path: "/products",
            className: "",
            children: [
                {
                    name: "All Products",
                    path: "/products",
                    className: ""
                },
                {
                    name: "Unique Products",
                    path: "/products/unique-products",
                    className: ""
                },
            ]
        },
        {
            name: "Sponsored Listings",
            icon: "fe-airplay",
            path: "/sponsored-listings",
            // className: "active",
            className: "",
            children: null
        },
    ])

    const [ApplicationNav, setApplicationNav] = useState([
        {
            name: "Referrals",
            icon: "fe-pocket",
            path: "/referrals",
            className: "",
            children: [
                {
                    name: "Customer Referrers",
                    path: "/referrals/customer-referrers/",
                    className: ""
                },
                {
                    name: "Vendor Referrers",
                    path: "/referrals/vendor-referrers/",
                    className: ""
                },
                // {
                //     name: "Referrees",
                //     path: "/referrals/referrees/",
                //     className: ""
                // },
            ],
            expanded: false
        },
        // {
        //     name: "Service Kits",
        //     icon: "fe-pocket",
        //     path: "",
        //     className: "",
        //     children: [
        //         {
        //             name: "All Kits",
        //             path: "/service-kits",
        //             className: ""
        //         },
        //     ]
        // },
        {
            name: "Diagnostics",
            icon: "fe-pocket",
            path: "/diagnostics",
            className: "",
            children: [
                {
                    name: "Requests",
                    path: "/diagnostics/",
                    className: ""
                },
            ],
            expanded: false,
        }
    ])


    const [CommunicationsNav, setCommunicationsNav] = useState([
        {
            name: "Email Marketing",
            icon: "fe-pocket",
            path: "/email-marketing",
            className: "",
            children: [
                {
                    name: "Communications",
                    path: "/email-marketing/",
                    className: ""
                },
                // {
                //     name: "Mailing Lists",
                //     path: "/email-marketing/mailing-list/",
                //     className: ""
                // },

            ],
            expanded: false,
        },
        {
            name: "Push Notification",
            icon: "fe-pocket",
            path: "/push-notification",
            className: "",
            children: [
                {
                    name: "Communications",
                    path: "/push-notification/",
                    className: ""
                },
            ],
            expanded: false,
        },
        {
            name: "Messenger",
            icon: "fe-message-square",
            path: "/messenger",
            className: "",
            children: null
        },
    ])

    const ToggleNavigation = (nav) => {
        if (nav.children.length > 0) {
            if (OpenNav && nav.path == OpenNav) {
                setOpenNav(null)
            } else {
                setOpenNav(nav.path)
                // setCurrentPath(nav.path)
            }
        } else {
            setCurrentPath(nav.path)
        }
    }

    const RenderNavs = (navigations) => {
        let template = []
        let newNavigations = navigations
        navigations.map(nav => {
            // Get new navigation item
            newNavigations.map(newNavItem => {
                if (newNavItem.name == nav.name) {
                    // Set Path
                    let className = ""                    

                    if (CurrentPath && nav.path == `/${CurrentPath.split('/', 2)[1]}` || OpenNav && nav.path == `/${OpenNav.split('/', 2)[1]}`) {
                        if (CurrentPath && nav.path == CurrentPath) {
                            className += "active"
                        }
                        if (OpenNav && nav.path == OpenNav) {
                            newNavItem.expanded = true
                            className += " collapse in"
                        }
                        //
                        newNavItem.className = className
                    }

                    // if (CurrentPath && nav.path == `/${CurrentPath.split('/', 2)[1]}`) {
                    //     className += "active"
                    // }
                    // else if (OpenNav && nav.path == OpenNav) {
                    //     newNavItem.expanded = true
                    //     className += " collapse in"
                    // } 
                        
                    // }

                    // if (nav.path == CurrentPath || nav.path == OpenNav) {
                    //     if (CurrentPath && nav.path == CurrentPath) {
                    //         className += "active"
                    //     }
                    //     if (OpenNav && nav.path == OpenNav) {
                    //         newNavItem.expanded = true
                    //         className += " collapse in"
                    //     }
                    //     //
                    //     newNavItem.className = className
                    // }
                    else {
                        if (nav.children) {
                            newNavItem.className = "collapse"
                            newNavItem.expanded = false
                            // Set Active Child
                        } else {
                            //
                            newNavItem.className = className
                        }
                    }
                }
            })

            if (nav.children) {
                template.push(
                    <li style={{ cursor: 'pointer' }}>
                        <a  onClick={()=> ToggleNavigation(nav)}
                            className={nav.className}
                        >
                            <i class={nav.icon}></i>
                            <span>
                                {nav.name}
                            </span>
                            <span class="menu-arrow"></span>
                        </a>
                        
                        <ul class={`nav-second-level ${nav.className}`} aria-expanded={nav.expanded}>
                            {
                                nav.children.map(navChild => {
                                    return (
                                        <li class={navChild.className}>
                                            <Link to={navChild.path}>
                                                {navChild.name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li>
                )
            } else {
                template.push(
                    <li style={{ cursor: 'pointer' }}>
                        <Link to={nav.path} className={nav.className}>
                            <i class={nav.icon}></i>
                            <span>
                                {nav.name}
                            </span>
                        </Link>
                    </li>
                )
            }
        })

        return template
    }

    if (CurrentPath != `/${match.path.split('/', 2)[1]}`) {
        setCurrentPath(`/${match.path.split('/', 2)[1]}`)
    }

    const SideMenu = () => {
        return (
            <div class="slimscroll-menu">
                <div id="sidebar-menu">
                    <ul class="metismenu" id="side-menu">

                        <li class="menu-title">Navigation</li>

                        {
                            RenderNavs(NavigationNav, setNavigationNav)
                        }

                        <li class="menu-title">Applications</li>

                        {
                            RenderNavs(ApplicationNav, setApplicationNav)
                        }

                        <li class="menu-title">Communications</li>

                        {
                            RenderNavs(CommunicationsNav, setCommunicationsNav)
                        }

                    </ul>

                </div>

                <div class="clearfix"></div>

            </div>
        )
    }

    
    return (
        <>
            <IsPhone>
                {
                    showMenu ? (
                        <div
                            style={{
                                zIndex: 999,
                                display: "block",
                                width: "240px",
                                background: "#3f3f3f",
                                bottom: 0,
                                padding: "20px 0",
                                position: "fixed",
                                "-webkit-transition": "all .2s ease-out",
                                transition: "all .2s ease-out",
                                top: "70px",
                                "-webkit-box-shadow": "0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%)",
                                boxShadow: "0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%)",
                            }}
                        >
                            {
                                SideMenu()
                            }
                        </div>
                    ) : null
                }
            </IsPhone>

            <Breakpoint name={"notPhone"}>
                <div className="left-side-menu">
                    {
                        SideMenu()
                    }
                </div>
            </Breakpoint>

            <div class="content-page">
                <div class="content">
                    <div class="container-fluid">
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
