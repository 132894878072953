import React, { useState, useEffect } from 'react';
import {
    PostMan
} from '../../../../../../Helpers';
import {
    Switch,
    Route,
    useRouteMatch 
} from "react-router-dom";

import VehicleFaultList from './components/VehicleFaultList';
import VehicleFaultDetail from './components/VehicleFaultDetail/Index';
import VehicleInformation from './components/VehicleInformation';
import BreadCrumb from '../../../../../../components/BreadCrumb';

import UserProfileCard from '../DiagnosticsUserProfileCard';

export default function DiagnosticsReportDetailScreen(props) {
    const match = useRouteMatch()
    const reportId = match.params.reportId

    const [DiagnosticsReport, setDiagnosticsReport] = useState(null)

    
    const FetchDiagnosticsReport = async () => {
        const responseObject = await PostMan(`diagnostics/reports/${reportId}`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("Report Detail: ", responseData)
            // Update DiagnosticsReport in state.
            await setDiagnosticsReport(responseData)
        }
        else { }
    }

    useEffect(() => {
        // Fetch Client Detail
        FetchDiagnosticsReport()
    }, [])

    console.log("match.path: ", match.path)

    return (
        <div class="container-fluid">
            <BreadCrumb 
                title={
                    DiagnosticsReport
                    &&
                    `${DiagnosticsReport.version.display}: ${DiagnosticsReport.diagnostics_request.vehicle.short_name_display}`
                    ||
                    `Diagnostics Vehicle Report`
                }
                crumbs={[
                    {
                        title: "All Diagnostics Reports",
                        path: `/diagnostics/${DiagnosticsReport && DiagnosticsReport.diagnostics_request.id}`
                    },
                    {
                        title: DiagnosticsReport && DiagnosticsReport.diagnostics_request.vehicle.short_name_display
                    }
                ]}
            />

            <div class="row">
                <div class="col-xl-8 order-12 order-xl-1">
                    <Switch>
                        <Route path={`${match.path}/fault/:faultId`}>
                            <VehicleFaultDetail />
                        </Route>

                        <Route exact path={`${match.path}`}>
                            {/* <VehicleFaultDetail /> */}

                            <VehicleFaultList 
                                diagnosticsReport={DiagnosticsReport}
                                vehicleFaults={DiagnosticsReport && DiagnosticsReport.faults}
                            />
                            
                        </Route>

                    </Switch>
                </div>

                <div class="col-xl-4 order-1 order-xl-12">
                    
                    <VehicleInformation 
                        reportId={reportId} 
                        diagnosticsReport={DiagnosticsReport} 
                    />

                    <UserProfileCard 
                        diagnosticsUser={DiagnosticsReport && DiagnosticsReport.diagnostics_request} 
                    />

                </div>
            </div>
        </div>
    )
}
