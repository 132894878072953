import React, { useState, useEffect } from 'react'
import {
    Link,
    useRouteMatch
} from 'react-router-dom'

import IsPhone from "../../utils/breakpoints/IsPhone"
import Breakpoint from "../../utils/breakpoints/Base"

export default function Drawer(props) {
    const {
        showMenu,
        children
    } = props
    const match = useRouteMatch()

    // console.log("match: ", match)
    console.log("match.path: ", match.path)

    const [CurrentPath, setCurrentPath] = useState("/")

    const [NavigationNav, setNavigationNav] = useState([
        {
            name: "Dashboard",
            icon: "fe-airplay",
            path: "/",
            className: "active",
            children: null
        },
        {
            name: "Reports",
            icon: "fe-users",
            path: "/reports",
            className: "",
            children: null
        },
        // {
        //     name: "clients",
        //     icon: "fe-users",
        //     path: "/clients",
        //     className: "collapse in",
        //     children: [
        //         {
        //             name: "Add Client",
        //             path: "/clients/add",
        //             className: ""
        //         },
        //         {
        //             name: "All Clients",
        //             path: "/clients",
        //             className: ""
        //         },
        //     ]
        // },
        // {
        //     name: "Reports",
        //     icon: "fe-pocket",
        //     path: "",
        //     className: "",
        //     children: [
        //         {
        //             name: "Open Reports",
        //             path: "/reports/open/",
        //             className: ""
        //         },
        //         {
        //             name: "All Reports",
        //             path: "/reports",
        //             className: ""
        //         },
        //     ]
        // },
    ])

    const [ApplicationNav, setApplicationNav] = useState([
        // {
        //     name: "Upload Report",
        //     icon: "fe-pocket",
        //     path: "/upload-reports",
        //     className: ""
        // },
    ])

    const RenderNavs = (navigations) => {
        let template = []
        let newNavigations = navigations
        navigations.map(nav => {
            // Get new navigation item
            newNavigations.map(newNavItem => {
                if (newNavItem.name == nav.name) {
                    // Set Path
                    if (nav.path == CurrentPath) {
                        if (nav.children) {
                            newNavItem.className = "active collapse in"
                            newNavItem.expanded = true
                        } else {
                            newNavItem.className = "active"
                        }
                    } else {
                        if (nav.children) {
                            newNavItem.className = "collapse"
                            newNavItem.expanded = false
                            // Set Active Child

                        } else {
                            newNavItem.className = ""
                        }
                    }
                }
            })

            if (nav.children) {
                template.push(
                    <li>
                        <Link to={nav.children[0].path} className={nav.className}>
                            <i class={nav.icon}></i>
                            <span>
                                {nav.name}
                            </span>
                            <span class="menu-arrow"></span>
                        </Link>

                        {/* <a href="javascript: void(0);" className={nav.className}>
                            <i class={nav.icon}></i>
                            <span>
                                {nav.name}
                            </span>
                            <span class="menu-arrow"></span>
                        </a> */}

                        <ul class={`nav-second-level ${nav.className}`} aria-expanded={nav.expanded}>
                            {
                                nav.children.map(navChild => {
                                    return (
                                        <li class={navChild.className}>
                                            <Link to={navChild.path}>
                                                {navChild.name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li>
                )
            } else {
                template.push(
                    <li>
                        <Link to={nav.path} className={nav.className}>
                            <i class={nav.icon}></i>
                            <span>
                                {nav.name}
                            </span>
                        </Link>
                    </li>
                )
            }
        })

        return template
    }

    if (CurrentPath != match.path) {
        setCurrentPath(match.path)
    }

    const SideMenu = () => {
        return (
            <div class="slimscroll-menu">
                <div id="sidebar-menu">
                    <ul class="metismenu" id="side-menu">
                        
                        {
                            NavigationNav.length > 0 ? (
                                <>
                                    <li class="menu-title">Applications</li>
                                    {RenderNavs(NavigationNav, setNavigationNav)}
                                </>
                            ) : null
                        }


                        {
                            ApplicationNav.length > 0 ? (
                                <>
                                    <li class="menu-title">Applications</li>
                                    {RenderNavs(ApplicationNav, setApplicationNav)}
                                </>
                            ) : null
                        }

                    </ul>

                </div>

                <div class="clearfix"></div>

            </div>
        )
    }
    
    return (
        <>
            <IsPhone>
                {
                    showMenu ? (
                        <div
                            style={{
                                zIndex: 999,
                                display: "block",
                                width: "240px",
                                background: "#3f3f3f",
                                bottom: 0,
                                padding: "20px 0",
                                position: "fixed",
                                "-webkit-transition": "all .2s ease-out",
                                transition: "all .2s ease-out",
                                top: "70px",
                                "-webkit-box-shadow": "0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%)",
                                boxShadow: "0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%)",
                            }}
                        >
                            {
                                SideMenu()
                            }
                        </div>
                    ) : null
                }
            </IsPhone>

            <Breakpoint name={"notPhone"}>
                <div className="left-side-menu">
                    {
                        SideMenu()
                    }
                </div>
            </Breakpoint>

            <div class="content-page">
                <div class="content">
                    <div class="container-fluid">
                        {
                            children
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
