import React from 'react'

export default function UserReferrals() {
    return (
        <>
            <h5 class="mb-3 mt-4 text-uppercase"><i class="mdi mdi-cards-variant mr-1"></i>
                Referrals
            </h5>

            <div class="table-responsive">
                <table class="table table-borderless mb-0">
                    <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th>Project Name</th>
                            <th>Start Date</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Clients</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>App design and development</td>
                            <td>01/01/2015</td>
                            <td>10/15/2018</td>
                            <td><span class="badge badge-info">Work in Progress</span></td>
                            <td>Halette Boivin</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Coffee detail page - Main Page</td>
                            <td>21/07/2016</td>
                            <td>12/05/2018</td>
                            <td><span class="badge badge-success">Pending</span></td>
                            <td>Durandana Jolicoeur</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Poster illustation design</td>
                            <td>18/03/2018</td>
                            <td>28/09/2018</td>
                            <td><span class="badge badge-pink">Done</span></td>
                            <td>Lucas Sabourin</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Drinking bottle graphics</td>
                            <td>02/10/2017</td>
                            <td>07/05/2018</td>
                            <td><span class="badge badge-blue">Work in Progress</span></td>
                            <td>Donatien Brunelle</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Landing page design - Home</td>
                            <td>17/01/2017</td>
                            <td>25/05/2021</td>
                            <td><span class="badge badge-warning">Coming soon</span></td>
                            <td>Karel Auberjo</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}
