import React, { useEffect, useState, useCallback } from 'react';
import { PostMan, Slugify } from '../../../../../Helpers';
import { Redirect } from 'react-router-dom';
import Button from '../../../../../utils/Button';
import FormField from '../../../../../utils/FormField';
import BreadCrumb from '../../../../../components/BreadCrumb';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CommunicationCreate() {
    const [redirect, setRedirect] = useState(null)
    const [ImportFromHTML, setImportFromHTML] = useState(false)

    const [formData, setFormData] = useState({
        name: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Name',
                props: null,
            },
            props: {
                name: 'name_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication name',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        recipients: {
            element: 'select',
            data: [],
            value: '',
            showLabel: true,
            label: {
                text: 'Recipients',
                props: null,
            },
            props: {
                name: 'subject_input',
                type: 'text',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        subject: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Subject',
                props: null,
            },
            props: {
                name: 'subject_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication subject',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        content: {
            element: 'textarea',
            value: '',
            showLabel: true,
            label: {
                text: 'Content',
                props: null,
            },
            props: {
                name: 'content_input',
                type: 'text',
                className: 'form-control',
                required: true,
                style: {
                    height: "550px"
                }
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
        action: {
            element: 'input',
            value: '',
            showLabel: true,
            label: {
                text: 'Action [Optional]',
                props: null,
            },
            props: {
                name: 'action_input',
                type: 'text',
                className: 'form-control',
                placeholder: 'Enter communication deep link',
                required: false,
            },
            wrapperProps: {
                className: 'form-group mb-3',
            },
        },
    })
    
    const [PageButtons, setPageButtons] = useState({
        saveToDraft: {
            text: {
                style: null,
                value: "Save To Draft",
            },
            props: {
                type: "button",
                style: {
                    margin: '0 10px 0'
                },
                className: 'btn btn-danger',
            },
            onClick: () => {},
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
    })

    const AttemptSaveToDraft = useCallback(
        async () => {
            // Start Loader
            let newPageButtons = PageButtons
            newPageButtons.saveToDraft.loader.isLoading = true
            await setPageButtons({ ...newPageButtons })
            //
            let payload = {
                // account_type: userType,
            }
            for (let formField in formData) {
                let fieldName = formField
                let fieldData = formData[formField]
                if (fieldData.props.required) {
                    if (
                        !fieldData.value ||
                        fieldData.value == ' ' ||
                        fieldData.value == 0
                    ) {
                        // Toast Error Message
                        toast.error(`${fieldData.label.text} field is required!`)
                        // Stop Loader
                        newPageButtons.saveToDraft.loader.isLoading = false
                        return setPageButtons({ ...newPageButtons })
                    }
                }
                //
                payload[fieldName] = fieldData.value
            }
            //
            const responseObject = await PostMan(`notifications/push/communications/`, 'POST', payload)
            if (responseObject.status === 'success') {
                let responseData = responseObject.data
                setRedirect(`/push-notification/${responseData.name}/`)
            } else {
            }
        }
    )

    const FetchCommunicationList = useCallback(
        async () => {
            const responseObject = await PostMan(
                `notifications/communication-list`,
                'GET'
            )
            if (responseObject.status === 'success') {
                let emailLists = responseObject.data
                let newFormData = formData
                emailLists.map(mailList => {
                    newFormData.recipients.data.push({
                        value: mailList.id,
                        display: mailList.name,
                    })
                })
                // Update FormData in state.
                await setFormData({ ...newFormData })
            }
            else { }
        }, []
    )

    useEffect(() => {
        // Fetch Email List
        FetchCommunicationList()
    
    }, [])

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <div class="container-fluid">

            <ToastContainer />

            <BreadCrumb
                title="Create Push Communication"
                crumbs={[
                    {
                        title: "Push Notifications",
                        path: "/push-notification/"
                    },
                    {
                        title: "Create Push Notification"
                    }
                ]}
            />

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="header-title">Enter below notification details</h4>
                            
                            {/* <p class="sub-header">
                                Most common form control, text-based input fields. Includes support for all HTML5 types: <code>text</code>, <code>password</code>, <code>datetime</code>, <code>datetime-local</code>, <code>date</code>, <code>month</code>, <code>time</code>, <code>week</code>, <code>number</code>, <code>email</code>, <code>url</code>, <code>search</code>, <code>tel</code>, and <code>color</code>.
                            </p> */}

                            <div class="row">
                                <div class="col-lg-6">
                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => {
                                            let new_name = newFormData.name.value
                                            newFormData.name.value = Slugify(new_name)
                                            setFormData({ ...newFormData })}
                                        }
                                        field={{
                                            id: 'name',
                                            config: formData.name,
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'recipients',
                                            config: formData.recipients,
                                        }}
                                    />


                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'subject',
                                            config: formData.subject,
                                        }}
                                    />

                                    <FormField
                                        formData={formData}
                                        change={(newFormData) => setFormData({ ...newFormData })}
                                        field={{
                                            id: 'content',
                                            config: formData.content,
                                        }}
                                    />
                                </div>

                                <div className='col-12'>
                                    <Button {...PageButtons.saveToDraft} {...{ onClick: () => AttemptSaveToDraft() }} />
                                </div>

                            </div>                       

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
