import React, { useState, useEffect } from 'react';
import { PostMan, NumberWithCommas } from '../../../../../../Helpers';
import { useRouteMatch } from 'react-router-dom';

import BreadCrumb from '../../../../../../components/BreadCrumb';

import UserProfileCard from '../../../users/components/UserProfileCard';
import ReferralTable from '../ReferralTable';

export default function ReferralDetail(props) {
    const {
        listType = "customer"
    } = props

    const match = useRouteMatch()

    const [ReferralUser, setReferralUser] = useState(null)
    const [ReferralList, setReferralList] = useState([])

    const userId = match.params.userId

    const FetchReferralDetail = async () => {
        let urlPath
        if (listType == "vendor") {
            urlPath = `referral/user/${userId}/vendor/`
        } else {
            urlPath = `referral/user/${userId}/customer/`
        }
        const responseObject = await PostMan(urlPath, 'GET')
        if (responseObject.status === 'success') {
            let referralDetail = responseObject.data
            // const referralDetail = responseData.
            console.log('Referral detail: ', referralDetail)
            // Update Referral detail in state.
            await setReferralUser(referralDetail.user)
            await setReferralList(referralDetail.referrals)
        } else {
        
        }
    }

    useEffect(() => {
        // Fetch Referral List
        FetchReferralDetail()
        
    }, [])

    return (
        <>
            <BreadCrumb title={"Referral User Detail"}

                crumbs={[
                    {
                    	title: 'Referrals',
                        path: `/referrals/${listType}-referrers`,
                    },
                    {
                        title: ReferralUser && ReferralUser.full_name,
                        path: null,
                    },
                ]}
            />

            <div class="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-xl-4">
                        <UserProfileCard user={ReferralUser} />
                    </div>

                    <div className="col-lg-8 col-xl-8">


                        <div class='card'>
                            <div class='card-body'>
                                <ReferralTable referralList={ReferralList} listType={listType} />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
