import React from 'react';
import {
    SetDateTime
} from '../../../../../../../Helpers';

import {
    Link,
} from 'react-router-dom';

import ReportPlaceholder from '../../../../../../../assets/images/report.png';


export default function VehicleReportList(props) {
    const {
        vehicleReports
    } = props

    return (

        <>
            {
                vehicleReports.length > 0 ? (
                    <>
                        {
                            vehicleReports.map(report => {
                                return (
                                    <div class="card-box mb-2">
                                        <div class="row align-items-center">
                                            <div class="col-sm-6">
                                                <div class="media">
                                                    <img class="d-flex align-self-center mr-3 rounded-circle" src={ReportPlaceholder} alt="Generic placeholder image" height="64" />
                                                    <div class="media-body">
                                                        <h4 class="mt-0 mb-2 font-16">{report.version.display} {report._id.toUpperCase()}</h4>
                                                        <p class="mb-0"><b>Status:</b> {report.status.display}</p>
                                                        <p class="mb-1"><b>Issues:</b> {report.faults.length}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="text-center my-3 my-sm-0">
                                                    <p class="mb-0 text-muted">{SetDateTime(report.created_at)}</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="text-center button-list">
                                                    <Link to={`/diagnostics/reports/${report.id}`} class="btn btn-xs btn-primary waves-effect waves-light">
                                                        View
                                                    </Link>
                                                    {
                                                        report.file && <a href="javascript: void(0);" class="btn btn-xs btn-primary waves-effect waves-light">Download</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                ) : (
                    <div class="card-box mb-2" style={{ padding: "100px" }}>
                        <div class="row align-items-center justify-content-center">
                            <div>
                                <div class="media" style={{ alignItems: 'center' }}>
                                    <img class="d-flex align-self-center mr-3 rounded-circle" src={ReportPlaceholder} alt="Generic placeholder image" height="64" />
                                    <div>
                                        <h4 class="mt-0 mb-2 font-16">There are no reports for this vehicle</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
