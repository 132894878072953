import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import PushCommunicationCreate from './components/CommunicationCreate';
import PushCommunicationList from './components/CommunicationList';
import PushCommunicationDetail from './components/CommunicationDetail/Index';

export default function Index(props) {
    const {

    } = props
    
    const match = useRouteMatch()

    return (
        <Switch>
            
            <Route path={`${match.path}/create`}>
                <PushCommunicationCreate />    
            </Route>

            <Route path={`${match.path}/:communicationSlug`}>
                <PushCommunicationDetail />
            </Route>
            
            <Route exact path={`${match.path}`}>
                <PushCommunicationList />
            </Route>
            
        </Switch>
    )
}
