import React, { useState, useEffect, useCallback } from 'react';
import { PostMan, NumberWithCommas } from '../../../../../../Helpers';
import { Link, useRouteMatch } from 'react-router-dom';

import BreadCrumb from '../../../../../../components/BreadCrumb';

import ReferralDashboard from '../ReferralDashboard';
import ReferreeTable from '../ReferreeTable';

import PageActivityIndicator from '../../../../../../utils/indicator/PageActivityIndicator';


export default function ReferreeList() {
	const [ReferreeList, setReferreeList] = useState([])

	const [IsLoading, setIsLoading] = useState(false)

	const FetchReferreeList = useCallback(
		async () => {
			//
			setIsLoading(true)
			//
			const responseObject = await PostMan(`referral/referrees/`, 'GET')
			//
			setIsLoading(false)
			//
			if (responseObject.status === 'success') {
				let responseData = responseObject.data
				let referrerList = responseData
				// Update ReferreeList in state.
				await setReferreeList(referrerList)
			} else {
			}
		},
		[],
	)


	useEffect(() => {
		// Fetch Referral List
		FetchReferreeList()
	}, [])

	return (
		<>
			<PageActivityIndicator open={IsLoading} toggle={() => { }} />

			<BreadCrumb title={"Referral Program"} 
			
				crumbs={[
					{
						title: "Referees",
						path: null,
					},
				]}
			/>

			<div class="container-fluid">

				<ReferralDashboard />

				<div class='row'>
					<div class='col-12'>
						<div class='card'>
							<div class='card-body mt-3'>
								{
									ReferreeList
									&&
									<ReferreeTable referreeList={ReferreeList} />
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
