import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ListingDetail from './components/ListingDetail';

export default function StoresScreen() {
	const match = useRouteMatch()

	return (
		<div class='container-fluid'>
			<Switch>
				<Route exact path={`${match.path}/:listingId`}>
					<ListingDetail />
				</Route>

			</Switch>
		</div>
	)
}
