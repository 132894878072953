import React, { useState } from 'react';

export default function DiagnosticsDashboard() {
    const [Dashboard, setDashboard] = useState(null)
    
    return (
        <div class="row">
            <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="fe-users font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.users}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-primary border-primary border">
                                <i class="dripicons-store font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.stores}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Open Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-success border-success border">
                                <i class="fas fa-tools font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.products}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3">
                <div class="widget-rounded-circle card-box">
                    <div class="row">
                        <div class="col-6">
                            <div class="avatar-lg rounded-circle shadow-lg bg-info border-info border">
                                <i class="fe-shopping-cart font-22 avatar-title text-white"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-right">
                                <h3 class="text-dark mt-1"><span data-plugin="counterup">{Dashboard && Dashboard.listings}</span></h3>
                                <p class="text-muted mb-1 text-truncate">Total Vehicles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
