import React from 'react';

import DefaultAvatar from '../../../../../assets/images/avatar.png';

export default function UserProfileCard(props) {
    const {
        diagnosticsUser
    } = props

    return (
        <div className='card-box'>
            {
                diagnosticsUser ? (
                    <>
                        <div className='media mb-3'>
                            <img className="d-flex mr-3 rounded-circle avatar-lg"
                                src={diagnosticsUser.image ? diagnosticsUser.image : DefaultAvatar}
                                alt="Generic placeholder image"
                            />

                            <div className='media-body'>
                                <h4 className='mt-0 mb-1'>{diagnosticsUser.full_name}</h4>
                                <p className='text-muted'>
                                    {/* {diagnosticsUser.account_type.toUpperCase()} */}
                                </p>

                                <a href='#' className='btn- btn-xs btn-info'>
                                    Send Email
                                </a>
                            </div>
                        </div>

                        <h5 className='mb-3 mt-4 text-uppercase bg-light p-2'>
                            <i className='mdi mdi-account-circle mr-1'></i>
                            Personal Information
                        </h5>
                        <div className=''>
                            <h4 className='font-13 text-muted text-uppercase'>Email :</h4>
                            <p className='mb-3'>{diagnosticsUser.email || "N/A"}</p>

                            <h4 className='font-13 text-muted text-uppercase mb-1'>
                                Mobile :
                            </h4>
                            <p className='mb-3'> {diagnosticsUser.mobile} </p>

                            <h4 className='font-13 text-muted text-uppercase mb-1'>
                                Referral Code :
                            </h4>
                            <p className='mb-3'> {diagnosticsUser.referral_code} </p>
                        {/* </div>

                        <div class=""> */}
                            <h4 class="font-13 text-muted text-uppercase mb-1">Address :</h4>
                            <p class="mb-0">{diagnosticsUser.address}</p>
                            <p class="mb-3">{diagnosticsUser.state.short_name}</p>
                        </div>
                    </>
                ) : null
            }
            
        </div>
    )
}
