import React, { Component } from 'react'

import {
    colors
} from '../App.json'

// import Select from 'react-select';

import { DefaultEditor } from 'react-simple-wysiwyg';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


export default class FormField extends Component {
    constructor(props) {
        super(props)
    }

    changeHandler = (event, id) => {
        // console.log("event: ", event)

        let eventValue
        let {
            field,
            change,
            formData,
        } = this.props

        const newFormData = formData;

        if (field.config.props.isMulti) {
            // event: { value: 7, label: "Microsoft Xbox 360" }
            eventValue = event;
        } else if (field.config.props.type === 'file') {
            eventValue = event.target.files[0];
        } else if (field.config.element === 'sun-editor') {
            eventValue = event;
        }
        else {
            eventValue = event.target.value;
        }

        // console.log("eventValue: ", eventValue)
        newFormData[id].value = eventValue;

        // Update parent component
        change(newFormData)
    };

    checkboxOnClick = (field) => {
        let {
            change,
            formData,
        } = this.props
        const newFormData = formData;
        newFormData[field.id].checked = !newFormData[field.id].checked;
        // Update parent component
        change(newFormData)
    };

    handleKeyUp = (event) => {
        const {
            keyUpHandler,
        } = this.props

        if (event.keyCode === 13 && typeof keyUpHandler !== "undefined") {
            // Update parent component
            keyUpHandler()
        }
    }

    showLabel = (show, label) => {
        return show ? (
            <label {...label.props}>
                {label.text}
            </label>
        )
            :
            null
    };

    renderFormField = (field) => {
        let formTemplate
        let fieldConfig = field.config

        switch (fieldConfig.element) {

            case ('input'):
                formTemplate = (

                    <div {...fieldConfig.wrapperProps}>

                        {this.showLabel(fieldConfig.showLabel, fieldConfig.label)}

                        {
                            fieldConfig.props.type === "file" ? (
                                <input
                                    {...fieldConfig.props}
                                    onChange={(event) => this.changeHandler(event, field.id)}
                                />
                            ) : (
                                <input
                                    {...fieldConfig.props}
                                    value={fieldConfig.value}
                                    onChange={(event) => this.changeHandler(event, field.id)}
                                    onKeyUp={(event) => this.handleKeyUp(event)}
                                />
                            )
                        }
                    </div>
                );
                break;

            case ('select'):
                formTemplate = (

                    <div {...fieldConfig.wrapperProps}>

                        {this.showLabel(fieldConfig.showLabel, fieldConfig.label)}

                        <select
                            {...fieldConfig.props}
                            value={fieldConfig.value}
                            onChange={(event) => this.changeHandler(event, field.id)}
                        >
                            {fieldConfig.data.map((option, index) => {
                                return <option key={index} value={option.value}>{option.display}</option>;
                            })}
                        </select>

                        {/* {
                            fieldConfig.props.isMulti ? (
                                <Select
                                    {...fieldConfig.props}
                                    value={fieldConfig.value}
                                    onChange={(event) => this.changeHandler(event, field.id)}
                                    options={fieldConfig.data}
                                />
                            ) : (
                                <select
                                    {...fieldConfig.props}
                                    value={fieldConfig.value}
                                    onChange={(event) => this.changeHandler(event, field.id)}
                                >
                                    {fieldConfig.data.map((option, index) => {
                                        return <option key={index} value={option.value}>{option.display}</option>;
                                    })}
                                </select>
                            )
                        } */}
                    </div>
                );
                break;
            case ('textarea'):
                formTemplate = (
                    <div {...fieldConfig.wrapperProps}>
                        {this.showLabel(fieldConfig.showLabel, fieldConfig.label)}

                        <textarea
                            {...fieldConfig.props}
                            value={fieldConfig.value}
                            onChange={(event) => this.changeHandler(event, field.id)}
                        />

                        {/* <DefaultEditor
                            // {...fieldConfig.props}
                            value={fieldConfig.value}
                            onChange={(event) => this.changeHandler(event, field.id)}
                        /> */}
                    </div>
                );
                break;

            case ('sun-editor'):
                formTemplate = (
                    <div {...fieldConfig.wrapperProps}>
                        {this.showLabel(fieldConfig.showLabel, fieldConfig.label)}

                        <SunEditor 
                            onChange={(event) => this.changeHandler(event, field.id)}
                            setContents={fieldConfig.value}
                            setOptions={{
                                ...fieldConfig.props.style,
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["paragraphStyle"],
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript"
                                    ],
                                    ["fontColor", "hiliteColor", "outdent", "indent"],
                                    ["removeFormat"],
                                    "/", // Line break
                                    ["align", "horizontalRule", "list", "lineHeight"],
                                    ['table', 'link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                ],
                            }}
                        />
                    </div>
                );
                break;

            case ('checkbox'):
                formTemplate = (
                    <div {...fieldConfig.wrapperProps}>
                        <input
                            {...fieldConfig.props}
                            checked={fieldConfig.checked}
                            onClick={() => this.checkboxOnClick(field)}
                        />

                        {this.showLabel(fieldConfig.showLabel, fieldConfig.label)}
                    </div>
                )

                break;
            default:
                formTemplate = null
        }

        return formTemplate
    }

    render() {
        const { field } = this.props

        return (
            <>
                {
                    this.renderFormField(field)
                }
            </>
        )
    }
}