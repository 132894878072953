import React, { useState, useEffect } from 'react';
import {
    PostMan,
    SetDateTime
} from '../../../../../../Helpers';
import {
    useRouteMatch
} from "react-router-dom";

import BreadCrumb from '../../../../../../components/BreadCrumb';

import VehicleReportList from './components/VehicleReportList';
import VehicleInformation from './components/VehicleInformation';

import UserProfileCard from '../DiagnosticsUserProfileCard';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../../../../utils/Button';
import FormField from '../../../../../../utils/FormField';


export default function Index(props) {
    const match = useRouteMatch()
    const requestId = match.params.requestId

    const [DiagnosticsRequest, setDiagnosticsRequest] = useState(null)
    const [ShowPayedButton, setShowPayedButton] = useState(false)

    const [FormData, setFormData] = useState({
        technician: {
            element: 'select',
            data: [
                {
                    value: 0,
                    display: "---"
                },
            ],
            value: '',
            showLabel: true,
            label: {
                text: 'Technician',
                props: null
            },
            props: {
                name: 'technician_input',
                className: 'form-control',
                required: true,
            },
            wrapperProps: {
                className: 'form-group mb-3'
            }
        },
        
    })

    const [PageButtons, setPageButtons] = useState({
        saveAndContinue: {
            text: {
                style: null,
                value: "Assign Technician",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        attemptMarkAsPaid: {
            text: {
                style: null,
                value: "Mark As Paid?",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => setShowPayedButton(true),
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        markAsPaid: {
            text: {
                style: null,
                value: "Confirm",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => { },
            loader: {
                isLoading: false,
                size: 20,
                style: {
                    color: 'white'
                }
            },
        },
        cancelMarkAsPaid: {
            text: {
                style: {
                    color: 'red'
                },
                value: "X",
            },
            props: {
                type: "button",
                style: null,
                className: 'btn btn-primary',
            },
            onClick: () => setShowPayedButton(false),
        }
    })

    const FetchDiagnosticsRequest = async () => {
        const responseObject = await PostMan(`diagnostics/${requestId}/`, 'GET')
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("Request Detail: ", responseData)
            // Update DiagnosticsRequest in state.
            await setDiagnosticsRequest(responseData)
        }
        else { }
    }

    const AttemptUpdateDiagnosticsRequest = async (payload) => {
        const responseObject = await PostMan(`diagnostics/${requestId}/`, 'PATCH', payload)
        if (responseObject.status === 'success') {
            let responseData = responseObject.data
            console.log("Request Detail: ", responseData)
            // Update DiagnosticsRequest in state.
            await setDiagnosticsRequest(responseData)
        }
        else { }
    }

    const AttemptAddTechnician = async () => {
        let payload = {}
        // Start Loader
        let newPageButtons = PageButtons
        newPageButtons.saveAndContinue.loader.isLoading = true
        await setPageButtons({ ...newPageButtons })
        //
        if (!FormData.technician.value 
            || 
            FormData.technician.value == ' ' 
            || 
            FormData.technician.value == 0
        ) {
            // Toast Error Message
            toast.error("Please select a technician")
            // Stop Loader
            newPageButtons.saveAndContinue.loader.isLoading = false
            return setPageButtons({ ...newPageButtons })
        } 
        else {
            payload['technician'] = FormData.technician.value
        }
        //
        await AttemptUpdateDiagnosticsRequest(payload)
        
        // Stop Loader
        newPageButtons.saveAndContinue.loader.isLoading = false
        await setPageButtons({ ...newPageButtons })

        // const responseObject = await PostMan(`diagnostics/${requestId}/`, 'PATCH', payload)
        // //
        // if (responseObject.status === 'success') {
        //     let responseData = responseObject.data            
        //     // Reload Screen
        //     await setDiagnosticsRequest(responseData)
        // }
        // else { }
    }

    const FetchTechnicians = async () => {
        const responseObject = await PostMan('diagnostics/technicians/', 'GET')
        if (responseObject.status === 'success') {
            let technicianList = responseObject.data
            console.log("Technician list: ", technicianList)
            // 
            let newFormData = FormData
            technicianList.map((technician) => {
                newFormData.technician.data.push({
                    value: technician.id,
                    display: technician.user.full_name
                })
            })
            // Update Form data in state.
            await setFormData({...newFormData})
        }
        else { }
    }

    useEffect(() => {
        // Fetch Client Detail
        FetchDiagnosticsRequest()
        // Fetch Technicians
        FetchTechnicians()
    }, [])

    return (
        <div class="container-fluid">
            <BreadCrumb 
                title={
                    DiagnosticsRequest 
                    && 
                    `Diagnostics Request: ${DiagnosticsRequest.vehicle.short_name_display}`
                    ||
                    "Diagnostics Request"
                }
                crumbs={[
                    {
                        title: "All Diagnostics Requests",
                        path: "/diagnostics/"
                    },
                    {
                        title: DiagnosticsRequest && DiagnosticsRequest.vehicle.short_name_display
                    }
                ]} 
            />

            <div class="row">
                <div class="col-xl-8 order-12 order-xl-1">

                    <VehicleInformation diagnosticsUser={DiagnosticsRequest} />

                    <div class="card-box mb-2">
                        <div class="row align-items-center">
                            <div class="col-sm-4">
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mb-1"><b>Appointment Date:</b> {DiagnosticsRequest && DiagnosticsRequest.appointment_date}</p>
                                        <p class="mb-1"><b>Appointment Time:</b> {DiagnosticsRequest && DiagnosticsRequest.appointment_time}
                                            {DiagnosticsRequest && DiagnosticsRequest.appointment_time > 12 ? " pm" : " am"}
                                        </p>
                                        <p class="mb-1"><b>Payment Status:</b> {DiagnosticsRequest && DiagnosticsRequest.payment_status.display}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="text-center my-3 my-sm-0">
                                    <h5>Created on</h5>
                                    <p class="mb-0 text-muted">{SetDateTime(DiagnosticsRequest && DiagnosticsRequest.created_at)}</p>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                {
                                    DiagnosticsRequest ? (
                                        <>
                                            {
                                                DiagnosticsRequest.payment_status.value == "pending" ? (
                                                    <div class="text-center button-list">
                                                        {
                                                            ShowPayedButton ? (
                                                                <>
                                                                    <Button {...PageButtons.markAsPaid} {...{
                                                                        onClick: async () => {
                                                                            let newPageButtons = PageButtons
                                                                            // Start Loader
                                                                            newPageButtons.saveAndContinue.loader.isLoading = true
                                                                            await setPageButtons({ ...newPageButtons })
                                                                            // Attempt update request
                                                                            AttemptUpdateDiagnosticsRequest({ payment_status: 'completed' })
                                                                            // Stop Loader
                                                                            newPageButtons.saveAndContinue.loader.isLoading = false
                                                                            await setPageButtons({ ...newPageButtons })
                                                                        }
                                                                    }} />
                                                                    <Button {...PageButtons.cancelMarkAsPaid} />
                                                                </>
                                                            ) : (
                                                                <Button {...PageButtons.attemptMarkAsPaid} />
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <>
                                                        {
                                                            DiagnosticsRequest.payment_status.value == "completed" ? (
                                                                <div style={{ color: 'white', padding: '10px', width: "fit-content" }} className={"bg-success"}>
                                                                    Paid
                                                                </div>
                                                            ) : (
                                                                <div style={{ color: 'white', padding: '10px', width: "fit-content" }} className={"bg-primary"}>
                                                                    {DiagnosticsRequest.payment_status.display}
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    
                                                )
                                            }
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                                        
                    {
                        DiagnosticsRequest && DiagnosticsRequest.technician && DiagnosticsRequest.technician.user ? (
                            <>
                                {
                                    DiagnosticsRequest && <VehicleReportList vehicleReports={DiagnosticsRequest.reports} />
                                }
                            </>        
                        ) : (
                            <form>
                                <FormField
                                    formData={FormData}
                                    change={(newFormData) => setFormData({ ...newFormData })}
                                    field={{
                                        id: 'technician',
                                        config: FormData.technician
                                    }}
                                />

                                <Button {...PageButtons.saveAndContinue} {...{ onClick: () => AttemptAddTechnician() }} />
                            </form>
                        )
                    }

                </div>

                <div class="col-xl-4 order-1 order-xl-12">
                    <UserProfileCard diagnosticsUser={DiagnosticsRequest} />
                </div>
            </div>

            <ToastContainer />
        </div>
    )
}
