import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";

// import BaseScreen from './screens/BaseScreen';

import BaseDashboard from '../../components/management/BaseDashboard';

import LoginScreen from '../auth/LoginScreen';

import ProtectedRoute from "../../utils/ProtectedRoute";
import HomeScreen from "./screens/HomeScreen";
// import UsersScreen from "./screens/UsersScreen";

// import ReferralsScreen from "./screens/ReferralsScreen";


import UsersScreen from "./screens/users/Index";
import StoresScreen from "./screens/autoshop/stores/Index";
import ListingsScreen from "./screens/autoshop/listings/Index";
import ReferralsScreen from "./screens/referrals/Index";
import DiagnosticsScreen from "./screens/diagnostics/Index";
import MessengerScreen from "./screens/messenger/Index";
import EmailMarketingScreen from "./screens/email_marketing/Index";
import PushNotificationScreen from "./screens/push_notification/Index";
import SponsoredListingsScreen from "./screens/sponsored_listings/Index";


function RouterBase() {

    return (
        <>
            <Router>

            
                {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}

                <Switch>

                    


                    <Route path="/sponsored-listings">
                        <BaseDashboard>
                            <SponsoredListingsScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/push-notification">
                        <BaseDashboard>
                            <PushNotificationScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/email-marketing">
                        <BaseDashboard>
                            <EmailMarketingScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/messenger">
                        <BaseDashboard>
                            <MessengerScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/diagnostics">
                        <BaseDashboard>
                            <DiagnosticsScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/referrals">
                        <BaseDashboard>
                            <ReferralsScreen />
                        </BaseDashboard>
                    </Route>
                     
                    <Route path="/autoshop/listings">
                        <BaseDashboard>
                            <ListingsScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/autoshop/stores">
                        <BaseDashboard>
                            <StoresScreen />
                        </BaseDashboard>
                    </Route>

                    <Route path="/users">
                        <BaseDashboard>
                            <UsersScreen />
                        </BaseDashboard>
                    </Route>

                    <Route exact path="/login">
                        <LoginScreen />
                    </Route>

                    <Route path="/">
                        <BaseDashboard>
                            <HomeScreen />
                        </BaseDashboard>
                    </Route>

                    {/* <Route path="/" component={BaseDashboard} /> */}

                </Switch>

            </Router>

        </>
    );
}

export default withRouter(RouterBase)