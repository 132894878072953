import React from 'react'

export default function UserMessages(props) {
    const {
        getStatus
    } = props
    
    return (
        <div className={`tab-pane ${getStatus()}`} id="userMessages">

            <h5 className="mb-4 text-uppercase"><i className="mdi mdi-briefcase mr-1"></i>
                Support
            </h5>

            <form action="#" className="comment-area-box mt-2 mb-3">
                <span className="input-icon">
                    <textarea rows="3" className="form-control" placeholder="Write something..."></textarea>
                </span>
                <div className="comment-area-btn">
                    <div className="float-right">
                        <button type="submit" className="btn btn-sm btn-dark waves-effect waves-light">Post</button>
                    </div>
                    <div>
                        <a href="#" className="btn btn-sm btn-light text-black-50 shadow-none"><i className="far fa-user"></i></a>
                        <a href="#" className="btn btn-sm btn-light text-black-50 shadow-none"><i className="fa fa-map-marker-alt"></i></a>
                        <a href="#" className="btn btn-sm btn-light text-black-50 shadow-none"><i className="fa fa-camera"></i></a>
                        <a href="#" className="btn btn-sm btn-light text-black-50 shadow-none"><i className="far fa-smile"></i></a>
                    </div>
                </div>
            </form>

        </div>
    )
}
