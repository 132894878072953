import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
	PostMan,
	SetDateTime
} from '../../../../../../../Helpers';

import CreateUserModal from './CreateUser';

import PageActivityIndicator from '../../../../../../../utils/indicator/PageActivityIndicator';


export default function TechnicianList() {
	const [TechList, setTechList] = useState([])
	const [IsLoading, setIsLoading] = useState(false)
	const [ShowModal, setShowModal] = useState(false)

	const FetchTech = async () => {
		setIsLoading(true)
		//
		const responseObject = await PostMan(
			`account/user/all/?account_type=technician`,
			'GET'
		)
		//
		setIsLoading(false)
		//
		if (responseObject.status === 'success') {
			let responseData = responseObject.data
			console.log('Technician List: ', responseData)
			// Update TechList in state.
			await setTechList(responseData)
		} 
		else {}
	}

	const toggleModal = () => {
		setShowModal(!ShowModal)
	}


	useEffect(() => {
		// Fetch Diagnostics List
		FetchTech()
	}, [])

	return (
		<>
			<div class='row'>
				<div class='col-12'>
					<div class='card'>
						<div class='card-body'>
							<div class='row mb-2'>
								<div class='col-sm-4'>
									<a
										href='#'
										data-toggle='modal'
										data-target='#add-agent'
										class='btn btn-md btn-danger'
										onClick={toggleModal}
									>
										<i class='mdi mdi-plus-circle-outline'></i> Add Technician
									</a>
								</div>
								<div class='col-sm-8'></div>
							</div>

							<div class='table-responsive'>
								<table
									class='table table-centered table-striped'
									id='agent-datatable'
								>
									<thead>
										<tr>
											<th style={{ width: '20px' }}>
												<div class='custom-control custom-checkbox'>
													<input
														type='checkbox'
														class='custom-control-input'
														id='customCheck1'
													/>
													<label class='custom-control-label'>&nbsp;</label>
												</div>
											</th>
											<th>Full Name</th>
											<th>Phone</th>
											<th>Email</th>
											<th>Create Date</th>
											<th>Status</th>
											<th style={{ width: '85px' }}>Action</th>
										</tr>
									</thead>
									<tbody>
										{TechList.map((user) => {
											return (
												<tr key={user.id}>
													<td>
														<div class='custom-control custom-checkbox'>
															<input
																type='checkbox'
																class='custom-control-input'
																id='customCheck2'
															/>
															<label class='custom-control-label'>&nbsp;</label>
														</div>
													</td>
													<td class='table-user'>
														<Link
															to={`technicians/${user.id}`}
															class='text-body font-weight-semibold'
														>
															{user.full_name}
														</Link>
													</td>
													<td>{user.mobile}</td>
													<td>{user.email ? user.email : '---'}</td>
													<td>{SetDateTime(user.created_at)}</td>
													<td>
														{user.is_active ? (
															<span class='badge bg-soft-success text-success shadow-none'>
																Active
															</span>
														) : (
															<span class='badge bg-soft-danger text-danger shadow-none'>
																Inactive
															</span>
														)}
													</td>

													<td>
														<a
															href={`technicians/${user.id}`}
															class='action-icon'
														>
															{' '}
															<i class='mdi mdi-square-edit-outline'></i>
														</a>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<CreateUserModal
				userType={"technician"}
				config={{
					title: 'Add a Technician',
					isOpen: ShowModal,
					toggle: toggleModal,
				}}
			/>
		</>
	)
}
