import React from 'react';
import { Link } from 'react-router-dom';

import AviPlaceholder from '../../../../../../../assets/images/avatar.png';

import moment from 'moment';

export default function ChatUser(props) {
    const {
        conversation
    } = props

    const chat_recipient = conversation.recipients[0]

    return (
        <>
            <Link to={`/messenger/${conversation.id}`} class="text-body">
                <div class="d-flex align-items-start p-2">
                    <img class="mr-2 rounded-circle" height="42"
                        src={chat_recipient.user.image ? chat_recipient.user.image : AviPlaceholder}
                        alt={chat_recipient.user.full_name}
                    />

                    <div class="w-100">
                        <h5 class="mt-0 mb-0 font-14">
                            {chat_recipient.user.full_name}
                        </h5>
                        <span class="text-muted" style={{ fontSize: '12px', fontStyle: 'italic' }}>
                            {moment(conversation.preview.timestamp).fromNow()}
                        </span>
                        <p class="d-flex mt-1 mb-0 text-muted font-14">
                            <div style={{
                                width: '120px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                {conversation.preview.message}
                            </div>
                            {
                                conversation.unread_messages
                                &&
                                conversation.unread_messages.count > 0 ? (
                                    <p style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'red',
                                        borderRadius: '20px',
                                        color: 'white',
                                        textAlign: 'center',
                                        margin: 0
                                    }}>
                                        {conversation.unread_messages.count}
                                    </p>
                                ) : null
                            }
                        </p>
                    </div>
                </div>
            </Link>
        </>
    )
}
