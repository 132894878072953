
// export const UserTypes = {
//     agents: 'Agents',
//     customers: 'Customers',
//     vendors: 'Vendors',
//     technicians: 'Technicians',
// }

export const UserTypes = {
    agents: 'Agents',
    customers: 'Customers',
    vendors: 'Vendors',
    technicians: 'Technicians',
}