import React from 'react';
import { SetDateTime } from '../../../../../Helpers';
import { Link } from 'react-router-dom';

export default function DiagnosticsTable(props) {
    const {
        diagnosticsRequests
    } = props

    return (
        <>
        {
            diagnosticsRequests ? (
                <div class="table-responsive">
                    <table class="table table-centered table-striped" id="agent-datatable">
                        <thead>
                            <tr>
                                <th style={{ width: '20px' }}>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                        <label class="custom-control-label" for="customCheck1">&nbsp;</label>
                                    </div>
                                </th>
                                <th>Full Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Vehicle</th>
                                <th>Payment</th>
                                <th>Technician</th>
                                <th>Create Date</th>
                                <th style={{ width: '85px' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                diagnosticsRequests.map(user => {
                                    return (
                                        <tr>
                                            <td>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                    <label class="custom-control-label" for="customCheck2">&nbsp;</label>
                                                </div>
                                            </td>
                                            <td class="table-user">
                                                <Link to={`${user.id}/`} class="text-body font-weight-semibold">
                                                    {user.full_name || "Michael Smith"}
                                                </Link>
                                            </td>
                                            <td>
                                                {user.mobile || "2347081032420"}
                                            </td>
                                            <td>
                                                {user.email ? user.email : "---"}
                                            </td>
                                            <td>
                                                {user.vehicle.long_name}
                                            </td>
                                            <td>
                                                {user.payment_status.display}
                                            </td>
                                            <td>
                                                {user.technician && user.technician.user.full_name || "---"}
                                            </td>
                                            <td>
                                                {SetDateTime(user.created_at)}
                                            </td>

                                            <td>
                                                <Link to={`${user.id}/`} class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )
            :
            null
        }
        </>
    )
}
