import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import StoreList from './components/StoreList';
import StoreDetail from './components/StoreDetail/Index';

export default function StoresScreen() {
	const match = useRouteMatch()

	return (
		<div class='container-fluid'>
			<Switch>
				<Route exact path={`${match.path}/:storeId`}>
					<StoreDetail />
				</Route>

				<Route exact path={`${match.path}`}>
					<StoreList />
				</Route>
			</Switch>
		</div>
	)
}
