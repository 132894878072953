import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadCrumb(props) {
    const {
        title,
        crumbs
    } = props

    return (
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>

                            {
                                crumbs && crumbs.map(crumb => {
                                    if (crumb.path) {
                                        return (
                                            <li class="breadcrumb-item">
                                                <Link to={crumb.path}>
                                                    {crumb.title}
                                                </Link>
                                            </li>
                                        )
                                    }
                                    return (
                                        <li class="breadcrumb-item active">
                                            {crumb.title}
                                        </li>
                                    )
                                })
                            }

                            {/* <li class="breadcrumb-item active">{title}</li> */}
                        </ol>
                    </div>
                    <h4 class="page-title">
                        {title}
                    </h4>
                </div>
            </div>
        </div>
    )
}
