import React from 'react'
import './modal.css'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const ModalComponent = ({ children, open, toggle, title, className, fullscreen=false }) => {
	return (
		<>
			<Modal isOpen={open} toggle={toggle} fullscreen={"xl"}>
				<ModalHeader toggle={toggle}>{title}</ModalHeader>
				<ModalBody className={className}>{children}</ModalBody>
			</Modal>
		</>
	)
}

export default ModalComponent
