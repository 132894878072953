import React from 'react';
import '../modal/modal.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ActivityIndicator from './ActivityIndicator';

const PageActivityIndicator = ({ children, open, toggle }) => {
    return (
        <>
            <Modal isOpen={open} toggle={toggle} style={{ background: 'transparent' }}>
                <ModalBody style={{ background: 'transparent' }}>
                    {children ? children : (
                        <ActivityIndicator />
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

export default PageActivityIndicator
