import React from 'react';
import '../modal/modal.css';

const ActivityIndicator = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			Loading...
			<div class="spinner-border text-danger m-2" role="status"></div>
		</div>
	)
}

export default ActivityIndicator
