import React from 'react';
import {
    logout
} from '../../redux/actions/AuthActions'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Logo from '../../assets/images/logo-straight-white.png';
import LogoWhite from '../../assets/images/logo-white.png';

function Header(props) {
    const {
        auth,
        setShowMenu,
        logout: reduxLogout 
    } = props

    return (
        <div class="navbar-custom">
            <ul class="list-unstyled topnav-menu float-right mb-0">


                <li class="dropdown notification-list">
                    <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <span class="pro-user-name ml-1">
                            {auth.user && auth.user.first_name || "John Doe"}
                            <i class="mdi mdi-chevron-down"></i>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                        <div class="dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome !</h6>
                        </div>

                        <a href="#" class="dropdown-item notify-item">
                            <i class="fe-user"></i>
                            <span>My Account</span>
                        </a>

                        <div class="dropdown-divider"></div>

                        <div onClick={() => reduxLogout()} class="dropdown-item notify-item">
                            <i class="fe-log-out"></i>
                            <span>Logout</span>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="logo-box">
                <a href="/" class="logo text-center">
                    <span class="logo-lg">
                        <img src={Logo} alt="" height="32" />
                    </span>
                    <span class="logo-sm">
                        <img src={LogoWhite} alt="" height="32" />
                    </span>
                </a>
            </div>

            <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
                <li>
                    <button onClick={() => setShowMenu()} class="button-menu-mobile waves-effect waves-light">
                        <i class="fe-menu"></i>
                    </button>
                </li>
            </ul>
        </div>
    )
}


const mapStateToProps = state => {
    const {
        auth
    } = state
    return {
        auth
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)