import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router'
import { Link, useRouteMatch } from 'react-router-dom'

import BreadCrumb from '../../../../../../components/BreadCrumb';

import { UserTypes } from '../../Helpers';

import AgentList from './components/AgentList';
import VendorList from './components/VendorList';
import CustomerList from './components/CustomerList';
import TechnicianList from './components/TechnicianList';

export default function UserList() {
	const match = useRouteMatch()
	const userType = match.params.userType

	return (
		<>
			<BreadCrumb
				title={UserTypes[userType]}
				crumbs={[
					{
						title: 'Users',
						path: '/users',
					},
					{
						title: `${UserTypes[userType]}`,
						path: `/users/${userType}`,
					},
				]}
			/>


			<Switch>
				<Route exact path={`${match.path}`}>
					{userType === 'agents' ? (
						<AgentList />
					) : userType === 'customers' ? (
						<CustomerList />
					) : userType === 'vendors' ? (
						<VendorList />
					) : userType === 'technicians' ? (
						<TechnicianList />
					) : null}
				</Route>
			</Switch>
		</>
	)
}
