import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';

import {
	PostMan,
	SetDateTime
} from '../../../../../../../Helpers';

import {
	useRouteMatch,
	useHistory,
	Redirect
} from 'react-router-dom';

import BreadCrumb from '../../../../../../../components/BreadCrumb';
import ListingsTable from './components/ListingsTable';


export default function Store() {
	const match = useRouteMatch()
	const history = useHistory()
	
	const storeId = match.params.storeId

	const [redirect, seRedirect] = useState(null)
	const [Store, setStore] = useState(null)


	const FetchStore = async () => {
		const responseObject = await PostMan(
			`auto-shop/${storeId}/`,
			'GET'
		)
		if (responseObject.status === 'success') {
			let responseData = responseObject.data
			console.log('Store Detail: ', responseData)
			await setStore(responseData)
		} 
		else {}
	}

	useEffect(() => {

		// Fetch Store
		FetchStore()

	}, [])


	return (
		<>
			<BreadCrumb
				title={
					Store 
					?
					(
						<div style={{ textTransform: 'capitalize' }}>
							Store Detail: 
							<span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
								{Store && Store.name}
							</span>
						</div>
					)
					:
					"Store Detail"
				}
				crumbs={[
					{
						title: 'Autoshop',
						path: '/autoshop',
					},
					{
						title: 'All Stores',
						path: `/autoshop/stores/`,
					},
					{
						title: Store && Store.name,
						path: null,
					},
				]}
			/>


			<div class="row">
				<div class="col-xl-8 order-12">
					<div class="card">
						<div class="card-body">
							<div class="row mb-2">
								<div class="col-sm-4">
									<form class="form-inline">
										<div class="form-group mb-2">
											<label for="inputPassword2" class="sr-only">Search</label>
											<input type="search" class="form-control" id="inputPassword2" placeholder="Search..." />
										</div>
									</form>
								</div>
								<div class="col-sm-8">
									<div class="text-sm-right">
										<button type="button" class="btn btn-success waves-effect waves-light mb-2 mr-1"><i class="mdi mdi-settings"></i></button>
										<a href="#custom-modal" class="btn btn-danger waves-effect waves-light mb-2" data-animation="fadein" data-plugin="custommodal" data-overlayColor="#38414a">
											<i class="mdi mdi-plus-circle mr-1"></i>
											Add Listing
										</a>
									</div>
								</div>
							</div>

							{
								Store && <ListingsTable store={Store} />
							}

						</div> 
					</div> 
				</div> 

				<div class="col-xl-4 order-1">
					<div class="card-box">
						{
							Store ? (
								<>
									<div class="media mb-3">
										<div class="media-body">
											<h4 class="mt-0 mb-1">{Store.vendor.full_name}</h4>
											<p class="text-muted">Store manager</p>
											<p class="text-muted"><i class="mdi mdi-office-building"></i><b>{Store.name}</b></p>

											<a href="javascript: void(0);" class="btn- btn-xs btn-info mr-1">Send Email</a>
											<a href="javascript: void(0);" class="btn- btn-xs btn-info mr-1">Send Push Message</a>
											<a href="javascript: void(0);" class="btn- btn-xs btn-secondary mr-1">Edit</a>
										</div>
									</div>

									<h5 class="mb-3 mt-4 text-uppercase bg-light p-2"><i class="mdi mdi-account-circle mr-1"></i> Store Information</h5>
									<div class="">
										{
											Store.vendor.email ? (
												<>
													<h4 class="font-13 text-muted text-uppercase mb-1">Email :</h4>
													<p class="mb-3">{Store.vendor.email}</p>
												</>
											) : null
										}
										
										<h4 class="font-13 text-muted text-uppercase mb-1">Mobile :</h4>
										<p class="mb-3">{Store.vendor.mobile}</p>

										<h4 class="font-13 text-muted text-uppercase mb-1">Address :</h4>
										<p class="mb-3">{Store.address}</p>

										<h4 class="font-13 text-muted text-uppercase mb-1">City :</h4>
										<p class="mb-3">{Store.store_location.short_name}</p>

										<h4 class="font-13 text-muted text-uppercase mb-1">Joined :</h4>
										<p class="mb-3"> {SetDateTime(Store.created_at)}</p>

										<h4 class="font-13 text-muted text-uppercase mb-1">Updated :</h4>
										<p class="mb-0"> {SetDateTime(Store.updated_at)}</p>

									</div>
								</>
							) : (
								<>
									Loading
								</>
							)
						}
						
					</div> 
				</div>
			</div>
			
		</>
	)
}
